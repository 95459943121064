import { Injectable } from '@angular/core';

// google-places.service.ts
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private apiKey = 'AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY';
  private googleApiBaseUrl = '/https://maps.googleapis.com/maps/api/place/queryautocomplete/json';
  private geocodingApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
  private placesApiUrl = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json';

  constructor(private http: HttpClient, private apiService: ApiService) { }

  // // Perform a place autocomplete search
  // public autoCompletePlace(input: string): Observable<any> {
  //   console.log(input)
  //   const autoCompleteUrl = this.apiService.backendUrl + '/autocomplete'; // Use your server URL
  //   const autoCompleteParams = {
  //     input: input,
  //   };

  //   return this.http.get(autoCompleteUrl, { params: autoCompleteParams });
  // }

  // Convert a city and country into coordinates
  public geocode(locations: string[]): Observable<any> {
    // const address = `${city}, ${country}`;
    const requestBody = { locations };
    return this.http.post(this.apiService.backendUrl + '/geocode', requestBody);
  }



  // // Assuming you have the coordinates (latitude and longitude) in location
  // public nearbySearch(location: string, radius: number, types: string): Observable<any> {
  //   const params = new HttpParams()
  //     .set('location', location)
  //     .set('radius', radius.toString())
  //     .set('type', types) // Specify the type of places you want, e.g., 'point_of_interest'
  //     .set('key', this.apiKey);
  //   return this.http.get(this.apiService.backendUrl + '/places-api', { params });
  // }


   // Assuming you have the coordinates (latitude and longitude) in location
   public nearbySearch(location: string, radius: number, includedTypes: string[], excludedTypes: string[], maxResultCount: number): Observable<any> {
    console.log("locations is", location)
    const requestBody = {
      includedTypes: includedTypes,
      excludedTypes: excludedTypes,
      maxResultCount: maxResultCount,
      locationRestriction: {
        circle: {
          center: {
            latitude: parseFloat(location.split(',')[0]), // Extract latitude directly from the split location string
            longitude: parseFloat(location.split(',')[1]) // Extract longitude directly from the split location string
          },
          radius: radius
        }
      },
      rankPreference: "POPULARITY"
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.apiService.backendUrl + '/places-api', requestBody, { headers: headers });
}

  public getPlacePhoto(photoReference: string): Observable<Blob> {
    const apiUrl = `${this.apiService.backendUrl}/getPlacePhoto?photoReference=${photoReference}`;
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  public getSpecificPlaceDetails(placeId){
    return this.http.get(`${this.apiService.backendUrl}/place-details?placeId=${placeId}`)
  }


  public autocomplete(query: string): Observable<any> {
    const params = new HttpParams()
      .set('input', query)
      .set('key', this.apiKey)
      .set('types', 'address'); // Add the 'types' parameter with value 'address'
    return this.http.get(this.apiService.backendUrl + '/api/autocomplete', { params });
  }
  public getPlaceDetails(placeId: string): Observable<any> {
    const detailsUrl = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json';
    const params = new HttpParams()
      .set('place_id', placeId)
      .set('fields', 'geometry') // You can add more fields as needed
      .set('key', this.apiKey);
  
    return this.http.get(detailsUrl, { params });
  }
  

  // // Get details for a place
  // public getPlaceDetails(placeId: string): Observable<any> {
  //   const placeDetailsUrl = 'https://maps.googleapis.com/maps/api/place/details/json';
  //   const placeDetailsParams = {
  //     place_id: placeId,
  //     key: this.apiKey,
  //   };

  //   return this.http.get(placeDetailsUrl, { params: placeDetailsParams });
  // }

  public convertBlobToUrl(blobData: Blob): any {
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }


  // Search nearby places
  public searchNearbyPlaces(location: string, radius: number, keyword: string): Observable<any> {
    const nearbySearchUrl = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json';
    const nearbySearchParams = {
      location: location,
      radius: radius.toString(),
      keyword: keyword,
      key: this.apiKey,
    };
    return this.http.get(nearbySearchUrl, { params: nearbySearchParams });
  }

  // private getGeoLocation(location) {
  //   this.geocode(location).subscribe((geoCodeData) => {
  //     // Handle the response from the Geocoding API here
  //     console.log(geoCodeData);
  //     this.location = geoCodeData.results[0].geometry.location.lat + ',' + geoCodeData.results[0].geometry.location.lng;
  //     console.log(this.location);
  //     this.getNearbyRecommendations(this.location, 'tourist_attraction');
  //   });
  // }
}
