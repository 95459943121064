import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { ItineraryActivitiesPage } from '../itinerary-activities/itinerary-activities.page';
import { ApiService } from '../api.service';
import { Subject, take, takeUntil } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { DateRange, MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { IonicSlides } from '@ionic/angular';
import { GoogleService } from '../google.service';
import { NavigationExtras, Router } from '@angular/router';



declare global {
  interface Window {
    Swiper: typeof Swiper;
  }
}
type ItineraryDate = {
  date: string;
  _id: string;
  selected: boolean;
};
@Component({
  selector: 'app-itinerary-details',
  templateUrl: './itinerary-details.page.html',
  styleUrls: ['./itinerary-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [trigger('fadeInOut', [
    // Define the "void" => "visible" transition
    transition(':enter', [ // :enter is alias for void => *
      style({ opacity: 0 }), // Start with an opacity of 0
      animate('0.1s ease-in', style({ opacity: 1 })) // Animate to opacity 1
    ]),
    // Define the "visible" => "void" transition
    transition(':leave', [ // :leave is alias for * => void
      animate('0.01s ease-out', style({ opacity: 0 })) // Animate to opacity 0
    ])
  ], ),
  trigger('slideUpAnimation', [
    state('void', style({
      transform: 'translateY(100%)',
      opacity: 0
    })),
    state('*', style({
      transform: 'translateY(0)',
      opacity: 1
    })),
    transition(':enter', [
      animate('100ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
    ]),
    transition(':leave', [
      animate('100ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 }))
    ])
  ]),
  trigger('fadeInOutDays', [
    // Define the "void" => "visible" transition
    transition(':enter', [ // :enter is alias for void => *
      style({ opacity: 0 }), // Start with an opacity of 0
      animate('0.1s ease-in', style({ opacity: 1 })) // Animate to opacity 1
    ]),
    // Define the "visible" => "void" transition
    transition(':leave', [ // :leave is alias for * => void
      animate('0.5s ease-out', style({ opacity: 0 })) // Animate to opacity 0
    ])
  ]),
  trigger('gradientChange', [
    state('increased', style({
      background: 'linear-gradient(to right, rgba(82, 166, 255, 0.2) 100%, white 100%)'
    })),
    state('decreased', style({
      background: 'linear-gradient(to right, rgba(82, 166, 255, 0.2) 100%, white 100%)'
    })),
    transition('increased <=> decreased', animate('0.5s ease-out'))
  ]),
  trigger('bounce', [
    transition('* => true', [  // Assuming the animation is controlled by a boolean
    animate('150ms', keyframes([
        style({ transform: 'translateY(0)', offset: 0 }),
        style({ transform: 'translateY(-15px)', offset: 0.4 }),
        style({ transform: 'translateY(-7.5px)', offset: 0.6 }),
        style({ transform: 'translateY(0)', offset: 1 })
      ]))
    ])
  ])



]

  
})


export class ItineraryDetailsPage implements OnInit, OnChanges {
  @ViewChild(MatCalendar) calendar: MatCalendar<any>;
  selectedColumnIndex: number = 0
  isTours = false;
  gradientState = 'increased'; // Initial state
 @Input() tripLocation: any;
 @Input() tripId: any;
 @Input() acceptedInviteCount: number;
 @Input() viatorTags = [];
 @Input() viatorDestId: number;
 @Input() viatorTagAndProductData: any;
 @Input() tripRecommendations = []
 maxPrice = 2000; 
 filteredData: any[] = []; // Store filtered data for display
 groupedVotes: any;

 @ViewChild('slides') slides: any;
 slideOpts = {
  initialSlide: 1,
  speed: 400,
};
public voteBeingVotedOn: any;
 public selectedDateRange: DateRange<Date>;
 parsedDateRanges: { startDate: Date, endDate: Date }[] = [];
  public dayId = 0;
 constructor(private modalCtrl: ModalController,
  private router: Router,
   private apiService: ApiService, private cdr: ChangeDetectorRef, private geocodingService: GoogleService) {}
 @ViewChild('swiperContainer') swiperContainer!: ElementRef;
 @ViewChildren('swiperSlide') swiperSlides!: QueryList<ElementRef>;
  private _itineraryDates: ItineraryDate[] = [];
  selectedDate = null;
  formattedDateForItineraryPage: string;
  public noDatesYet = false;
  selectedDateActivities = [];
  dates = [];
  showSearchBarOption = false;
  isHidingOptions = false;
  isLoaded = false;
  showSearchCalendar = false;
  swiper: Swiper | undefined;
  public locationsToVoteOn = []
  private destroy$ = new Subject<void>();
  public myUserId: string;
  public profilePicture:string;
  public showSaveCalendarDatesButton = false;
  private tempLocationsArray = []
  gradientStyle = {};
  selectedTagIndex: number | null = null;
  public viatorDestinationProducts = []
  originalViatorTagAndProductData: any[] = []; // Store the original data here
  originalSelectedDateActivities: any[] = []; // Store the original data here
  public isMyUserDateVoteAlreadySaved = false;
  public potentialVotes = []
  public allPotentialDateVotes = []
  public isTagDataLoaded = false;
  public isPiggyBacking = false;

  public categories = ['Restaurants'];
  public accomodations = ['Hotels', 'AirBnB']
  public showNearbyRecommendations = false;
  public isShowingAccomodations = false;
  filterResultsEmpty = {
    viatorDataEmpty: false,
    selectedDateActivitiesEmpty: false
  };
  
  @Input() set itineraryDates(val: ItineraryDate[]) {
    if (val) {
      console.log(val); // This will log the new value being set.
      this._itineraryDates = val;
       this.processDates(); // Make sure this method is correctly processing the dates.
    }
  }

  
  
  get itineraryDates(): ItineraryDate[] {
    return this._itineraryDates;
  }
  

  // // Initial Swiper options
  // swiperOptions: SwiperOptions = {
  //   // slidesPerView: 'auto',
  //   spaceBetween: 1,
  //   loop: false
  //   // Add more options as needed
  // };

  // updateGradient(attraction: any, acceptedInviteCount: number) {
  //   const percentage = this.getVotingPercentage(attraction, acceptedInviteCount);
  //   this.gradientStyle = {
  //     'background': `linear-gradient(to right, rgba(82, 166, 255, 0.2) ${percentage}%, white ${percentage}%)`,
  //     'transition': 'background-color 0.5s ease' // Add transition property dynamically
  //   };
  // }

  
  
  selectTag(tag,index: number, isTours): void {
    // Set the currently selected index to the one clicked
    this.selectedTagIndex = index;
    console.log(isTours)
    console.log(tag)
    if(isTours){
      this.viatorDestinationProducts = tag.products
      this.openActivityModal(true)

    } else {

      //this.openActivityModal(false)
      this.navigateToItineraryActivity(false)

    }

  }

 
  
  showSearchBar(){
    console.log("called")
    this.showSearchCalendar = false;
    this.isHidingOptions = false
    this.apiService.openSearchBar(true, true, this.tripId)
  }

  showCalendar(){
    this.showSearchCalendar = true;
    this.isHidingOptions = true
  }

  groupVotes() {
    this.groupedVotes = this.groupByDate(this.allPotentialDateVotes);
    console.log(this.groupedVotes)
    this.cdr.detectChanges()
  }

  groupByDate(votes: any[]): any {
    const groupedVotes: any = {};
    votes.forEach(vote => {
      const date = `${vote.startDate}-${vote.endDate}`;
      if (!groupedVotes[date]) {
        groupedVotes[date] = [];
      }
      groupedVotes[date].push(vote);
    });
    return groupedVotes;
  }

   compareDates(dateArray1, dateArray2) {
    // Check if the arrays have the same length
    // if (dateArray1.length !== dateArray2.length) {
    //     return false;
    // }
    const date1 = dateArray1

    // Iterate over each element in the arrays
    for (let i = 0; i < dateArray2.length; i++) {
        const date2 = dateArray2[i];

        // Convert date1 start and end to ISO strings
        const startDateString = new Date(date1.start).toISOString();
        const endDateString = new Date(date1.end).toISOString();

        // Convert date2 start and end to ISO strings
        const date2StartDateString = new Date(date2.startDate).toISOString();
        const date2EndDateString = new Date(date2.endDate).toISOString();
        console.log('comparing..',startDateString, 'to ',  date2StartDateString, 'for ', dateArray2[i])
        // Compare the start and end dates
        if (startDateString !== date2StartDateString || endDateString !== date2EndDateString) {
            return false;
        }
        this.voteBeingVotedOn = dateArray2[i]

    }
    console.log(this.voteBeingVotedOn)
    // All elements match
    return true;
}



  selectColumn(index: number) {
    this.selectedColumnIndex = index;
    console.log(this.selectedColumnIndex)
    if(this.selectedColumnIndex === 1){
      this.isTours = true;
      this.showNearbyRecommendations  = false;
      console.log("isTours", this.isTours)
      this.isShowingAccomodations = true;


    } else if (this.selectedColumnIndex === 2){
      this.isTours = false;
      this.showNearbyRecommendations  = false;
      this.isShowingAccomodations = true;
    } else if (this.selectedColumnIndex === 0){
      console.log('setting to false')
      this.isTours = false;
      this.showNearbyRecommendations = true
      this.isShowingAccomodations = false;

    }
    this.cdr.detectChanges()
    this.initSwiper();
  }
  isColumnSelected(index: number): boolean {
    return this.selectedColumnIndex === index;
  }
  
  
  
  showNearby(){
    this.showNearbyRecommendations = true;
  }

  normalizeDate(date: Date): string {
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1; // getMonth() is zero-based
    const day = date?.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
  
// Ensure that the dateClass function correctly highlights the dates
dateClass() {
  console.log('running')
  // if (this.isMyUserDateVoteAlreadySaved) {
  //   return;
  // }
  return (date: Date): string => {
    const highlightDate = this.parsedDateRanges.some(range => {
      return date >= range.startDate && date <= range.endDate;
    });
    console.log(highlightDate)
    return highlightDate ? 'special-date' : '';
  };
}
_onSelectedChange(date: Date): void {
  // Immediately exit the function if the user has already voted
  if (this.isMyUserDateVoteAlreadySaved) {
    return;
  }

  console.log(date);

  // Proceed with the existing logic
  if (
    this.selectedDateRange &&
    this.selectedDateRange.start &&
    date > this.selectedDateRange.start &&
    !this.selectedDateRange.end
  ) {
    this.selectedDateRange = new DateRange(
      this.selectedDateRange.start,
      date
    );
  } else {
    this.selectedDateRange = new DateRange(date, null);
  }
  
  console.log(this.selectedDateRange);
  console.log(this.compareDates(this.selectedDateRange, this.allPotentialDateVotes))
  this.isPiggyBacking = this.compareDates(this.selectedDateRange, this.allPotentialDateVotes) ? true: false;
  // Show save button if a date range is selected
  if (this.selectedDateRange) {
    this.showSaveCalendarDatesButton = true;
  }
}


// Method to fetch potential dates
getPotentialDates() {
  this.apiService.getPotentialDates(this.tripId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
    console.log(res);

    this.allPotentialDateVotes = res
    console.log(this.allPotentialDateVotes)
    // Check if the user's vote is already saved in any of the date ranges
    this.hasMyUserVoted(res);


    const dateRanges = res.map((range: any) => ({
      startDate: new Date(range.startDate),
      endDate: new Date(range.endDate)
    }));

    // Replace the existing parsedDateRanges array with the new date ranges
    this.parsedDateRanges = dateRanges;
    this.groupVotes();
    console.log(this.parsedDateRanges);
    
    // Trigger change detection if necessary
    // You might not need this if change detection is automatically triggered
    this.cdr.detectChanges();
  });
}

clearMyPotentialVotes(){
  this.apiService.clearMyPotentialVotes(this.tripId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
    
    this.allPotentialDateVotes = res
    // Check if the user's vote is already saved in any of the date ranges
    console.log(this.allPotentialDateVotes)
    const dateRanges = res.map((range: any) => ({
      startDate: new Date(range.startDate),
      endDate: new Date(range.endDate)
    }));
    // Replace the existing parsedDateRanges array with the new date ranges
    this.parsedDateRanges = dateRanges;
    this.groupVotes()
    this.hasMyUserVoted(res);

    console.log(this.parsedDateRanges);
    // Trigger change detection if necessary
    // You might not need this if change detection is automatically triggered
    this.selectedDateRange = null;
    if(this.selectedDateRange === null){
      this.showSaveCalendarDatesButton = false;
      
    }
    this.calendar.updateTodaysDate()
    this.apiService.presentToast('voteCleared')
  //  this.cdr.detectChanges();
  });
}


  private hasMyUserVoted(res: any) {
    console.log(res)
    this.isMyUserDateVoteAlreadySaved = res.some((range: any) => {
      return range.usersVotedYes && range.usersVotedYes.some((vote: any) => vote.userId === this.apiService.getMyUserId());
    });
    console.log(this.isMyUserDateVoteAlreadySaved)
    this.cdr.detectChanges();
  }

  saveMyDateSelection(vote, isPiggyBacking = false) {
    this.isPiggyBacking = isPiggyBacking;

    if (isPiggyBacking || this.isPiggyBacking) {
      vote = this.voteBeingVotedOn;
      this.selectedDateRange = new DateRange(vote.startDate, vote.endDate);
      console.log( this.selectedDateRange, vote)
      this.saveDateSelection(this.tripId, this.selectedDateRange, this.isPiggyBacking, vote._id);

    } else {
      this.saveDateSelection(this.tripId, this.selectedDateRange, false);

    }
 
    console.log(this.selectedDateRange)
    console.log(this.potentialVotes)
  }

  private saveDateSelection(tripId, selectedDateRange, isPiggyBacking, voteId?) {
    this.apiService.saveMyDateSelection(tripId, selectedDateRange, isPiggyBacking, voteId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      console.log(res);
      this.allPotentialDateVotes = res.matchingTrip;
      console.log(this.allPotentialDateVotes)
      this.groupVotes()
      this.hasMyUserVoted(res.matchingTrip);
        this.apiService.presentToast('voteAdded')
      // this.potentialVotes = [...this.potentialVotes, ...res.matchingTrip];
      this.cdr.detectChanges();

    });
  }
  ngOnInit(): void {
    console.log("call here...")
  }

  ngAfterViewInit(): void {
    console.log(this.dates)
     console.log(this.tripRecommendations)
     console.log(this.isTours)
    this.initSwiper();  
    if (this.parsedDateRanges.length === 0 && this.dates.length === 0) {
      this.getPotentialDates();
      
   }

    console.log(this.viatorTagAndProductData)
    // this.processDates();
    this.selectDate(0)
    this.originalViatorTagAndProductData = [...this.viatorTagAndProductData];
    this.filterByPrice(); // Initial filter to populate `filteredData`
    if(this.viatorTagAndProductData){
      this.isTagDataLoaded = true;
      console.log(this.isTagDataLoaded)
    }
    // this.apiService.getDestId('Ocho Rios, Jamaica').subscribe((res)=>{
    //   console.log(res)
    // })

    // // this.apiService.getViator().subscribe((res)=>{
    // //   console.log(res)
    // // })
    this.apiService.myUserData.pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe((res: any)=>{
      console.log(res)
      this.profilePicture = res.profilePicture;
      this.myUserId = res.id;

    })
    // this.dates = this.itineraryDates
    console.log(this.tripId)
    console.log(this.dates)
    console.log(this.tripLocation)
    // console.log(this.tripDates)


    if (this.dates.length > 0) {
       this.getFullSelectedDate(0) 
    } else {
      this.noDatesYet = true;
    }

    this.apiService.getLocationOption(this.tripId).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      console.log(res);
       this.locationsToVoteOn = []
      this.locationsToVoteOn.push(...res)
      this.locationsToVoteOn.reverse();
      console.log(this.locationsToVoteOn);
      this.isLoaded = true;
      console.log(this.isLoaded)
      this.cdr.detectChanges()
    });

    this.apiService.locationsToVoteOn.pipe(takeUntil(this.destroy$)).subscribe((newLocation) => {
      // Add the new location to the locationsToVoteOn array
      console.log(newLocation)
      if (newLocation) {
        console.log(newLocation)
        this.locationsToVoteOn.push(newLocation);
        this.locationsToVoteOn.reverse();
        console.log(this.locationsToVoteOn);
        this.cdr.detectChanges()
      }
    });

  }

  filterByPrice() {
    // Filter viatorTagAndProductData
    const filteredViatorData = this.originalViatorTagAndProductData.map(tag => ({
      ...tag,
      products: tag.products.filter(product =>
        product.pricing.summary.fromPrice <= this.maxPrice
      )
    })).filter(tag => tag.products.length > 0);
    
    // Update flag based on filteredViatorData being empty
    this.filterResultsEmpty.viatorDataEmpty = filteredViatorData.length === 0;
  
    // Assign the filtered result to viatorTagAndProductData
    this.viatorTagAndProductData = filteredViatorData;
  
    // Filter selectedDateActivities
    const filteredSelectedDateActivities = this.originalSelectedDateActivities.filter(activity =>
      activity.productPricing <= this.maxPrice
    );
  
    // Update flag based on filteredSelectedDateActivities being empty
    this.filterResultsEmpty.selectedDateActivitiesEmpty = filteredSelectedDateActivities.length === 0;
  
    // Assign the filtered result to selectedDateActivities
    this.selectedDateActivities = filteredSelectedDateActivities;
  
  }
  
  resetFilter(){
    this.maxPrice = 2000;
    this.viatorTagAndProductData = this.originalViatorTagAndProductData;
    this.selectedDateActivities = this.originalSelectedDateActivities
  }

  async toggleAttractionsVote(attraction: any) {
    console.log('yoyoyoyo')
    const userIndex = attraction.usersVotedYes.findIndex(user => user.userId === this.myUserId);
    attraction.shouldAnimate = false;

    if (userIndex > -1) {
      // User already voted, remove the vote
      attraction.usersVotedYes.splice(userIndex, 1);
      this.apiService.presentToast('voteCleared')

      this.removeAttractionVote(attraction)
    } else {
      // Add the user's vote
      await Haptics.impact({ style: ImpactStyle.Light });
      attraction.usersVotedYes.push({ userId: this.myUserId, profilePicture: this.profilePicture });
      console.log(attraction)
      this.apiService.presentToast('voteAdded')
      this.saveAttractionVote(attraction);
        // Trigger the animation
        attraction.shouldAnimate = true;
        setTimeout(() => {
            attraction.shouldAnimate = false;
        }, 1000); // Animation duration
   // }
    }
  
    // Send the updated attraction to the backend to save the changes
    // this.apiService.saveAttractionVote(attraction).subscribe({
    //   next: (response) => console.log('Vote saved', response),
    //   error: (error) => console.error('Error saving vote', error)
    // });
  
    // To make Angular detect changes in arrays/objects, you might need to manually trigger change detection or use a different approach to update your model
    this.selectedDateActivities = [...this.selectedDateActivities];
    this.cdr.detectChanges()

  }
  
// Inside your Angular component

hasUserVoted(attraction: any): boolean {
  return attraction.usersVotedYes.some(user => user.userId === this.myUserId);
}

  private saveAttractionVote(attraction: any) {
    this.apiService.saveAttractionVote(attraction.tripId, attraction.dayId, attraction.productCode).pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  private removeAttractionVote(attraction: any) {
    console.log(attraction)
    this.apiService.removeAttractionVote(attraction.tripId, attraction.dayId, attraction.productCode).pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  getTripItinerary(locations: string[]): string {
    return locations.map((location, index) => `${location}`).join(' -> ');
  }

  toggleVote(option: any) {
    const userIndex = option.usersVotedYes.findIndex((user: any) => user.userId === this.myUserId);
  
    if (userIndex !== -1) {
      // User has already voted, so unvote
      option.usersVotedYes.splice(userIndex, 1);
      option.checked = false;
      this.removeVote(option);

    } else {
      // User hasn't voted yet, so vote
      option.usersVotedYes.push({
        userId: this.myUserId,
        profilePicture: this.profilePicture,
      });
      option.checked = true;
      this.saveVote(option);
    }
  
    // For debugging purposes
    console.log(option);
  }

  // Assuming this is part of your component code
getDisplayedUsers(usersVotedYes: any[]) {
  const MAX_DISPLAY = 5;
  if (usersVotedYes.length > MAX_DISPLAY) {
    return usersVotedYes.slice(0, MAX_DISPLAY);
  }
  return usersVotedYes;
}

getAdditionalCount(usersVotedYes: any[]) {
  const MAX_DISPLAY = 5;
  if (usersVotedYes.length > MAX_DISPLAY) {
    return `+ ${usersVotedYes.length - MAX_DISPLAY}`;
  }
  return '';
}

  getVotingPercentage(option: any, totalVoters: number) {
  const votedCount = option.usersVotedYes.length;
  return (votedCount / totalVoters) * 100; // returns a percentage value
}


  private removeVote(option: any) {
    this.apiService.removeUserFromTripActivity(option._id, this.tripId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  private saveVote(option: any) {
    this.apiService.updateActivityWithUserSelection(option._id, this.tripId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        // Handle response
        console.log(res);
      });
  }

  handleReorder(event: CustomEvent) {
    // The logic to adjust the order of `selectedDateActivities` based on the reorder event
    const itemMove = this.selectedDateActivities.splice(event.detail.from, 1)[0];
    this.selectedDateActivities.splice(event.detail.to, 0, itemMove);
    event.detail.complete();
  }
  

  addActivity(date: any) {
    // Implement logic to add activity for the selected date
    console.log('Adding activity for date:', date);
    // You can call a service or perform any other action here to add the activity for the selected date
  }

  // ngAfterViewInit() {
  //   // Initialize Swiper after the view has been initialized
  //   // this.initSwiper();
  // }

  ngOnChanges(changes: SimpleChanges) {
    // When itineraryDates input changes, reinitialize Swiper
    console.log(changes)
    if (changes.itineraryDates) {
      console.log("Running....")
      this.initSwiper();
    }
    console.log(changes)
    // if (changes['allPotentialDateVotes']) {
    //   this.groupVotes();
    // }
  }

  
  initSwiper() {
    const slidesToShow = this.dates.length > 5 ? 5 : this.dates.length;
    const index = localStorage.getItem("dateIndex");

    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: slidesToShow,
      spaceBetween: 1,
      loop: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    if(index){
      console.log(index)
      // this.swiper.slideTo(parseInt(index)); // Replace 'indexNumber' with the desired slide index
      this.selectDate(parseInt(index))
    }

    this.swiper = new Swiper('.swiper-container2', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      loop: false,
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  }

  private processDates() {
    const daysOfWeek = {
      "Sun": "Sun",
      "Mon": "Mon",
      "Tue": "Tue",
      "Wed": "Wed",
      "Thu": "Thu",
      "Fri": "Fri",
      "Sat": "Sat"
    };
  
    this.dates = this._itineraryDates.map((itineraryDate) => {
      
      const dateParts = itineraryDate.date.split(' '); // Splits "Mon Mar 25th 2024" into ["Mon", "Mar", "25th", "2024"]
      const dayAbbreviation = daysOfWeek[dateParts[0]]; // Get the abbreviation for the day from the first part of the date string
      let dayOfMonth = dateParts[2]; // Get the day of the month with the suffix (e.g., "25th")
  
      // Remove the ordinal suffix from dayOfMonth
      dayOfMonth = dayOfMonth.replace(/(st|nd|rd|th)/, '');
  
      // Create the formatted date by combining the day abbreviation with the day of the month
      const formattedDate = `${dayAbbreviation} ${dayOfMonth}`;
  
      return {
        ...itineraryDate, // Spread the existing properties
        date: formattedDate, // Override the date property with the formatted date
      };
    });
  
    console.log(this.dates); // Output the processed dates
  }
  
  
  
  
  private extractDateNumber(dateString: string): number {
    // Remove any non-digit characters from dateString and parse it
    return parseInt(dateString.replace(/\D/g, ''), 10);
  }


// Update selectDate to include a new parameter with a default value
selectDate(i: number, shouldVibrate: boolean = false) {
  if(this.dates.length === 0){
    this.showCalendar()
    return;
  }
  this.dayId = this.dates[i]._id;
  this.getFullSelectedDate(i); // Load or update activities for the selected date
  localStorage.setItem("dateIndex", i.toString())
  this.getSavedAttractionsForTheDay();

  // Only call hapticFeedback if shouldVibrate is true
  if (shouldVibrate) {
    this.hapticFeedback();
  }

  // If you have swiper for details, navigate to the corresponding slide
  // if (this.swiper) {
  //   this.swiper.slideTo(i, 500);
  // }
}

async hapticFeedback() {
  console.log("haptic clicked");
  await Haptics.impact({ style: ImpactStyle.Light });
}

 public openSearchBar(){
  this.apiService.openSearchBar(true, true, this.tripId)
  this.isHidingOptions = false;

 }


  async openGoogleMaps(url: string) {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }

  getSavedAttractionsForTheDay(){
    this.apiService.getSavedAttractionsForTheDay(this.tripId, this.dayId).pipe(take(1))
    .subscribe((res: any) => {
      console.log(res)
     // if(res.length > 0){
        this.selectedDateActivities = res.reverse()
        console.log(this.selectedDateActivities)
        this.originalSelectedDateActivities = [...this.selectedDateActivities]
        console.log(this.selectedDateActivities)
        this.isLoaded = true;
        this.cdr.detectChanges()
     // }

    })
      
  }

  private getFullSelectedDate(i: number) {
    this.dates.forEach((date, index) => date.selected = index === i);
    const selectedDateObj = this._itineraryDates[i]; // This is now an object, not a string.
    const dateParts = selectedDateObj.date.split(' ');
    console.log(dateParts)
    const dayOfWeek = dateParts[0];
    const dayOfMonth = dateParts[2];
  
    // Format as "M 25th"
    this.formattedDateForItineraryPage = `${dayOfWeek} ${dayOfMonth}`;
  
    // Set the selected date property (if needed elsewhere in the code)
    this.selectedDate = this.formattedDateForItineraryPage;
  
    console.log(this.formattedDateForItineraryPage);
    this.loadSelectedDateActivities();
  }

  loadSelectedDateActivities() {
    // Assuming you have a method to load activities based on the selected date
    // this.selectedDateActivities = this.getActivitiesForDate(this.selectedDate);
  }
  async openWebpage(url: string) {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }
  
  async openActivityModal(isTours) {
    console.log(this.viatorDestinationProducts) // Ensure this is formatted as "M 25th"
    console.log(this.tripRecommendations)
    console.log(this.isTours)
    const modal = await this.modalCtrl.create({
      component: ItineraryActivitiesPage, // Adjust the component name
      componentProps: {
        isTours: isTours,
        selectedDate: this.formattedDateForItineraryPage,
        dayId: this.dayId,
        tripLocation: this.tripLocation,
        tripId: this.tripId,
        viatorDestinationProducts: this.viatorDestinationProducts,
        tripRecommendations: this.tripRecommendations,
        selectedDateActivities: this.selectedDateActivities
        // Other props as needed
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log(data)
    if(!data){
      console.log("done")
      return
    };
    this.selectedDateActivities = [...this.selectedDateActivities, ...data?.res.savedAttractions];
    this.selectedDateActivities = this.selectedDateActivities.reverse()
    this.cdr.detectChanges()
    console.log(this.selectedDateActivities)
  }

  navigateToItineraryActivity(isTours) {
    // Prepare the data
    const data = {
      isTours: this.isTours,
      selectedDate: this.formattedDateForItineraryPage,
      dayId: this.dayId,
      tripLocation: this.tripLocation,
      viatorDestinationProducts: this.viatorDestinationProducts,
      tripRecommendations: this.tripRecommendations
    };
    this.apiService.setGoogleMapData(data);
    console.log(data)
    // Navigate with the data
    this.router.navigate(['/itinerary-activity', this.tripId]);
  }

  ngOnDestroy() {
    // if (this.swiper) {
    //   this.swiper.destroy();
    // }
    this.selectedTagIndex = -1;
  }

}
