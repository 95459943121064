import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { take } from 'rxjs';
import { ActionSheetController, IonNav, ModalController } from '@ionic/angular';
import { ItineraryTypesPage } from '../itinerary-types/itinerary-types.page';

@Component({
  selector: 'app-linked-trips',
  templateUrl: './linked-trips.page.html',
  styleUrls: ['./linked-trips.page.scss'],
})
export class LinkedTripsPage implements OnInit {
  // @ViewChild(IonNav) nav: IonNav;
  @Input() item: any;
  public allTrips = []
  public isLinked = false;
  selectedTripIndex: number | null = null;

  constructor(private modalCtrl: ModalController, private apiService: ApiService, public actionSheetController: ActionSheetController, private nav: IonNav) { }

  ngOnInit() {
    // console.log(this.item)
    // this.apiService.getAllMyTrips().pipe((take(1))).subscribe((res) => {
    //   console.log(res)
    //   this.apiService.getLatestDataForTripsIAmInvitedTo().subscribe((data) => {
    //     console.log(data)
    //     this.allTrips = [...res.trips, ...data.matchingTrips];
    //     console.log(this.allTrips)
    //     if (Object.keys(this.item.isLinkedToTrip).length > 1) {
    //       // Your code here
    //       this.allTrips = this.allTrips.filter((trip) => this.item.isLinkedToTrip.uuid === trip.uuid)
    //       console.log(this.allTrips)
    //     }
    //   })

    // })

  }

  ngAfterViewInit(){

    console.log(this.item)
    this.apiService.getAllMyTrips().pipe((take(1))).subscribe((res) => {
      console.log(res)
      this.apiService.getLatestDataForTripsIAmInvitedTo().subscribe((data) => {
        console.log(data)
        this.allTrips = [...res.trips, ...data.matchingTrips];
        console.log(this.allTrips)
        if (Object.keys(this.item.isLinkedToTrip ?? {}).length > 1) {
          // Your code here

          this.allTrips = this.allTrips.filter((trip) => this.item.isLinkedToTrip.uuid === trip.uuid)
          console.log(this.allTrips)
          this.isLinked = true;
        }
      })

    })

  }

  navigateToItineraryPage(tripId) {
    console.log(tripId)
    this.nav.push(ItineraryTypesPage, { tripId: tripId, item: this.item });
  }

  async presentActionSheet(trip, index) {
    const actionSheet = await this.actionSheetController.create({
      header: `Link Itinerary for ${trip.tripName}`,
      buttons: [{
        text: 'Confirm',
        handler: () => {
          this.linkItinerary(trip._id);
        }
      }, {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          // Action cancelled
        }
      }]
    });
    await actionSheet.present();
  }

  linkItinerary(tripId: string) {
    // Logic to link itinerary for the selected trip
    console.log(`Linking itinerary for trip ID: ${tripId}`);
  }

  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }


}
