import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { DateRange, MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { ChangeDetectorRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActionSheetController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-vote-results',
  templateUrl: './vote-results.component.html',
  styleUrls: ['./vote-results.component.scss'],
})
export class VoteResultsComponent implements OnInit {
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  @Input() activitiesList: any;
  @Input() selectedCategory: any;
  @Input() myUserId: any;
  @Input() tripDetails: any;
  @Input() selectedCategoryId: any
  @Input() isVoted = false;
  private destroy$ = new Subject<void>();
  public isDataLoaded = false;
  public selectedActivity: any = '';
  public activityToDisplay = []
  public showClearSelectionButton = false;
  selectedDateRange: DateRange<Date>;
  parsedDateRanges: { start: Date, end: Date }[] = [];
  constructor(private apiService: ApiService,
     private cdr: ChangeDetectorRef, private alertController: AlertController,
     private actionSheetController: ActionSheetController) { }

  ngOnInit() {
    console.log(this.selectedCategory)
    this.activityToDisplay = this.activitiesList
    console.log(this.activitiesList)
    this.addHighlightedDates(this.activitiesList)
  }

  dateClass() {
   
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.parsedDateRanges.some(range => {
        return date >= range.start && date <= range.end;
      });
      return highlightDate ? 'special-date' : '';
    };
  }
  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
    console.log(this.selectedDateRange)
  }

  submitForm() {
    let startDate;
    let endDate;
   // if (this.campaignOne.valid) {
      if(this.selectedDateRange.start){
        startDate = this.selectedDateRange.start
      }
      if(this.selectedDateRange.end){
        endDate =  this.selectedDateRange.end
      }
      // const startDate = this.campaignOne.get('start').value;
      // const endDate = this.campaignOne.get('end').value;

      // Prepare the object to send to the database
      const dateRange = {
        startDate: startDate, // or format it as required
        endDate: endDate,     // or format it as required
        userId: this.myUserId,
        tripId: this.tripDetails.uuid,
        selectedCategoryId: this.selectedCategoryId
      };

      console.log(dateRange);
      this.apiService.saveUsersPreferredTripDates(dateRange.selectedCategoryId, this.tripDetails.uuid, dateRange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        // Handle response
        console.log(res)
        if(res.newActivity.tripId === this.tripDetails.uuid){
          if( this.activitiesList.length > 0){
            this.activitiesList.forEach((activity: any) => {
              console.log('activity ', activity);
              // if(activity.activityBelongsToCategoryId = "657daacfcd3d51e1e44e4c42"){
                
              // }
              // Find a matching result based on the _id
              // const matchingResult = res.findActivityAndUpdate.find((result: any) => result._id === activity._id);
              
              if(res.newActivity.activityBelongsToCategoryId === activity.activityBelongsToCategoryId){
                console.log('in here...')
                const matchingResult = res.findActivityAndUpdate
                this.activityToDisplay.push(matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid));
                // this.isVoted = activity.usersVotedYes.some((user)=> user.userId === this.myUserId)
                console.log('have i voted? ', this.isVoted)
                console.log(this.activityToDisplay)
                this.addHighlightedDates(activity);
              }
               else {
                // If no matching result is found, ensure usersVotedYes is empty or untouched
                // depending on your requirement
                //  activity.usersVotedYes = []; // Uncomment this line if you want to clear usersVotedYes when no matching result is found
              }
            });
          } else {
            this.activityToDisplay.push(res.newActivity)
            console.log(this.activitiesList)
            this.apiService.updateDataForActivityList(this.activitiesList)
  
            this.addHighlightedDates(res.newActivity);
          }
        
        }
     
        
        this.isDataLoaded = true;
      });
 // }
      // Here, send 'dateRange' to your backend or database
   // }
  }
  clearDates(event){

    this.presentActionSheet()
    //  const isChecked = event.detail.checked
      // this.toggleActivity(activity, true)
      // console.log(this.selectedActivity)
      // const userIndex = this.selectedActivity.usersVotedYes.findIndex(user => user.userId === this.myUserId);
      
      // if ( userIndex > -1) {
      //   this.selectedActivity.usersVotedYes.splice(userIndex, 1);
      //   // Call API to remove vote
      //   this.apiService.removeUserFromTripActivity(this.selectedActivity._id, this.tripDetails.uuid)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe((res: any) => {
      //       // Handle response
      //       console.log(res)
      //       this.activitiesList.forEach((activity: any) => {
      //         console.log('activity ', activity);
          
      //         if(res.updatedActivity._id === activity._id){
      //           const matchingResult = res.updatedActivity;
      //           activity.usersVotedYes = matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid);
      //           // this.isVoted = activity.usersVotedYes.some((user)=> user.userId === this.myUserId)
      //           console.log('have i voted? ', this.isVoted)
      //           this.addHighlightedDates(activity);
      //           this.selectedDateRange = null;
      //           this.refreshCalendar()
      //         }
      //          else {
      //           // If no matching result is found, ensure usersVotedYes is empty or untouched
      //           // depending on your requirement
      //            activity.usersVotedYes = []; // Uncomment this line if you want to clear usersVotedYes when no matching result is found
      //         }
      //       });
      //       this.isDataLoaded = true;
      //     });
      // }
  
    }

    async presentActionSheet() {
      console.log(this.activitiesList)
      this.actionSheetController.create({
        header: 'Accept Invitation',
        buttons: [
          {
            text: 'Delete Your Selection?',
            icon: 'trash-outline',
            cssClass: 'success-button',
            handler: async () => {
              if (this.activitiesList[0].usersVotedYes.length >= 2) {
                console.log('presenting alert..')
                // If more than two users have voted, present an ion-alert
                this.presentAlert();
              } else {
                // Handle the deletion normally if 2 or fewer users have voted
              }
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            icon: 'close',
            handler: () => {
              // Handle the cancel action if needed
            },
          },
        ],
      }).then((actionSheet) => {
        actionSheet.present();
      });
    }
    
    async presentAlert() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Confirm Deletion',
        message: 'More than two users have voted for this date. Are you sure you want to delete it?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Yes, delete it',
            handler: () => {
              console.log('Confirm Okay');
              // Add your deletion logic here
            }
          }
        ]
      });
    
      await alert.present();
    }
    




    private refreshCalendar() {
      if (this.calendar) {
        // Refresh the calendar to apply changes
        this.calendar.updateTodaysDate();
      }
    }
    private addHighlightedDates(activities: any[]) {
      // Initialize an empty array to hold all parsed date ranges
      this.parsedDateRanges = [];
    
      // Loop through each activity
      activities.forEach(activity => {
        console.log(activity);
        this.showClearSelectionButton =  activity.usersVotedYes.some((vote)=> vote.dateSelectionOwner === this.myUserId) 
        
        // Extract and parse the date ranges for each user who voted 'yes' for the current activity
        const dateRanges = activity.usersVotedYes.map((range: any) => ({
          start: new Date(range.startDate),
          end: new Date(range.endDate)
        }));
    
        // Concatenate the new date ranges to the existing parsedDateRanges array
        this.parsedDateRanges = this.parsedDateRanges.concat(dateRanges);
      });
    
      // At this point, this.parsedDateRanges contains all parsed date ranges from all activities
      console.log('All Parsed Date Ranges:', this.parsedDateRanges);
    }
    

}
