// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-background {
  background-color: #f5c8A2 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyIsIi4uLy4uLy4uL2FuZ3VsYXIlMjA0L3NyYy9hcHAvYXBwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0ksb0NBQUE7QUNESiIsImZpbGUiOiJhcHAuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLnNwbGFzaC1iYWNrZ3JvdW5ke1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNmNWM4QTIgIWltcG9ydGFudFxuICB9XG5cbiIsIi5zcGxhc2gtYmFja2dyb3VuZCB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmNWM4QTIgIWltcG9ydGFudDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../angular%204/src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACI,oCAAA;ACDJ;ADCA,gfAAgf","sourcesContent":["\n\n.splash-background{\n    background-color: #f5c8A2 !important\n  }\n\n",".splash-background {\n  background-color: #f5c8A2 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
