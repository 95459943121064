import { Component, OnInit } from '@angular/core';
import { IonNav, NavController, NavParams } from '@ionic/angular';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-conversation-details',
  templateUrl: './conversation-details.page.html',
  styleUrls: ['./conversation-details.page.scss'],
})
export class ConversationDetailsPage implements OnInit {
  public comments = []
  public tripLocation: string;
  public topicId: string;
  public topic: any
  public commentText: string = '';
  constructor(private ionNav: IonNav, private apiService: ApiService, private navParams: NavParams) { }

  ngOnInit() {
    console.log('hello', this.topic)
    this.tripLocation = this.navParams.get('tripLocation');
    this.topicId = this.navParams.get('topicId')
    console.log(this.topicId)

    this.apiService.getCountryComment(this.tripLocation, this.topicId).subscribe((res)=>{
      console.log(res)
      this.comments = res.comments
    })
  }

  goBack(){
    this.ionNav.pop()
  }

  postComment() {
    const commentData = { text: this.commentText, topicId: this.topicId };
    this.apiService.postCountryComment(this.tripLocation, commentData).subscribe((res: any) => {
      console.log(res);
      if (res && res.newComment) {
        // Use the spread operator to create a new array with the new comment appended
        this.comments = [...this.comments, res.newComment];
      }
    });
    

  }

}
