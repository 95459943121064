import { Component, Input, OnInit } from '@angular/core';
import { Contacts } from '@capacitor-community/contacts';
import { contacts } from '../contacts';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss'],
})
export class InviteFriendsComponent implements OnInit {
  @Input() tripDetails?: any
  @Input() parentComponent: string;
  public permission: any;
  public contacts: any[] = [];
  public searchActive: boolean = false;
  public userDeclinedContactsAccess = false;
  public filteredContacts: any[] = [];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
      this.loadContacts()
      console.log(this.tripDetails)
      console.log(this.parentComponent)
  }

  async loadContacts(){
    console.log('loading contacts...')
    //db lookup to see if matching numbers
   // this.userDeclinedContactsAccess = false;
    try {
      const permission = await Contacts.requestPermissions();
      this.permission = permission
      if(!permission?.contacts) return;
      else if(permission?.contacts === 'granted'){
        Contacts.getContacts({
          projection: {
            name: true,
            phones: true,
            image: true
          }
        }).then((result)=>{
          console.log('result',result)
          this.contacts = result.contacts
        })
      } else {
        this.userDeclinedContactsAccess = true;

        //need a solution for when user declines.
      }

    } catch(e){
      console.log(e)
      // need a solution for when user is on web
      if(e){ 
        const user = 
        {
          "contactId": "177C371E-701D-42F8-A03B-C61CA31627F7",
          "name": {
        
              "display": "Phillip DaCosta",
          },
          "phones": [
              {
                  "number": "(404) 751-7786",
                  "type": "mobile"
              }
        
          ]
      }
        
        this.contacts = contacts
        this.contacts.push(user)
        console.log( this.contacts)
      }
    }

  }
  searchContacts(query: string) {
    // Convert the query to lowercase for case-insensitive search
    const lowercaseQuery = query.toLowerCase();
  
    // Check if the search query is empty
    this.searchActive = query.trim().length > 0;
  
    // Filter the contacts based on the search query
    this.filteredContacts = this.contacts.filter((contact) => {
      // Check if the contact's name or any phone number matches the search query
      return (
        contact.name.display.toLowerCase().includes(lowercaseQuery) ||
        contact.phones.some((phone) => phone.number.toLowerCase().includes(lowercaseQuery))
      );
    });
  }


}
