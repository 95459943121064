import { Component, OnInit, NgZone, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { callbackUri } from './auth.config';
import { ThemeService } from './theme.service';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SocketService } from './socket.service';
import { Platform, ToastController } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { shuffle } from 'lodash'; // Import the shuffle function from lodash or implement your own
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public myData: any;
  @Output() dataLoaded = new EventEmitter(false);
  experiences = []
  currentPage = 0;
  pageSize = 25; // You can change this to how many items you want per page
  private loading = false;
  private subscription: Subscription;
  isUserActive = false;

  constructor( private toastCtrl: ToastController,
  public auth: AuthService, private ngZone: NgZone, private platform: Platform,
  private themeService: ThemeService, private apiService: ApiService, 
  private socketService: SocketService) {
  //  this.showSplash()
    this.loadExperiences()
    this.apiService.getTravelArticles();

    this.subscription = this.apiService.triggerFunctionInAppComponent$.subscribe(() => {
    this.experiences = [];
     this.apiService.isExperienceLoaded = false;
      this.loadExperiences();
    });


    defineCustomElements(window);
  }
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }



  ngOnInit(): void {
    
    this.apiService.fetchUserData().pipe(takeUntil(this.destroy$)).subscribe(myUserData => {
      console.log(myUserData)
      this.socketService.joinTripMyIdRoom(myUserData.id);
      
    });


    // this.apiService.getUserAuthenticationData().pipe(takeUntil(this.destroy$)).subscribe((user) => {
    //   console.log(user)
    //   this.apiService.getUserDataFromDatabase(user).pipe(take(1)).subscribe((myUserData) => {
    //     console.log(myUserData)
    //     this.apiService.setMyUserId(myUserData.id)

    //     this.isUserActive = myUserData.isActive;

    //     if(this.isUserActive){
    //       this.apiService.getAllPublicTrips().pipe((takeUntil(this.destroy$))).subscribe((res) => {
    //         console.log(res);
    //         this.apiService.updateArray(res.trips)

    //       });
    //     }
    //    this.apiService.setTripData(myUserData.trips)
    //    this.socketService.joinTripMyIdRoom(myUserData.id)
  
    //       if(!myUserData.doesEmailExist){

    //        document.body.classList.toggle(undefined, myUserData.isDarkMode);
    //         this.themeService.setDarkModeStatus(myUserData.isDarkMode)
    //       }
    //       this.apiService.setMyUserEmail(myUserData.email)
    //       this.apiService.setMyUserToken(myUserData.token)
    //       this.apiService.setMyUserPhoneNumber(myUserData.phoneNumber)

    //       console.log(myUserData)
    //       this.apiService.setMyUserData(myUserData)
    //       this.themeService.isDark = myUserData.isDarkMode

    //   });
     
    // });


 
    localStorage.removeItem('multiLocationArray');
  //  this.apiService.intiateData$.subscribe((res)=>{
      console.log('initiating data...')
      this.socketService.onUserAddedToTrip().subscribe((res)=>{
        console.log(res)
      this.apiService.presentToast('notifyUserBeenAddedToTrip', res.updatedTrip.tripName)
      })

    App.addListener('appUrlOpen', ({ url }) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        if (url?.startsWith(callbackUri)) {
          if (
            url.includes('state=') &&
            (url.includes('error=') || url.includes('code='))
          ) {
            this.auth
              .handleRedirectCallback(url)
              .pipe(mergeMap(() => Browser.close()))
              .subscribe();
          } else {
            Browser.close();
          }
        }
      });
    });
    
    

    this.socketService.receiveTaggedNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
      this.apiService.presentToast('taggedUser', res.tripName)
    })
  }

  loadExperiences() {
    // Check if we're already loading data to prevent duplicate calls
    console.log("loading experiences...")
    if (this.loading) {
      return;
    }
  
    this.loading = true; // Set loading state to true
  
    const currentSearchHistory = this.apiService.getSearchHistory();
    const hasSearchParameter = currentSearchHistory && currentSearchHistory.length > 0;
    // Calculate the offset for the pagination based on the current page
    const offset = this.currentPage * this.pageSize;
  
    // Construct the appropriate API call
    const attractionsObservable = this.apiService.getRandomAttractions(
      hasSearchParameter ? currentSearchHistory : undefined, 
      this.pageSize, 
      offset
    );
  
    attractionsObservable.pipe(
      takeUntil(this.destroy$), // Ensure subscription is cleaned up appropriately
      tap({
        next: res => {
          // Handle the API response
          if (res && res.length) {
            // Append new experiences to the current list
         
            this.experiences = [...this.experiences, ...res];
            this.currentPage++; // Increment currentPage for the next load
            this.apiService.experiencesSubject.next(this.experiences); // Optionally, update a BehaviorSubject or similar if used

            console.log("Finished")
            this.apiService.isExperienceLoaded = true
          } else {
            // Optionally handle an empty or null response
            this.apiService.experiencesSubject.next(this.experiences);
          }
        },
        error: error => {
          console.error('Error fetching attractions:', error);
          this.apiService.experiencesSubject.next(this.experiences); // Optionally handle errors by notifying subscribers
        },
        complete: () => this.loading = false // Reset loading state when the stream completes
      })
    ).subscribe();
  }
  

  onSwiperReachEnd() {
    // Load more experiences when the end of the swiper is reached
    this.loadExperiences();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

  }
}
