import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, map, switchMap, take, tap, throwError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { ActionSheetController, AnimationBuilder, AnimationController, IonInput, ModalController, NavController, ToastController } from '@ionic/angular';
import { Forum, TripData } from 'interfaces/trip-interface';
import { NotificationData } from 'interfaces/notification-data';
import { SocketService } from './socket.service';
import { SearchBarPage } from './search-bar/search-bar.page';
import { TabsServiceService } from './tabs-service.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public appName = "Ollie"
  public userFirstName: String;
  public isTripPlannerPage = false;
  public showFabIcon = false;
  public initialBreakpoint = 0.5
  private isUserActive = new BehaviorSubject<boolean>(false);
  private token = this.getMyUserToken();
  public returnMyUserId;
  public tripsIAmInvitedToNotification = false;
  public myTripsNotification = false;
  public isAuthenticated = false;
  public userData: any;
  public SEARCH_HISTORY_KEY = 'countrySearchHistory';
  public isExperienceLoaded = false;
  public isMapLoaded = false;
 //public backendUrl = "http://localhost:5000";
  //public backendUrl = "https://travelbugserver-1-new.onrender.com";
 public backendUrl = "https://travelbugserver.onrender.com"

  public tripData = new BehaviorSubject<any[]>([]);
  public singleTrip = new BehaviorSubject<any[]>([]);
  public myUserData: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  private userDataSubject = new BehaviorSubject<any>(null);
  userData$ = this.userDataSubject.asObservable();
  private replySource = new BehaviorSubject<string>('');
  reply$ = this.replySource.asObservable();

  public intiateData = new BehaviorSubject<any>(null);
  intiateData$ = this.intiateData.asObservable();
  public showFooterOverlay: boolean = false;

  private email: string;
  private removeUser = false;
  public sentInvites = []
  public myTripData = {
    tripName: '',
    isTripPublic: true,
    isGoingWithFriendsFamily: false,
    tripLocation: [],
    tripDates: [],
    invitees: [],
    tripLimitNumber: '',
    isEcoFriendly: false,
    isMultiLocation: false
  }
  @ViewChild('messageInput', {static: false}) messageInput!: IonInput;
  private sharedDataSubject = new BehaviorSubject<any>(null);
  sharedData$ = this.sharedDataSubject.asObservable();
  public invitedTrip = []
  public tripData$ = this.tripData.asObservable();
  public singleTripData$ = this.singleTrip.asObservable();
  public myUserId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private myUserEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private myUserPhoneNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private myUserToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public notificationNumberSubject = new BehaviorSubject<NotificationData>(null);
  notificationNumber$ = this.notificationNumberSubject.asObservable();

  public getPublicTrip = new BehaviorSubject<any>(null);
  getPublicTrip$ = this.getPublicTrip.asObservable();
  public allPublicTripsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public allTripsOwnedByMe: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private tripDataSubject = new BehaviorSubject<any | null>(null);

  public getDataForASingleTrip = new BehaviorSubject<any>(null);
  getDataForASingleTrip$ = this.getDataForASingleTrip.asObservable();



  public getActivityList = new BehaviorSubject<any>(null);
  getDataForActivityList$ = this.getActivityList.asObservable();


  private postForum = new BehaviorSubject<Forum[]>([]);
  currentForumList = this.postForum.asObservable();


  public locationsSubject = new BehaviorSubject<any[]>([]);
  locations$ = this.locationsSubject.asObservable();

  public locationsToVoteOn = new BehaviorSubject<any>(null);
  locationsToVoteOn$ = this.locationsToVoteOn.asObservable();

  public articles = new BehaviorSubject<any>(null);
  readingArticles$ = this.articles.asObservable();

  public coordinates = new BehaviorSubject<any>(null);
  coordinates$ = this.coordinates.asObservable();

  public myCoordinates = new BehaviorSubject<any[]>([]);
  myCoordinates$ = this.myCoordinates.asObservable();

  private setBreakpoint = new Subject<void>();
  setModalBreakpoint$ = this.setBreakpoint.asObservable();

  private modalSub: Subscription;
  private modal: HTMLIonModalElement;


  public googleData = new BehaviorSubject<any>({});
  currentGoogleData = this.googleData.asObservable();

  public  tripRestaurants = new BehaviorSubject<any>({});
  tripRestaurantsData = this.tripRestaurants.asObservable();

  experiencesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private triggerFunctionSource = new Subject<void>();

  triggerFunctionInAppComponent$ = this.triggerFunctionSource.asObservable();
  private myUserDataSubject = new BehaviorSubject<any|null>(null);

  private googleMapModalState = new BehaviorSubject<boolean>(false);

  public  moveToNextStepInStepper = new BehaviorSubject<any>({});
  moveToNextStepInStepperEvent = this.moveToNextStepInStepper.asObservable();


  constructor(public auth: AuthService, private modalCtrl: ModalController,private animationCtrl: AnimationController,private tabsService: TabsServiceService, private navCtrl: NavController,
    private actionSheetController: ActionSheetController, private router: Router, private http: HttpClient, private toastCtrl: ToastController,) {
    console.log(this.myTripData)
    console.log(this.myUserId)
    this.modalSub = this.setModalBreakpoint$.subscribe(() => {
      console.log('closing modal..')
      this.modal.setCurrentBreakpoint(0.3)
      // this.modal.dismiss()
    });
    // this.modalBreakpointSubject.subscribe((data)=>{
    //   console.log(data)
    //   this.initialBreakpoint = data;
    //   this.openSearchBar(this.initialBreakpoint)
    
    // })
  }

  // updateModalBreakpoint(breakpoint: number) {
  //   this.modalBreakpointSubject.next(breakpoint);
 
  // }

  showModal() {
    console.log("opening modaalll")

    this.googleMapModalState.next(true);
  }

  hideModal() {
    console.log("closing modaalll")
    this.googleMapModalState.next(false);
  }

  getModalState() {
    return this.googleMapModalState.asObservable();
  }

  fetchUserData() {
    return this.getUserAuthenticationData().pipe(
      switchMap(user => {
        // Do something with the user data or just pass it along
        console.log(user);
        this.userFirstName = user.given_name
        return this.getUserDataFromDatabase(user).pipe(take(1));
      }),
      tap(myUserData => {
        // Perform all the initializations that depend on myUserData
        this.setMyUserData(myUserData);
        console.log(myUserData);
        this.setMyUserId(myUserData.id);
        this.isUserActive = myUserData.isActive; // Assuming this is a service property
        
        if (this.isUserActive) {
          // Possibly move this logic to a separate method if it gets too complex
          this.getAllPublicTrips().subscribe((res) => {
            console.log(res);
            this.updateArray(res.trips);
          });
        }
        
        this.setTripData(myUserData.trips);
        // this.socketService.joinTripMyIdRoom(myUserData.id);
        
        // Toggle dark mode
        document.body.classList.toggle(undefined, myUserData.isDarkMode);
        // this.themeService.setDarkModeStatus(myUserData.isDarkMode);
        
        // Set user-specific details
        this.setMyUserEmail(myUserData.email);
        this.setMyUserToken(myUserData.token);
        this.setMyUserPhoneNumber(myUserData.phoneNumber);
        
        // Update user data in the service
        // this.themeService.isDark = myUserData.isDarkMode; // Assuming this is a service property
      })
    );
  }

  setStepperState(data: any) {
    this.moveToNextStepInStepper.next(data);
  }



  setTripRestaurantData(data: any) {
    this.tripRestaurants.next(data);
  }

  setGoogleMapData(data: any) {
    this.googleData.next(data);
  }

  saveExperiencies(data: any){

    this.experiencesSubject.next(data);

  }

  setCoordinates(data: any) {
    this.coordinates.next(data);
  }

  getCoordinates() {
    return this.coordinates.asObservable();
  }

  setMyCoordinates(data: any[]) {
    this.myCoordinates.next(data);
  }

  getMyCoordinates() {
    return this.myCoordinates.asObservable();
  }


  setReadingArticles(data: any) {
    this.articles.next(data);
  }

  getReadingArticles() {
    return this.articles.asObservable();
  }

  setLocationsToVoteOn(data: any) {
    this.locationsToVoteOn.next(data);
  }

  getLocationsToVoteOn() {
    return this.locationsToVoteOn.asObservable();
  }

  setModalBreakpoint() {
    this.setBreakpoint.next();
  }

  setNewModalBreakpoint(){
    this.modal.setCurrentBreakpoint(0.5)
  }
  
  setLocations(locations: any[]): void {
    this.locationsSubject.next(locations);
  }


  updateLocations(newLocation): void {
    // Get current locations array
    // const currentLocations = this.locationsSubject.getValue();
  
    // // Filter out the location to be removed
    // const updatedLocations = currentLocations.filter(location =>
    //   location.lngLat[0] !== newLocation.lngLat[0] || location.lngLat[1] !== newLocation.lngLat[1]
    // );
  
    // Append new location to the array
    // updatedLocations.push(newLocation);
    //   console.log(updatedLocations)
    // Update the BehaviorSubject with the new array
    this.locationsSubject.next(newLocation);
  }
  
  
  
  
  updateForumList(data: Forum[]) {
    this.postForum.next(data);
  }

  getDataForActivityList(): any {
    return this.getActivityList.value;
  }

  updateDataForActivityList(data: any): void {
    this.getActivityList.next(data);
  }

  getDataForASingleTripObs(): any {
    return this.getDataForASingleTrip.value;
  }

  updateDataForASingleTripObs(data: any): void {
    this.getDataForASingleTrip.next(data);
  }


  sendReply(reply: string) {
    this.replySource.next(reply);
  }


  updateAllTripsOwnedByMe(newArray: any[]): void {
    this.allTripsOwnedByMe.next(newArray);
  }

  // Example method to get the current array value
  getAllTripsOwnedByMe(): any[] {
    return this.allTripsOwnedByMe.value;
  }

  updateArray(newArray: any[]): void {
    this.allPublicTripsArray.next(newArray);
  }

  // Example method to get the current array value
  getArrayValue(): any[] {
    return this.allPublicTripsArray.value;
  }

  getNotificationNumber(): NotificationData {
    return this.notificationNumberSubject.value;
  }

  updateNotificationNumber(data: NotificationData): void {
    this.notificationNumberSubject.next(data);
  }

  setTabs2TripData(data: any) {
    this.tripDataSubject.next(data);
  }

  getTabs2TripData() {
    return this.tripDataSubject.asObservable();
  }

  setSharedData(data: any) {
    this.sharedDataSubject.next(data);
  }

  updateUserData(data: any) {
    this.userDataSubject.next(data);
  }
  

  public getMyUserData(): any {
    return this.myUserData.getValue();
  }

  getStoredUserData(): Observable<any> {
    return this.myUserData.asObservable();
  }
  public setMyUserData(data?: any) {
    this.myUserData.next(data);
  }
  public setTripData(data: any[]): void {
    this.tripData.next(data);
// Call the callback function after setting the data
  }
  public getTripData(): any[] {
    return this.tripData.getValue();
  }

  public getSingleTripData(): any {
    return this.singleTrip.getValue();
  }

  public setSingleTripData(data?: any) {
    this.singleTrip.next(data);
  }

  public getIsUserActiveStatus(): any {
    return this.isUserActive.getValue();
  }

  public setIsUserActiveStatus(data?: any) {
    this.isUserActive.next(data);
  }

  // Define a method to set new trip data and notify observers
  public getMyUserId(): string {
    return this.myUserId.value;
  }

  // Setter for the string
  public setMyUserId(value: string) {
    console.log(value)
     this.returnMyUserId = value;

    this.myUserId.next(value);
  }

    // Define a method to set new trip data and notify observers
    public getMyUserEmail(): string {
      return this.myUserEmail.value;
    }
  
    // Setter for the string
    public setMyUserEmail(value: string) {
      console.log(value)
      this.myUserEmail.next(value);
    }

    public getMyUserPhoneNumber(): string {
      return this.myUserPhoneNumber.value;
    }
  
    // Setter for the string
    public setMyUserPhoneNumber(value: string) {
      console.log(value)
      this.myUserPhoneNumber.next(value);
    }

    public getMyUserToken(): string {
      return this.myUserToken?.value;
    }
  
    // Setter for the string
    public setMyUserToken(value: string) {
      console.log(value)
      this.myUserToken.next(value);
    }

    public triggerAppComponentFunction() {
      this.triggerFunctionSource.next();
    }



  public authAndRoute(): boolean {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated
      console.log(this.isAuthenticated)
      if (isAuthenticated) {
        this.getUserAuthenticationData()
        // this.getUserDataFromDatabase()
        // this.router.navigate(['/tabs/tab1']);
      }
    });
    return this.isAuthenticated;
  }
  

  public getUserAuthenticationData(): Observable<any> {
    return this.auth.user$;
  }
  public getUserDataFromDatabase(user: any): Observable<any> {
    const userObject = {
      email: user.email,
      fullName: user.name
    };
  console.log(userObject)
    // Make an HTTP GET request to your backend API endpoint
    return this.http.get<any>(this.backendUrl + '/user-data', {
      params: userObject
    })
  }

  public getTripDetails(tripId: any): Observable<any> {
    const userObject = {
      tripId: tripId,
    };
  
    // Make an HTTP GET request to your backend API endpoint
    return this.http.get<any>(this.backendUrl + '/getTripDetails', {
      params: userObject
    }).pipe(
      catchError((error: any) => {
        // You can log the error or perform other error handling tasks here
        // if(error === 'Internal server error'){
        //   console.log('ooppsis')
        //  this.auth.logout()
        //   // this.router.navigateByUrl('/home')
        // // }
        // console.error('An error occurred while fetching user data:', error);
        return throwError(error); // Re-throw the error to propagate it to the caller
      })
    );
  }



  public getMyNotifications(): Observable<any> {
    const myUserId = this.getMyUserId();
    
    // Retrieve the token from where you have it stored in your Angular application (e.g., local storage, session storage, or as a variable)
    // const token = this.getMyUserToken();
    // console.log(token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
  
    // Make an HTTP GET request to your backend API endpoint with the Authorization header
    return this.http.get<any>(this.backendUrl + '/getMyNotifications', {
      // headers,
      params: { myUserId }
    });
  }


  public getSavedAttractionsForTheDay(tripId, dayId): Observable<any> {
  
    // Make an HTTP GET request to your backend API endpoint with the Authorization header
    return this.http.get<any>(this.backendUrl + '/getSavedAttractionsForTheDay', {
      params: { tripId,  dayId}
    });
  }

  getViatorTags(): Observable<any> {
    return this.http.get(this.backendUrl + '/getViatorTags');
  }
  

  getViatorDestId(location): Observable<any>{
    return this.http.get<any>(this.backendUrl + '/getDestId', {
      // headers,
      params: { location }
    });
  }

  getViatorProductsByDestinationTag(tagId, viatorDestId): Observable<any>{
    console.log(tagId)
    return this.http.get<any>(this.backendUrl + '/getViatorProductsByDestinationTag', {
      // headers,
      params: { tagId: tagId, viatorDestId }
    });
  }


  getViatorTagsAndProductsByDestination(viatorDestId): Observable<any>{
    console.log("MAN DEM CALL WE API AGAIN BREDDA")
    return this.http.get<any>(this.backendUrl + '/getViatorTagsAndProductsByDestination', {
      // headers,
      params: {  viatorDestId }
    });
  }

  public getViatorThingsToDo(location): Observable<any> {
    // const myUserId = this.getMyUserId();
    
    // Retrieve the token from where you have it stored in your Angular application (e.g., local storage, session storage, or as a variable)
    // const token = this.getMyUserToken();
    // console.log(token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
  
    // Make an HTTP GET request to your backend API endpoint with the Authorization header
    return this.http.get<any>(this.backendUrl + '/getViatorThingsToDo', {
      // headers,
      params: { location }
    });
  }

  public getViator(destinationId): Observable<any> {
    const myUserId = this.getMyUserId();
    
    // Retrieve the token from where you have it stored in your Angular application (e.g., local storage, session storage, or as a variable)
    // const token = this.getMyUserToken();
    // console.log(token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
  
    // Make an HTTP GET request to your backend API endpoint with the Authorization header
    return this.http.get<any>(this.backendUrl + '/getViatorThingsToDo', {
      // headers,
      params: { destinationId }
    });
  }

  public getCountryCityImages(city): Observable<any> {
    const myUserId = this.getMyUserId();

    // Make an HTTP GET request to your backend API endpoint with the Authorization header
    return this.http.get<any>(this.backendUrl + '/getCountryCityImages', {
      // headers,
      params: { city }
    });
  }

  public createTrip(trip: any): Observable<any> {
    const myUserId = this.getMyUserId();

    const tripData = {
      trip: trip,
      myUserId: myUserId
    }
    return this.http.post(this.backendUrl + '/createATrip', tripData, {})
  }

  

  public saveAttractionVote(tripId: any, dayId, productCode): Observable<any> {
    const myUserId = this.getMyUserId();
    

    const tripData = {
      tripId: tripId,
      dayId: dayId,
      myUserId: myUserId,
      productCode: productCode
    }
    return this.http.post(this.backendUrl + '/saveAttractionVote', tripData, {})
  }

  public removeAttractionVote(tripId: any, dayId, productCode): Observable<any> {
    const myUserId = this.getMyUserId();

    const tripData = {
      tripId: tripId,
      dayId: dayId,
      myUserId: myUserId,
      productCode: productCode
    }
    return this.http.post(this.backendUrl + '/removeAttractionVote', tripData, {})
  }

  public importItinerary(formData): Observable<any> {
    const myEmail = this.getMyUserEmail();
    console.log(myEmail)
    formData.append('userEmail', myEmail); 
    return this.http.post(this.backendUrl + '/api/import', formData, {})
  }


  public addItineraryToTrip(tripId: any, itineraryData): Observable<any> {

    const myUserId = this.getMyUserId();

    const postData = {
      itinerary: itineraryData,
      myUserId: myUserId,
      tripId: tripId
    }
    return this.http.post(this.backendUrl + '/addItineraryToTrip', postData, {})
  }

  public saveCategories(tripId: string, selectedCategories: any): Observable<any> {
    const tripData = {
      tripId: tripId,
      selectedCategories: selectedCategories
    }
    console.log(tripData)
    return this.http.post(this.backendUrl + '/saveCategories', tripData, {})
  }

  public saveMyDateSelection(tripId: string, selectedDates: any, isPiggyBacking, voteId): Observable<any> {
    const myUserId = this.getMyUserId();
    const tripData = {
      tripId: tripId,
      selectedDates: selectedDates,
      myUserId: myUserId,
      isPiggyBacking: isPiggyBacking,
      voteId: voteId
    }
    console.log(tripData)
    return this.http.post(this.backendUrl + '/saveMyDateSelection', tripData, {})
  }

  public getPotentialDates(tripId: string): Observable<any> {
    const myUserId = this.getMyUserId();
    return this.http.get<any>(this.backendUrl + '/getPotentialDates', {
      // headers,
      params: { tripId }
    });
    }

    public clearMyPotentialVotes(tripId: string): Observable<any> {
      const myUserId = this.getMyUserId();
      return this.http.delete<any>(this.backendUrl + '/clearMyPotentialVotes', {
        // headers,
        params: { tripId,myUserId }
      });
    }  

    

  public getAllMyTrips(): Observable<any> {

    const token = this.getMyUserToken();
    console.log(token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
  
    const myUserId = this.getMyUserId()
    // Make an HTTP GET request to your backend API endpoint
    return this.http.get<any>(this.backendUrl + '/getAllMyTrips', {
      // headers,
      params:
      {myUserId}
    });
  }

  public getAllPublicTrips(): Observable<any> {

    // const token = this.getMyUserToken();
    // console.log('getAllPublicTrips token..',token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
    const myUserId = this.getMyUserId()
    console.log("UUUSSER ID", myUserId)

    // Make an HTTP GET request to your backend API endpoint
    return this.http.get<any>(this.backendUrl + '/getAllPublicTrips',
     {
      // headers,
      params:
      {myUserId}
    }
    );
  }

  public getAllTripMessages(tripId): Observable<any> {

    // const token = this.getMyUserToken();
    // console.log(token)
    
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${token}`
    // });
  
    // const myUserId = this.getMyUserId()
    // Make an HTTP GET request to your backend API endpoint
    return this.http.get<any>(this.backendUrl + '/getTripMessages', {
      // headers,
      params:
      {tripId}
    });
  }

  // public findPlaces(params: any): Observable<any> {
  //   return this.http.get<any>('https://api.geoapify.com/v2/places?categories=commercial.supermarket&filter=rect%3A10.716463143326969%2C48.755151258420966%2C10.835314015356737%2C48.680903341613316&limit=20&apiKey=c5b4556e99d9474288a82a6cd1bc9d90', {
  //     params: params
  //   });
  // }
  // url: 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Museum%20of%20Contemporary%20Art%20Australia&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=YOUR_API_KEY',


  public searchPlaces(query: string): Observable<any> {
    const apiKey = 'AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY';
    const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=Aap_uEA7vb0DDYVJWEaX3O-AtYp77AaswQKSGtDaimt3gt7QCNpdjp1BkdM6acJ96xTec3tsV_ZJNL_JP-lqsVxydG3nh739RE_hepOOL05tfJh2_ranjMadb3VoBYFvF0ma6S24qZ6QJUuV6sSRrhCskSBP5C1myCzsebztMfGvm7ij3gZT&key=AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY`
      ;

    // Set headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // Add any other headers you need
    });

    return this.http.get<any>(url, { headers });
  }


  //AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY Google API key
  public formatDate(date: string): string {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      year: 'numeric', // Add this line to include the year in the output
      month: 'short',
      day: 'numeric',
    };
  
    return new Date(date).toLocaleDateString('en-US', options);
  }
  


  public formatDateRange(dates: string[]): string {
    if (dates?.length === 1) {
      const formattedDate = this.formatDate(dates[0]);
      // const year = new Date(dates[0]).getFullYear();
      return `${formattedDate}`;
    } else if (dates?.length >= 2) {
      const firstDate = this.formatDate(dates[0]);
      const lastDate = this.formatDate(dates[dates.length - 1]);
      // const year = new Date(dates[0]).getFullYear();
      return `${firstDate} - ${lastDate}`;
    }
    return '';
  }


  public saveTripDetails(trip: any): Observable<any> {
    console.log(trip)
    const tripData = {
      trip: trip,
      myUserId: this.getMyUserId()
    }
    return this.http.post(this.backendUrl + '/saveTripDetails', tripData, {})
  }

  public removeUserFromTrip(tripID: string, contact: any): Observable<any> {
    console.log(contact)
    console.log(tripID)
    const removeUserFromTripData = {
      tripID: tripID,
      contact: contact,
      myId: this.getMyUserId()
    }
    return this.http.post(this.backendUrl + '/removeUserFromTrip', removeUserFromTripData, {})
  }

  public addUserToTrip(tripID: string, contact: any): Observable<any> {
    console.log(contact)
    console.log(tripID)
    const addUserToTrip = {
      tripID: tripID,
      contact: contact,
      myId: this.getMyUserId()
    }
    return this.http.post(this.backendUrl + '/addUserToTrip', addUserToTrip, {})
  }


  public async openActionSheetToLeaveTrip(contact: any, tripId: string) {
    console.log(contact)
    console.log(tripId)
    this.actionSheetController.create({
      header: 'Leave trip',
      buttons: [
        {
          text: 'Leave',
          icon: 'close-circle-outline',
          handler: () => {
            this.openRemoveUserModal(tripId, contact, true)
            // setTimeout(() => {
            //   this.router.navigate(['tabs/tab2'])
            // }, 2000);
            // const method = {
            //   number: this.myNumber,
            //   email: this.myEmail,
            // };
            // console.log(method);
            // this.apiService
            //   .acceptInvite(this.tripDetails, method)
            //   .pipe(takeUntil(this.destroy$))
            //   .subscribe((trip: any) => {
            //     console.log(trip);
            //     // this.isInviteAccepted = true;
            //   });
          },
        },
       
        {
          text: 'Cancel', // Rename to "Cancel" instead of "Accept"
          role: 'cancel', // Specify role as 'cancel' for the cancel button
          icon: 'close',
          handler: () => {
            // Handle the cancel action if needed
            // This function will be called when clicking outside the action sheet
          },
        },
      ],
    }).then((actionSheet) => {
      actionSheet.present();
    });
  }


  public async removeOrResend(contact: any, tripId: string, component: string) {
    console.log(contact)
    console.log(tripId)
    console.log(component)
    this.actionSheetController.create({
      header: 'Accept Invitation',
      buttons: [
        {
          text: 'Remove',
          icon: 'close-circle-outline',
          handler: () => {
            this.openRemoveUserModal(tripId, contact, true)
            // const method = {
            //   number: this.myNumber,
            //   email: this.myEmail,
            // };
            // console.log(method);
            // this.apiService
            //   .acceptInvite(this.tripDetails, method)
            //   .pipe(takeUntil(this.destroy$))
            //   .subscribe((trip: any) => {
            //     console.log(trip);
            //     // this.isInviteAccepted = true;
            //   });
          },
        },
        {
          text: 'Resend to number',
          icon: 'call-outline',
          handler: () => {
            // const method = {
            //   number: this.myNumber,
            //   email: this.myEmail,
            // };
            // console.log(method);
            // this.apiService
            //   .acceptInvite(this.tripDetails, method)
            //   .pipe(takeUntil(this.destroy$))
            //   .subscribe((trip: any) => {
            //     console.log(trip);
            //     // this.isInviteAccepted = true;
            //   });
          },
        },
        {
          text: 'Resend to email',
          icon: 'mail-outline',
          handler: () => {
            // const method = {
            //   number: this.myNumber,
            //   email: this.myEmail,
            // };
            // console.log(method);
            // this.apiService
            //   .acceptInvite(this.tripDetails, method)
            //   .pipe(takeUntil(this.destroy$))
            //   .subscribe((trip: any) => {
            //     console.log(trip);
            //     // this.isInviteAccepted = true;
            //   });
          },
        },
        {
          text: 'Cancel', // Rename to "Cancel" instead of "Accept"
          role: 'cancel', // Specify role as 'cancel' for the cancel button
          icon: 'close',
          handler: () => {
            // Handle the cancel action if needed
            // This function will be called when clicking outside the action sheet
          },
        },
      ],
    }).then((actionSheet) => {
      actionSheet.present();
    });
  }



  public async openActionSheet(contact: any, tripId: string, component: string) {
    console.log(contact);
    console.log(tripId)
    console.log(component)
    const buttons = [];
    const email = contact.email;
    console.log(email)
    // Check if neither 'number' nor 'email' property exists in the contact
    if (!contact.number && !contact.email) {
      contact?.phones.forEach((phone: any) => {
        buttons.push({
          text: phone.number,
          handler: () => {
            // Handle the selected phone number
            console.log('Selected phone number:', phone.number);
            this.openModal(tripId, contact, false, phone.number, false, false, component);
          }
        });
      });
    }

    if (contact.phones && !contact.email) {
      // contact?.phones.forEach((phone: any) => {
      buttons.push({
        text: 'Invite by Email Address',
        handler: () => {
          // Handle the email address input
          console.log('Email address entered');
          this.openModal(tripId, contact, true, '', true, false, component);
        }
      });
      // });
    }



    else {
      // // Check if the 'email' property exists in the contact
      // if (contact.email) {
      //   buttons.push({
      //     text: `Resend invite to (${contact.email})`,
      //     handler: () => {
      //       // Handle sending invite to the email address
      //       console.log('Selected email address:', contact.email);
      //       this.openModal(tripId, contact, true, contact.email, false, false, component);
      //     }
      //   });
      // }

      // // Check if the 'number' property exists in the contact
      // if (contact.number) {
      //   buttons.push({
      //     text: `Resend invite to ${contact.name} (${contact.number})`,
      //     handler: () => {
      //       // Handle sending invite to the phone number
      //       console.log('Selected phone number:', contact.number);
      //       this.openModal(tripId, contact, false, contact.number, false);
      //     }
      //   });
      // }



      // Add the "Remove" button for both cases
      buttons.push({
        text: `Remove ${contact.name.display ? contact.name.display : contact.name}`,
        handler: () => {
          // Handle remove action

          console.log('Remove action clicked');
          // Add your remove logic here
          this.openRemoveUserModal(tripId, contact, true)
        }
      });
    }

    // Add an input option for email address
    //  if(contact.number){
    //   buttons.push({
    //     text: 'Invite by Email Address',
    //     handler: () => {
    //       // Handle the email address input
    //       console.log('Email address entered');
    //       this.openModal(tripId, contact, true, '', false, false, component);
    //     }
    //   });
    //  } else {
    //   buttons.push({
    //     text: 'Invite by number',
    //     handler: () => {
    //       // Handle the email address input
    //       console.log('Email address entered');
    //       this.openModal(tripId, contact, false, contact.number, false, false, component);
    //     }
    //   });
    //  }

    const inviteMethods = contact.inviteMethods || []; // Make sure inviteMethods is an array

    // Create buttons for each invite method to resend the invite
    const inviteMethodButtons = inviteMethods.map((method) => ({
      text: `Resend invite to ${method}`,
      handler: () => {
        // Add your logic to resend the invite for the specific method here
        // You can use method to identify which invite method to resend
        console.log(`Resending invite to ${method}`);
      },
    }));

    const actionSheet = await this.actionSheetController.create({
      header:
        component === 'tripDetailsPage' || component === 'contactsPage'
          ? `Where should we send the invite to ${contact.name.display ? contact.name.display : contact.name
          }`
          :
          contact.name.display ? contact.name.display : contact.name
      ,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        ...inviteMethodButtons, // Include buttons for each invite method
        ...buttons, // Other buttons if needed
      ],
    });



    await actionSheet.present();
  }


  public async openModal(tripId: string, contact?: any, isEmailOptionSelected?: boolean, number?: string, showAddContactAlternative?: boolean, isUserBeingRemoved?: boolean, component?: string) {
    console.log('modal', number)
    const modal = await this.modalCtrl.create({
      component: EmailModalComponent,
      breakpoints: [0, 0.3, 0.5, 0.8],
      initialBreakpoint: 0.5,
      componentProps: {
        contact: contact, // Pass the contact data as a component property
        isEmailOptionSelected: isEmailOptionSelected,
        number: number,
        showAddContactAlternative: showAddContactAlternative,
        tripId: tripId,
        isUserBeingRemoved: isUserBeingRemoved,
        component: component
      },

    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm' && data) {
      console.log(data)
    //  this.myTripData.invitees?.push(data.response.invitees); 
      //  this.myTripData.invitees = data.response
       console.log(this.myTripData)
      this.presentToast('addUserToTrip')
    }
  }

  public async openRemoveUserModal(tripId: string, contact?: any, isUserBeingRemoved?: boolean) {
    try {
      const modal = await this.modalCtrl.create({
        component: EmailModalComponent,
        breakpoints: [0, 0.3, 0.5, 0.8],
        initialBreakpoint: 0.5,
        componentProps: {
          contact, // Shorthand for contact: contact
          isUserBeingRemoved,
          tripId,
        },
      });

      await modal.present();
      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm' && data) {
        console.log(data)
        // Assuming that data.response.invitees contains the updated invitees list
        this.myTripData.invitees = data.response.invitees;
        this.presentToast('removeUserFromTrip');
      }
    } catch (error) {
      console.error("Error opening remove user modal:", error);
      // Handle any potential errors here, such as modal creation or dismissal errors.
    }
  }

  // Function to update the specific trip in your tripData array
  public updateTripInArray(updatedTrip: any): any[] {
    const currentTrips = this.getTripData();
    const tripIndex = currentTrips.findIndex((trip) => trip.uuid === updatedTrip.uuid);

    if (tripIndex !== -1) {
      // Replace the old trip data with the updated data
      currentTrips[tripIndex] = updatedTrip;
    }
    return currentTrips;
  }

  public postTripImage(tripId: string, imageData: FormData): Observable<any> {
    // Create an object to send both tripId and imageData in the request body
    const requestPayload = {
      tripId: tripId,
      imageData: imageData,
    };

    // Make an HTTP POST request to your API
    return this.http.post(this.backendUrl + '/upload-trip-image', requestPayload, this.getHttpOptions());
  }


  public sendVerificationCode(phoneNumber): Observable<any> {
    const verificationNumber = {
      phoneNumber: phoneNumber,
    }
    return this.http.post(this.backendUrl + '/phoneVerification', verificationNumber, {})
  }
  public verifyOTP(otpCode, phoneNumber, email, name) {
    // Send OTP to server for verification
    return this.http.post(this.backendUrl + '/verifyOTP', { otpCode: otpCode, phoneNumber: phoneNumber, email: email, name: name })
  }

  public postCountryQuestion(countryName, questionData){
    console.log(countryName)
    console.log(questionData)
    const myUserId = this.getMyUserId()
    return this.http.post(this.backendUrl + '/postCountryQuestion', {countryName, questionData, myUserId})

  }

  public postCountryComment(countryName, commentData){
    const myUserId = this.getMyUserId()
    return this.http.post(this.backendUrl + '/postCountryComment', {countryName, commentData, myUserId})

  }

  public getCountryForums(countryName){
    const myUserId = this.getMyUserId()
    return this.http.get<any>(this.backendUrl + '/getCountryForums', {
      params:
      {countryName}
    });
  }

  public getCountryComment(countryName, topicId){
    const myUserId = this.getMyUserId()
    return this.http.get<any>(this.backendUrl + '/getCountryComment', {
      params:
      {countryName, topicId}
    });
  }

  public searchCountries(query: string) {
    console.log(query)
    // Replace `your-api-endpoint` with the actual API endpoint URL
    return this.http.get<any>(this.backendUrl +  `/api/search-countries?query=${query}`);
  }

  

  public getTravelArticles() {
    // Replace `your-api-endpoint` with the actual API endpoint URL
    return this.http.get<any>(this.backendUrl +  `/getTravelArticles`).pipe(take(1)).subscribe((res)=>{
      this.setReadingArticles(res)
    })
  }

  public acceptInvite(tripToAccept, method) {
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/acceptTrip', { tripToAccept: tripToAccept, method: method, myUserId: myUserId })

  }

  public declineInvitation(tripToDecline) {
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/declineInvitation', { tripToDecline: tripToDecline, myUserId: myUserId })

  }

  public userHasSeenNotification(tripId) {
    console.log(tripId)
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/hasSeenNotification', { myUserId: myUserId, tripId: tripId})
  }

  public async presentToast(type?: string, trip?: string) {
    let message: string;
  
    switch (type) {
      case 'removeUserFromTrip':
        message = 'Removed user from trip';
        break;
      case 'addUserToTrip':
        message = 'Invite sent! 🎉';
        break;
      case 'uploadPicture':
        message = 'Profile picture added!';
        break;  
      case 'deletePicture':
        message = 'Profile picture removed!';
        break; 
      case 'notifyUserBeenAddedToTrip':
        message = `You've been invited to ${trip}!`;
        break;   
        case 'noProfilePictureToDelete':
          message = 'Nothing to delete';
        break; 
        case 'acceptedInvitation':
          message = `You're in ${trip}!`;
        break; 
        case 'declinedInvitation':
          message = `You declined the invitation ${trip}!`;
        break;   
        case 'taggedUser':
          message = `You were mentioned in ${trip}!`;
        break;    
        case 'privacy':
          // let displayMessage = trip.isTripPublic ?
          message = `Trip is now ${trip ? 'public' : 'private'}!`;
        break;  
        case 'import':
          // let displayMessage = trip.isTripPublic ?
          message = `Success!`;
        break; 
        case 'itineraryLink':
          // let displayMessage = trip.isTripPublic ?
          message = `Success! Itinerary linked to ${trip}`;
        break; 
        case 'tripCreated':
          // let displayMessage = trip.isTripPublic ?
          message = `Trip ${trip} created successfully! 🎉`;
        break;  
        case 'selectionSaved':
          // let displayMessage = trip.isTripPublic ?
          message = `Selections saved!`;
        break;   
        case 'voteCleared':
          // let displayMessage = trip.isTripPublic ?
          message = `Your vote has been removed!`;
        break; 
        case 'voteAdded':
          // let displayMessage = trip.isTripPublic ?
          message = `Your vote has been added! 🎉`;
        break;             
      // Add more cases as needed for different types
  
      //selectionSaved
      default:
        message = 'Default message';
    }
  
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 1800,
      position: 'top',
      cssClass: 'custom-toast'
    });
  
    toast.present();
  }


  
  public getLatestDataForTripsIAmInvitedTo() {
    // const userObject = {
      const myUserId = this.getMyUserId();

      // phoneNumber: this.getMyUserPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/getTripsIamInvitedTo', {
      params:
      {myUserId}
    });
  }

  public getAllCategoriesToDisplay(tripId) {
    // const userObject = {
      const myUserId = this.getMyUserId();

      // phoneNumber: this.getMyUserPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/getAllCategoriesToDisplay', {
      params:
      {tripId}
    });
  }

  getRandomCountries(){
    return this.http.get<any>(this.backendUrl + '/countries-with-images', {});
  }

  // getSingleCountryImage(country){
  //   return this.http.get<any>(this.backendUrl + '/get-single-country-with-image', { params:
  //     {country}});
  // }
  

  // getRandomCountries() {
  //   return this.http.get('/assets/countries.txt').pipe(
  //     map(data => {
  //       // Assuming the data is an array of country objects
  //       const allCountries = Object.values(data).map(country => ({
  //         name: country.name.common,
  //         region: country.region
  //       }));

  //       // Shuffle array and slice the first 20 items
  //       const shuffledCountries = allCountries.sort(() => 0.5 - Math.random());
  //       return shuffledCountries.slice(0, 20);
  //     })
  //   );
  // }

  public getCategoryActivities(tripId, categoryId) {
    // const userObject = {
      const myUserId = this.getMyUserId();
    console.log('getting actitivites for ', categoryId)
      // phoneNumber: this.getMyUs  erPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/getCategoryActivities', {
      params:
      {myUserId, categoryId,tripId}
    });
  }


  public getHotels() {
    // const userObject = {
      const myUserId = this.getMyUserId();
    // console.log('getting results for ', tripId)
      // phoneNumber: this.getMyUs  erPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/getHotels', {
      // params:
      // {myUserId, tripId}
    });
  }

  public getFlights() {
    console.log("getting flights")
    // const userObject = {
      const myUserId = this.getMyUserId();
    // console.log('getting results for ', tripId)
      // phoneNumber: this.getMyUs  erPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/flightSearch', {
      // params:
      // {myUserId, tripId}
    });
  }


  public getActivitiesResults(tripId) {
    // const userObject = {
      const myUserId = this.getMyUserId();
    console.log('getting results for ', tripId)
      // phoneNumber: this.getMyUs  erPhoneNumber()
    //}
    return this.http.get<any>(this.backendUrl + '/getActivitiesResults', {
      params:
      {myUserId, tripId}
    });
  }

  public initializeCounter(tripId){
    console.log(tripId)
    return this.http.post(this.backendUrl + `/startTripPlanningTimer`, {tripId: tripId})
  }

  public saveSelectedAttractions(tripId, selectedAttractions, dayId){
    console.log(tripId)
    return this.http.post(this.backendUrl + `/saveSelectedAttractions`, {tripId: tripId, selectedAttractions: selectedAttractions, dayId: dayId })
  }

  public cancelCounter(tripId){
    console.log(tripId)
    return this.http.post(this.backendUrl + `/cancelCounter`, {tripId: tripId})
  }
  public getTripCountdown(tripId: string) {
    return this.http.get(this.backendUrl + `/getTripPlanningTimer`, { params:
      {tripId}})
  }

  public saveUsersPreferredTripDates(activityBelongsToCategoryId, tripId, dateRange?) {
  
    console.log(activityBelongsToCategoryId)
    console.log(tripId)
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/saveUsersPreferredTripDates', { myUserId: myUserId, tripId: tripId, activityBelongsToCategoryId: activityBelongsToCategoryId, dateRange })
  }
  
  public updateActivityWithUserSelection(optionId, tripId) {
  
    console.log(optionId)
    console.log(tripId)
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/updateActivityWithUserSelection', { myUserId: myUserId, tripId: tripId, optionId: optionId })
  }

  public removeUserFromTripActivity(optionId, tripId) {
  
    console.log(optionId)
    console.log(tripId)
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/removeUserFromTripActivity', { myUserId: myUserId, tripId: tripId, optionId: optionId })
  }

  
  public getTripInvitees(tripUUID: string): Observable<any> {

    return this.http.get<any>(this.backendUrl + '/getTripInvitees', {
      params:
      {tripUUID}
    });
  }

  

  private getHttpOptions(): any {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return { headers: headers };
  }

  public uploadProfilePic(formData: FormData) {
    const userId = this.getMyUserId();
    console.log(userId)
    formData.append('userId', userId); // Ensure the field name matches the middleware
  
    return this.http.post(this.backendUrl + '/upload-profile-picture', formData);
  }

  //getAIResponse

  public getAIResponse(tripId) {
    // const userId = this.getMyUserId();
    // console.log(userId)
    // formData.append('userId', userId); // Ensure the field name matches the middleware
    console.log(tripId)
    return this.http.post(this.backendUrl + '/getAIResponse', {tripId: tripId});
  }

  // public notifyTaggedUser(taggedUserId, senderId) {
  //   const taggedMessageObject = {
  //     taggedUserId,
  //     senderId
  //   }
  //   return this.http.post(this.backendUrl + '/notifyTaggedUser', taggedMessageObject);
  // }

  public uploadChatImage(formData: FormData, tripId: string) {
    const myUserId = this.getMyUserId();
    formData.append('tripId', tripId); // Ensure the field name matches the middleware
    formData.append('myUserId', myUserId)
    return this.http.post(this.backendUrl + '/upload-chat-picture', formData);
  }

  public deleteProfilePic() {
    const userId = this.getMyUserId();
    console.log(userId);
  
    return this.http.post(this.backendUrl + '/delete-profile-picture', { userId }).pipe(
      catchError((error) => {
        if (error.error && error.error.error === 'Nothing to delete') {
          this.presentToast('noProfilePictureToDelete');
        } else {
          // Handle other types of errors here
          console.error('Error deleting profile picture:', error);
        }
        throw error; // Re-throw the error to propagate it further if necessary
      })
    );
  }

  isTripActive(countdownEnd: string): boolean {
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const endTime = new Date(countdownEnd).getTime(); // Convert countdownEnd to milliseconds
  
    return currentTime < endTime; // Return true if the current time is before countdownEnd
  }
  

  public getProfilePic(myUserId) {
    const userId = {
      userId: myUserId
  
    }
    console.log(userId)
    return this.http.get(this.backendUrl + '/getImage',  {
      params:userId

    });
  }
  public async openTripDetails(trip) {
    console.log("clicking...")
    const tripData: any = {
      tripDetails: trip,
      tripId: trip.uuid,
      // myUserId: this.allTripsOwnerByMe.id,
      // selectedTab: this.selectedTab,
      // myEmail: this.allTripsOwnerByMe.email,
      // myNumber: this.allTripsOwnerByMe.phoneNumber,
      // myUserData: this.allTripsOwnerByMe,
    };
    console.log(tripData)
    this.router.navigate(['trip-page',tripData.tripId], { replaceUrl: true })
    this.setTabs2TripData(tripData);
  }

  public getCountryDetails(country: string): Observable<any> {

    return this.http.get<any>(this.backendUrl + '/getCountryDetails', {
      params:
      {country}
    });
  }
  

  public getRandomAttractions(cachedCities?: string[], limit?, offset?): Observable<any> {
    console.log('Searching for', cachedCities);
    // Join the array of cities into a comma-separated string
    console.log("searching rando cities..")
    const citiesParam = cachedCities?.join(',');
    
    return this.http.get<any>(this.backendUrl + '/api/attractions', {
        params: { cachedCity: citiesParam, limit, offset  }
    });
}



  public getCityDetails(city: string): Observable<any> {
    console.log(city)
   const myUserId = this.getMyUserId();
    return this.http.get<any>(this.backendUrl + '/getCityDetails', {
      params:
      {city,myUserId}
    });
  }

  public getSingleCountryImage(country: string): Observable<any> {

    return this.http.get<any>(this.backendUrl + '/get-single-country-with-image', {
      params:
      {country}
    });
  
  }

  public saveUserInterests(userInterests){
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/save-interests', {userInterests, myUserId}, {})

  }

  public createLocationOption(location, tripdId){
    const myUserId = this.getMyUserId();
    return this.http.post(this.backendUrl + '/createLocationOption', {location, tripdId, myUserId}, {})

  }

  public getLocationOption( tripId){
    // const myUserId = this.getMyUserId();
   
    return this.http.get<any>(this.backendUrl + '/getLocationOption', {
      params:
      {tripId}
    });

  }

  

  public async openCountryDetails(country) {
    console.log("clicking...", country);
    // Navigate to the country page
    this.router.navigate(['/country-page', country.uuid], { state: country });
    
    // Update the country's tripUrl asynchronously
    this.getSingleCountryImage(country.tripLocation).pipe(take(1)).subscribe((res) => {
      console.log(res);
      country.tripUrl = res.tripUrl;
    });
  }


  public getSearchHistory(): any[] {
    return JSON.parse(localStorage.getItem(this.SEARCH_HISTORY_KEY)) || [];
  }
  
  // public getTwoRandomCountries(searchHistory: any[]): any[] {
  //   // Shuffle the search history to get a random order
  //   const shuffledHistory = searchHistory.sort(() => Math.random() - 0.5);
    
  //   // Extract at most two countries from the shuffled history
  //   return shuffledHistory.slice(0, 1);
  // }
  

  public async openCityDetails(city) {
    console.log("clicking...", city);
    const split = city.split(", ");
    const cachedCity = split[0];
    console.log(cachedCity);

    // Retrieve the existing search history from localStorage
    let searchHistory = JSON.parse(localStorage.getItem(this.SEARCH_HISTORY_KEY)) || [];

    // Ensure the search history does not exceed 2 items:
    // If there are already 2 items, remove the oldest one
    if (searchHistory.length >= 2) {
        searchHistory.shift(); // Removes the first item from the array
    }

    // Add the new city to the search history
    searchHistory.push(cachedCity);

    // Save the updated search history to localStorage
    localStorage.setItem(this.SEARCH_HISTORY_KEY, JSON.stringify(searchHistory));

    // Call getAttractionsForCountry with the cached city name
    this.getRandomAttractions(searchHistory).subscribe((res) => {
        console.log("Attractions for " + searchHistory, res);
        // Suppose you need to update the cached experiences similarly,
        // you could apply a similar strategy to manage how many items you want to keep
    });

    this.router.navigate(['/city-details'], { state: city });
    // Assuming you have a method to set data for navigation or other purposes
}




  public isUserVoted(activity: any): boolean {
    return activity.usersVotedYes.some(user => user.userId === this.returnMyUserId);
  }

  toggleFooterOverlay(show: boolean) {
    this.showFooterOverlay = show;
  }

  public dismissSearchBarModal(){
    if(this.modal){
      this.modal.dismiss()

    }
  }

  async openSearchBar(isTripBuilder?: boolean, isTripPlannerPage?: boolean, tripId?: string ) {
    console.log('isTripBuilder', isTripBuilder)
    this.tabsService.updateTabsVisibility(false);
    if(isTripPlannerPage){
      this.isTripPlannerPage = isTripPlannerPage;
    }
    if(!isTripBuilder){
      const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;
      
        const backdropAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
      
        const wrapperAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('.modal-wrapper')!)
          .fromTo('transform', 'translateY(-100%)', 'translateY(0)');
      
        return this.animationCtrl.create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(150)
          .addAnimation([backdropAnimation, wrapperAnimation]);
      }
      
      const leaveAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;
      
        const backdropAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
      
        const wrapperAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('.modal-wrapper')!)
          .fromTo('transform', 'translateY(0)', 'translateY(-100%)');
      
        // Reduce the duration here for a faster animation
        return this.animationCtrl.create()
          .addElement(baseEl)
          .easing('ease-in')
          .duration(10) // Adjusted for faster leave animation
          .addAnimation([backdropAnimation, wrapperAnimation]);
      };
      
       this.modal = await this.modalCtrl.create({
        component: SearchBarPage,
        enterAnimation,
        leaveAnimation,
        backdropBreakpoint: 0.1,
        componentProps: { // 'componentProps' is used to pass data to the modal component
          isTripBuilder: false, // Passing the isTripBuilder variable to the modal,
          isTripPlannerPage: false,
          tripId
        }
  
      });
    } else {
      console.log('initial BP is', this.initialBreakpoint )
      // this.initialBreakpoint = 0.8
      this.modal = await this.modalCtrl.create({
        component: SearchBarPage,
        breakpoints: [0.1, 0.3, 0.5, 0.8],
        initialBreakpoint: 0.5,
        backdropBreakpoint: 0.5,
        // backdropDismiss: false,
        componentProps: { // 'componentProps' is used to pass data to the modal component
          isTripBuilder: isTripBuilder, // Passing the isTripBuilder variable to the modal,
          isTripPlannerPage: isTripPlannerPage,
          tripId
          
          
          // modalState: modalState
        },
        // enterAnimation
  
      });
    }
  
    this.modal.present();

    this.modal.onDidDismiss().then(({ data, role }) => {
      console.log('closing...');

      this.tabsService.updateTabsVisibility(true);
      this.showFabIcon = true;
      if (data) {
        console.log('Received from modal:', data);
        // this.presentToast(data);
      }
    });
  }

  searchPlacesFourSquare(location: string): Observable<any> {
    console.log(location)
    return this.http.get<any>(`${this.backendUrl}/search?location=${location}`);
  }

  getPlaceDetails(placeId: string): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/details`, {
      params:
      {placeId}
    });
  }

  calculateStars(rating: number) {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return {
      fullStars: Array(fullStars).fill(0), // Creates an array with `fullStars` elements.
      halfStar: Array(halfStar).fill(0), // Creates an array with `halfStar` element, either 0 or 1.
      emptyStars: Array(emptyStars).fill(0), // Creates an array with `emptyStars` elements.
    };
  }

  extractLocationFromSingleElement(destinationArray) {
    if (destinationArray.length === 0) {
      return 'Array is empty';
    }

    // Access the first (and only) element in the array
    const fullLocation = destinationArray[0];

    // Split the string at the comma
    const parts = fullLocation.split(',');

    // Return the first part, trimmed of whitespace
    return parts[0].trim();
  }

  convertMinutesToHoursAndMinutes(minutes: number): string {
    // Log the input for debugging
  
    // Guard clause for undefined, null, or non-number input
    if (minutes == null || isNaN(minutes)) {
      return '';
    }
  
    // Calculate hours and remaining minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    // Construct the result string
    let result = '';
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (remainingMinutes > 0) {
      result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }
  
    return result.trim(); // Trim any leading/trailing spaces
  }


  findBestQualityImage(images: any[]): string | null {
    let bestQualityImageUrl: string | null = null;
    let bestQuality = 0;
  
    // Iterate through each image in the images array
    images.forEach((image) => {
      // Iterate through each variant of the current image
      image.variants.forEach((variant) => {
        // Calculate the quality of the current variant
        const quality = variant.height * variant.width;
  
        // Update bestQualityImageUrl if the quality of the current variant is better
        if (quality > bestQuality) {
          bestQuality = quality;
          bestQualityImageUrl = variant.url;
        }
      });
    });
  
    return bestQualityImageUrl;
  }

  // goBackToPreviousPage(){
  //   this.router.navigate(["/tabs/tab2"])
  // }
  

}








//https://api.unsplash.com/search/photos/?page=1&query=Jamaica&client_id=hSPby7R2IkATzFgutwFCTsNyG-j1utfcQN8FJ7pPjjU

//gtw6hXm3yi7Xmrls7F9Y5Zd0K6wejThB secret