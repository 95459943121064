// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAfsQGjgDqU5D2QuWlZwPKnPZ9y5OYesyY",
    authDomain: "mytravelbug-ff9a6.firebaseapp.com",
    projectId: "mytravelbug-ff9a6",
    storageBucket: "mytravelbug-ff9a6.appspot.com",
    messagingSenderId: "499239628528",
    appId: "1:499239628528:web:76a03c04622a82d286dc35",
    measurementId: "G-FECGPXJQ5W"
  },
  lottieSplashScreen: {
    location: 'public/assets/animations/splashlong.json',
    fullScreen: true,
    hideAfterAnimationEnd: true,
  },
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
