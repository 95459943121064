import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { GoogleMap, LatLngBounds } from '@capacitor/google-maps';
import * as mapboxgl from 'mapbox-gl';
import { ApiService } from '../api.service';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { GoogleMapContentComponent } from '../google-map-content/google-map-content.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild('map') mapElement: ElementRef;
  // map!: mapboxgl.Map;
  public isMapLoaded = true;
  @Input() public isFromActivitiesPage: boolean
  @Input() topStyle: string;
  @Input() heightStyle: string;
  @Input() locationInput: any;
  @Input() coordinates: any;
  @Input() isContactsPage: any;
  @Input() tripRecommendations: any;
  private destroy$ = new Subject<void>();
  selectedLocation: any | null = null; // Replace with your actual location data type

  // private bounds: mapboxgl.LngLatBounds;
  // private lastLocationsLength = 0;
  // private markerRefs: { [key: string]: mapboxgl.Marker } = {};
  private routerSubscription: Subscription;
  private map: mapboxgl.Map;
  private bounds = new mapboxgl.LngLatBounds();
  private markerRefs: { [key: string]: mapboxgl.Marker } = {};
  // private destroy$ = new Subject<void>();
  public locations: any[]
  isVisible = false;
  constructor(private apiService: ApiService, private cdr: ChangeDetectorRef, private router: Router, private mapContent: GoogleMapContentComponent) {

  }

  closeModal() {
    this.apiService.hideModal();
  }

  checkIfSearchbarInteractedWith() {
    let searchBarValue = sessionStorage.getItem('searchBar');
    console.log(searchBarValue)
    // Check if 'searchBar' is set to true
    if (searchBarValue === 'true') {
      // 'searchBar' is set to true
      console.log('searchBar has been interacted with');
      this.apiService.showFabIcon = true;
      return true
    } else {
      // 'searchBar' is not set to true
      console.log('searchBar has not been interacted with');
      return false;
    }
  }

  async ngOnInit() {
    console.log(this.isContactsPage)
    console.log(this.isFromActivitiesPage)
    this.setupLocationUpdates()
    // this.apiService.locationsSubject.subscribe((res)=>{
    //   console.log(res)
    //   this.updateMapMarkers(res); // Update markers with new locations
    //   this.saveLocations(res); // Persist new locations
    // })

    if (sessionStorage.getItem('mapComponent')) {
      console.log('mapComponent has been visited in this session.');
      if (this.checkIfSearchbarInteractedWith()) {
        this.apiService.showFabIcon = true;

      } else {
        this.apiService.showFabIcon = false;

      }


    } else {
      // Set the 'mapComponent' item in sessionStorage with a value to indicate it has been visited.
      sessionStorage.setItem('mapComponent', 'visited');
      console.log('mapComponent has not been visited in this session.');
    }


  }

  private saveLocations(locations: any[]): void {
    localStorage.setItem('selectedLocations', JSON.stringify(locations));
  }

  private loadLocations(): any[] {
    const locationsJSON = localStorage.getItem('selectedLocations');
    return locationsJSON ? JSON.parse(locationsJSON) : [];
  }


  private handleLocationUpdates(): void {
    this.apiService.locations$.pipe(takeUntil(this.destroy$)).subscribe(newLocations => {
      this.saveLocations(newLocations); // Save every time the locations update
      this.updateMapMarkers(newLocations);
      sessionStorage.setItem('multiLocationArray', JSON.stringify(newLocations));
    });
  }

  private updateMapMarkers(locations): void {
    console.log(locations)
    const newKeys = new Set(locations.map(loc => `${loc.lat},${loc.lng}`));
    console.log(newKeys)
    // Remove markers not in the new location list
    Object.keys(this.markerRefs).forEach(key => {
      // console.log(key)
      if (!newKeys.has(key)) {
        this.markerRefs[key].remove();
        delete this.markerRefs[key];
      }
    });

    // Add new markers
    console.log(locations)
    locations.forEach(location => {

      const key = `${location.lat},${location.lng}`;
      if (!this.markerRefs[key]) {
        const el = document.createElement('div');
        el.className = 'marker';
        const popup = new mapboxgl.Popup({ offset: 25 }) // Offset can be adjusted
          .setHTML(`<h3>${location.name}</h3><p>${location.description || ''}</p>`); // Replace with your content

        const marker = new mapboxgl.Marker({ color: 'black' })
          .setLngLat([location.lng, location.lat])
          .addTo(this.map)
          .setPopup(popup)
        this.markerRefs[key] = marker;
        console.log(this.markerRefs[key])
        // Add click event to center the map on the marker
        this.cdr.detectChanges()
        marker.getElement().addEventListener('click', () => {
          // Clone the location object to ensure change detection triggers
          
          this.selectedLocation = { ...location };
          console.log(this.selectedLocation)
          this.apiService.hideModal();
        
          // this.map.flyTo({
          //   center: [location.lng, location.lat],
          //   essential: true
          // });
        });
        
      }

    });

    this.updateBounds(locations);
    this.cdr.detectChanges();
  }

  cleanupMarkers(): void {
      Object.keys(this.markerRefs).forEach(key => {
        console.log('Cleaning up marker with key:', key);
        this.markerRefs[key].remove();
        delete this.markerRefs[key];
      });

  }
  

  private getNewMarkers(currentLocations: any[], newLocations: any[]): any[] {
    // Convert current locations to a string set for efficient comparison
    const currentSet = new Set(currentLocations.map(loc => `${loc.lat},${loc.lng}`));

    // Filter newLocations to find those not in currentSet
    const newMarkers = newLocations.filter(loc => !currentSet.has(`${loc.lat},${loc.lng}`));

    return newMarkers;
  }

  private updateBounds(locations): void {
    console.log(locations)
    this.bounds = new mapboxgl.LngLatBounds();
    locations.forEach(location => {
      this.bounds.extend([location.lng, location.lat]);
    });
    console.log(this.bounds)
    this.fitMapToBounds();
  }


  private fitMapToBounds(): void {
    if (!this.bounds.isEmpty()) {
      // Determine the number of locations
      const locationCount = Object.keys(this.markerRefs).length;
      console.log(locationCount)
      if (locationCount === 1) {
        // For a single location, use flyTo for an animated transition
        const singleLocation = Object.values(this.markerRefs)[0].getLngLat();
        this.map?.flyTo({
          center: singleLocation,
          zoom: 10, // Adjust this zoom level as necessary
          duration: 2000 // Animation duration in milliseconds
        });
      } else if (locationCount > 1) {
        // Use fitBounds for multiple locations
        this.map?.fitBounds(this.bounds, {
          padding: { top: 50, bottom: 50, left: 60, right: 60 },
          maxZoom: 12, // Or dynamically set based on your preference
          duration: 1000
        });
      }
    } else if (Object.keys(this.markerRefs).length === 0) {
      // If there are no locations, zoom out to show the entire Earth
      this.map?.flyTo({
        center: [0, 0], // Center of the Earth in longitude and latitude
        zoom: 1, // Zoom level to show the Earth; adjust as needed
        duration: 2000 // Animation duration in milliseconds
      });
    }
  }





  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeMapSettings();

      // const storedLocations = this.loadLocations();
      // if (storedLocations.length > 0) {
      //   console.log("running stored locations")
      //   // If there are stored locations, use them to add markers or set the map view
      //   this.updateMapMarkers(storedLocations); // Make sure this method is adapted to handle stored locations
      //   this.handleLocationUpdates();

      // } else {
      //   console.log("running from coordinated locations")

      //   this.apiService.coordinates$.pipe(take(1)).subscribe((data) => {
      //     console.log(data)
      //     if (data) {
      //       console.log('got data..loading map...')
      //       this.coordinates = data;
      //       this.updateMapMarkers(this.coordinates)
      //     }
      //   })
      // }
    }, 200);
  }



  private initializeMapSettings(): void {
    
    (mapboxgl as typeof mapboxgl).accessToken = 'pk.eyJ1IjoicGhpbGxpcGRhY29zdGEiLCJhIjoiY2xzeXlxMmZ3MDdjbTJsbzFubXc0YWhmMiJ9.1ndT1CJzDBbspFm6o3x6PQ'

    if (!document.getElementById('map')) {
      console.error("Map container element is not in the DOM.");
      return;
    }

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [12.550343, 55.665957],
      zoom: 1
    });

    this.map.on('load', (e) => {
      // Load persisted locations only after the map is fully loaded
            // Listen for new locations updates after the map is fully loaded
            this.map.resize();
            this.isMapLoaded = true;
            this.apiService.isMapLoaded = true;
            console.log(this.isMapLoaded)
      const storedLocations = this.loadLocations();
      if (storedLocations.length > 0) {
        console.log("running stored locations")
        // If there are stored locations, use them to add markers or set the map view
        this.updateMapMarkers(storedLocations); // Make sure this method is adapted to handle stored locations
        this.handleLocationUpdates();

      } else {
        console.log("running from coordinated locations")

        this.apiService.coordinates$.pipe(take(1)).subscribe((data) => {
          console.log(data)
          if (data) {
            console.log('got data..loading map...')
            this.coordinates = data;
            this.updateMapMarkers(this.coordinates)
          }
        })
      }

    });
  }

  private setupLocationUpdates(): void {
    this.apiService.locations$.pipe(takeUntil(this.destroy$)).subscribe(newLocations => {
      console.log(newLocations)
      if (newLocations) {
        this.updateMapMarkers(newLocations); // Update markers with new locations
        this.saveLocations(newLocations); // Persist new locations
      }

    });
  }

  private addMarkers(locations): void {
    locations.forEach((location) => {
      if (location && location.lat && location.lng) {
        const lngLatArray: [number, number] = [location.lng, location.lat];
        const marker = new mapboxgl.Marker({ color: 'black' })
          .setLngLat(lngLatArray)
          .addTo(this.map);
        this.bounds.extend(lngLatArray);
      }
    });

    if (!this.bounds.isEmpty()) {
      this.map.fitBounds(this.bounds, {
        maxZoom: 6,
        padding: { top: 50, bottom: 50, left: 60, right: 60 }
      });
    }
  }

  closeCard(event: MouseEvent) {
    this.selectedLocation = null;
    event.stopPropagation(); // Stop click from propagating to other elements
    this.apiService.showModal();
  }
  
  destroyMap(): void {
    // Clean up resources associated with the map
    // Unsubscribe from any subscriptions, remove event listeners, etc.
    // Dispose of the map instance
      this.map.remove(); // Assuming your map library provides a method to remove the map instance
      this.map = null; // Reset the map variable
  }


  ngOnDestroy() {
    console.log('leaving map')
    this.apiService.showFabIcon = false;
    this.apiService.dismissSearchBarModal()
  if(!this.isContactsPage){
    this.cleanupMarkers()
  
    this.apiService.setLocations([])
  }
  this.destroyMap()

  }

}
