// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.sub-notification {
  padding: 20px;
  font-size: 11px;
  color: grey;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVtYWlsLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwiLi4vLi4vLi4vLi4vYW5ndWxhciUyMDQvc3JjL2FwcC9lbWFpbC1tb2RhbC9lbWFpbC1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLG1CQUFBO0VBQ0EsdUJBQUE7RUFDQSxZQUFBO0FDQ0o7O0FERUU7RUFDRSxhQUFBO0VBQ0EsZUFBQTtFQUNBLFdBQUE7QUNDSiIsImZpbGUiOiJlbWFpbC1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250ZW50LXdyYXBwZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGhlaWdodDogNTB2aDtcbiAgfVxuXG4gIC5zdWItbm90aWZpY2F0aW9ue1xuICAgIHBhZGRpbmc6IDIwcHg7XG4gICAgZm9udC1zaXplOiAxMXB4O1xuICAgIGNvbG9yOiBncmV5O1xuICB9IiwiLmNvbnRlbnQtd3JhcHBlciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBoZWlnaHQ6IDUwdmg7XG59XG5cbi5zdWItbm90aWZpY2F0aW9uIHtcbiAgcGFkZGluZzogMjBweDtcbiAgZm9udC1zaXplOiAxMXB4O1xuICBjb2xvcjogZ3JleTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/email-modal/email-modal.component.scss","webpack://./../angular%204/src/app/email-modal/email-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;ACCJ;;ADEE;EACE,aAAA;EACA,eAAA;EACA,WAAA;ACCJ;ADCA,ojCAAojC","sourcesContent":[".content-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 50vh;\n  }\n\n  .sub-notification{\n    padding: 20px;\n    font-size: 11px;\n    color: grey;\n  }",".content-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 50vh;\n}\n\n.sub-notification {\n  padding: 20px;\n  font-size: 11px;\n  color: grey;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
