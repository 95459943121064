import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsServiceService {

  private showTabsSubject = new BehaviorSubject<boolean>(true);
  
  showTabs$ = this.showTabsSubject.asObservable();
  constructor() { }

  updateTabsVisibility(showTabs: boolean) {
    console.log("TABS VALUE IS", showTabs)
    this.showTabsSubject.next(showTabs);
  }
}
