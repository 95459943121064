import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActionSheetController, AnimationBuilder, AnimationController, IonNav, ModalController, NavController, ToastController, Animation } from '@ionic/angular';
import { ApiService } from '../api.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { Subject, Subscription, catchError, filter, map, of, take, takeUntil } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { PushNotifications } from '@capacitor/push-notifications';

// import { Plugins, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { SocketService } from '../socket.service';
import { GoogleService } from '../google.service';
import { RecommendationsModalComponent } from '../recommendations-modal/recommendations-modal.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryPage } from '../history/history.page';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NotificationData } from 'interfaces/notification-data';
import { QuestionnaireComponent } from '../questionnaire/questionnaire.component';
import { TabsServiceService } from '../tabs-service.service';
import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Storage } from '@ionic/storage-angular'
import { Geolocation } from '@capacitor/geolocation';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-trip-page',
  templateUrl: './trip-page.page.html',
  styleUrls: ['./trip-page.page.scss'],
  animations: [
    trigger('placeholderAnimation', [
      state('true', style({ opacity: 1, transform: 'translateY(0%)' })),
      state('false', style({ opacity: 0, transform: 'translateY(-100%)' })),
      transition('false => true', animate('300ms ease-out')),
      transition('true => false', animate('300ms ease-in'))
    ]),
    trigger('listAnimation', [
      transition('* <=> *', [ // '* <=> *' denotes any state change
        query(':enter', [ // ':enter' is a shorthand for void => *
          style({ opacity: 0, transform: 'translateY(-15px)' }), // Initial state
          stagger('50ms', animate('350ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' }))) // Final state
        ], { optional: true }),
        query(':leave', [ // ':leave' is a shorthand for * => void
          animate('350ms ease-in', style({ opacity: 0, transform: 'translateY(15px)' }))
        ], { optional: true })
      ])
    ]),
    trigger('fadeInOut', [
      // Define the "void" => "visible" transition
      transition(':enter', [ // :enter is alias for void => *
        style({ opacity: 0 }), // Start with an opacity of 0
        animate('0.5s ease-in', style({ opacity: 1 })) // Animate to opacity 1
      ]),
      // Define the "visible" => "void" transition
      transition(':leave', [ // :leave is alias for * => void
        animate('0.3ms ease-out', style({ opacity: 0 })) // Animate to opacity 0
      ])
    ]),
    trigger('slideUpAnimation', [
      state('void', style({
        transform: 'translateY(100%)',
        opacity: 0
      })),
      state('*', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition(':enter', [
        animate('0.05ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.05ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])


  ]
})
export class TripPagePage implements OnInit, OnDestroy {
  @ViewChild('userIconsContainer', { read: ElementRef }) userIconsContainer: ElementRef;
  // rootPage: any; 
  // component: any
  selectedColumnIndex: number = 0
  coordinates = []
  showItinerary = false;
  @Input() tripDetails: any;
  public myUserId;
  public myEmail?: string;
  public myNumber?: string
  @Input() parentComponent: string
  @Input() selectedTab: string;
  @Input() tripOwner: any;
  public inviteAcceptedCounter: number
  public highlightedDates = [];
  public selectedDates: string[] = [];
  public segmentValue: string = '';
  public tripVisibilityText: string;
  public isSaveDisabled = true;
  public isTripLocationTBD = false;
  public initialIsTripPublic: string = '';
  public showYouTag = false;
  public numberOfInviteesIncludingYou: number;
  public count = 0;
  public notificationNumber = 0
  public isInviteAccepted = false;
  public isInviteDeclined = false;
  public isOwner = false;
  public recommendation: any;
  public tripRecommendations = [];
  public tripRecommendationsType = ['tourist_attraction', 'restaurant', 'lodging'];
  public isLoaded = false;
  public isShowingRecommendations = false;
  public iframeUrl: any;
  private tripUpdateSubscription: Subscription;
  private types = ['point_of_interest']
  private location: string;
  private tripUUID: string;
  private destroy$: Subject<void> = new Subject<void>();
  private selectedFile: File | null;
  public users: any[] = [];
  public userColors: any;
  public tripNotificationNumber = 0;
  public tripInvitees = [];
  public ownerArray = [];
  public tripDates: string;
  public toggleSegmentPrivate = false;
  public toggleSegmentPublic = false;
  ecoFriendlyStatus: boolean = false;
  public userIsViewingPublicTripFromExplorerPageAndNotJoinedTrip = false;

  public userIsViewingPublicTripFromExplorerPageAndHasJoinedTrip = false;
  public hasAcceptedTripInvite = false;
  public userHasAcceptedInviteFromPrivateTrip = false;
  public shouldSeeOverlayForPrivateTrip = false;
  public hasBeenInvitedButNotAcceptedYet = false;
  public hasBeenInvited = false;
  public viatorTagAndProductData = []
  public tripStartAndEnd = ""
  public locationCoordinated: any;
  public viatorDestId: number;
  selectedLocation: string;

  slideOptsRecommendations = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1.2,
    spaceBetween: 10,
    centeredSlides: true,
  };

  constructor(private modalController: ModalController,
    private apiService: ApiService,
    private socketService: SocketService,
    private geocodingService: GoogleService,
    public actionSheetController: ActionSheetController,
    private sanitizer: DomSanitizer,
    private router: Router,
    private animationCtrl: AnimationController,
    private navCtrl: NavController,
    private cdr: ChangeDetectorRef,
    private storage: Storage,
    private tabsService: TabsServiceService,
    private toastCtrl: ToastController, private modalCtrl: ModalController, private route: ActivatedRoute) {
    this.initStorage();
  }

  ngOnInit() {
    console.log("trip page is running")
    // this.tabsService.updateTabsVisibility(true);

    this.apiService.myUserId.pipe(take(1)).subscribe((userId) => {
      this.myUserId = userId;
      this.socketService.joinNotificationZone(this.myUserId)

      this.route.params.pipe(take(1)).subscribe(async (params) => {
        console.log(params)
        this.tripUUID = params.tripUuid;

        this.getTripDetails(this.tripUUID);

        this.socketService.receiveTaggedNotifications().pipe(takeUntil(this.destroy$)).subscribe((res) => {
          if (res.tripId === params.tripUuid) {
            const notificationData = {
              updatedValue: res.count,
              tripId: res.tripId
            };
            // this.apiService.updateNotificationNumber(notificationData)

            // Update notification data or perform other actions
            console.log('Notification data updated:', notificationData);
          } else {
            console.log('Received notification for a different trip. Ignoring.');
          }
          this.apiService.presentToast('taggedUser', res.tripName)
        })
      })
    })
    // this.getCurrentLocation()

    // this.apiService.getViatorTags().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
    //   console.log(res);
    //   this.viatorTags = res;

    // });

    this.apiService.getMyNotifications().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      console.log(res)
      const trip = res.find((trip) => this.tripUUID === trip.tripUUID)
      console.log(trip)
      if (trip) {
        console.log(trip.tripUUID)

        const notificationData = {
          updatedValue: trip.count,
          tripId: trip.tripUUID
        };
        console.log(notificationData)
        this.apiService.updateNotificationNumber(notificationData)
        this.notificationNumber = trip.count;
        // Update notification data or perform other actions
        console.log('Notification data updated:', notificationData);
      } else {
        this.notificationNumber = 0
      }
    })


    this.apiService.notificationNumber$.pipe(takeUntil(this.destroy$)).subscribe((notificationCount: NotificationData) => {
      console.log(notificationCount)
      if(notificationCount){
        this.notificationNumber = notificationCount?.updatedValue
        const id = notificationCount?.tripId
      }


    })
    this.getRealtimeInviteStatusFromSocket();




    this.socketService.receiveTripSavedDetails().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      console.log(data)
    })
   




    this.apiService.getTabs2TripData().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        // Use the data in this component
        // console.log(data);
        // this.tripDetails = data.tripDetails
        // this.tripUUID = data.tripDetails.uuid
        // this.tripOwner = data.tripDetails.tripOwner
        // this.ownerArray.push(this.tripOwner)
        // console.log(this.ownerArray)
        // this.getTripInvitees(this.tripUUID)
        // console.log(this.isOwner)
        // console.log(this.isInviteAccepted)
        // this.myEmail = this.apiService.getMyUserEmail();
        // this.myNumber = this.apiService.getMyUserPhoneNumber();
        // console.log(this.tripOwner)

      }

      // this.getLatestData();
      // this.apiService.myUserData.pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      //   console.log(res)
      //   this.tripOwner = res;
      // })




      // const notificationData = {
      //   updatedValue: this.tripDetails.count,
      //   tripId: this.tripDetails.uuid
      // }
      // console.log(this.notificationNumber)
      // this.apiService.updateNotificationNumber(notificationData)
      // if(this.isOwner){
      //   this.getInviteStatus(this.tripDetails.invitees);
      // //}
      // console.log(this.apiService.myTripData.invitees)
      // // this.tripUUID = this.tripDetails.uuid;
      // this.socketService.joinTripRoom(this.tripUUID);
      // this.tripDates = this.apiService.formatDateRange(this.tripDetails.tripDates)
      // this.apiService.updateNotificationNumber(this.notificationNumber)
      // console.log(this.notificationNumber)
      // this.isInviteAccepted = true
      // console.log(this.isInviteAccepted)
      // Set tripUUID

      // this.setSegment();
      // Get invite status


      // Get trip geolocation
      console.log(this.tripDetails)
      // this.getGeoLocation(this.tripDetails.tripLocation);

      // Get user authentication data and trip data
      // this.highlightSelectedDates(this.tripDetails.tripDates)
      // Listen for trip updates
      // this.apiService.tripData.subscribe((data)=>{
      //   console.log(data)
      //   // this.myTripData = data
      // })
    });



    // this.apiService.notificationNumber$.subscribe((value) => {
    //   console.log(value)
    //   this.notificationNumber = value;
    // });


    // this.clearCachedData()
  }

  private getNearbyRecommendations(latitude: number, longitude: number) {
    console.log("getting google recommendations now..")
    console.log(latitude, longitude);
    const location = `${latitude},${longitude}`;
    this.locationCoordinated = location
    const cacheKey = `tripData-${location}`;
    const radius = 50000;
    const includedTypes = ["restaurant"];
    const excludedTypes = ["home_improvement_store", "supermarket", "department_store", "shopping_mall", "gas_station", "movie_theater"];
    const maxResultCount = 20;

    // First, attempt to get the data from storage
    this.storage.get(cacheKey).then(cachedData => {
      if (cachedData) {
        console.log('Using cached data for', location);
        this.processTripRecommendations(cachedData);
      } else {
        // No cached data, make an API call
        console.log('Fetching new data for', location);
        this.geocodingService.nearbySearch(location, radius, includedTypes, excludedTypes, maxResultCount)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log(data);
            const operationalData = data.filter((place) => place.businessStatus === 'OPERATIONAL');
            this.processTripRecommendations(operationalData);
            // Cache the data for future use
            this.storage.set(cacheKey, operationalData);
          });
      }
    });
  }


  private processTripRecommendations(tripRecommendations) {
    console.log(tripRecommendations)
    this.tripRecommendations = tripRecommendations;
    this.coordinates = this.tripRecommendations.map((recommendation, index) => ({
      lat: recommendation.location.latitude,
      lng: recommendation.location.longitude,
      name: recommendation.displayName.text,
      reviews: recommendation.reviews,
      formattedAddress: recommendation.formattedAddress,
      editorialSummary: recommendation.editorialSummary,
      img: recommendation.photos
    }));


    this.apiService.setCoordinates(this.coordinates);
    this.apiService.setTripRestaurantData(this.tripRecommendations);
  }
  // async getCurrentLocation() {
  //   try {
  //     const coordinates = await Geolocation.getCurrentPosition();
  //     console.log('Latitude:', coordinates.coords.latitude);
  //     console.log('Longitude:', coordinates.coords.longitude);
  //     this.apiService.setMyCoordinates([coordinates.coords.latitude,coordinates.coords.longitude])
  //     this.getNearbyRecommendations( coordinates.coords.latitude, coordinates.coords.longitude)
  //   } catch (error) {
  //     console.error('Error getting location:', error);
  //   }
  // }

  toggleItinerary() {
    console.log("toggling")
    setTimeout(() => {
      this.showItinerary = true
    }, 150);

  }

  goBackToOverview() {
    console.log("toggling")
    this.showItinerary = false
  }

  selectColumn(index: number) {
    this.selectedColumnIndex = index;
  }
  isColumnSelected(index: number): boolean {
    return this.selectedColumnIndex === index;
  }


  private getTripDetails(tripId) {
    this.apiService.getTripDetails(tripId).pipe(take(1)).subscribe((data) => {
      console.log(data.trip)
      this.tripDetails = data.trip;
      // this.getNearbyRecommendations(data.trip.geoCode[0].lat, data.trip.geoCode[0].lng); 
      // this.getGeoLocation([this.tripDetails.tripLocation]);

      if (this.tripDetails.tripLocation && this.tripDetails.tripLocation.length > 0) {
        this.selectedLocation = this.tripDetails.tripLocation[0];
      }
      console.log(this.myUserId)
      this.isOwner = this.tripDetails.tripOwner.ownerId === this.myUserId;
      console.log("Owner", this.isOwner)
      this.inviteAcceptedCounter = this.tripDetails.invitees.filter((invitee) => invitee.isInviteAccepted).length + 1
      console.log(this.inviteAcceptedCounter)
      this.isTripLocationTBD = this.tripDetails.tripLocation === 'TBD'

      const cacheLocationData = `tripData-${this.selectedLocation}`;


      this.storage.get(cacheLocationData).then((cachedData) => {
        console.log(cachedData)
        if (cachedData) {
          // Use cached data
          this.viatorTagAndProductData = cachedData;
          console.log('This is cached data')
        } else {
          // Fetch data from API
          this.getTagsAndProductDataFromViator()
          console.log('This is api data')
        }
      });
      if (this.tripDetails.tripDates.length > 1) {
        this.tripStartAndEnd = this.tripDetails.tripDates[0].date + ' - ' + this.tripDetails.tripDates[this.tripDetails.tripDates.length - 1].date

      } else if (this.tripDetails.tripDates.length === 1) {
        this.tripStartAndEnd = this.tripDetails.tripDates[0].date
      } else {
        this.tripStartAndEnd = 'Dates TBD'
      }

      console.log(this.tripStartAndEnd)

      this.ecoFriendlyStatus = this.tripDetails?.isEcoFriendly;
      this.segmentValue = this.tripDetails.isTripPublic ? 'public' : 'private'
      this.tripOwner = data.trip.tripOwner;
      this.ownerArray.push(this.tripOwner);
      this.getTripInvitees(this.tripUUID);
      this.myEmail = this.apiService.getMyUserEmail();
      this.myNumber = this.apiService.getMyUserPhoneNumber();
      this.apiService.myTripData.invitees = this.tripDetails.invitees

      console.log('isOwner', this.isOwner)
      // this.highlightSelectedDates(this.tripDetails.tripDates)

      //}
      console.log(this.apiService.myTripData.invitees)
      // this.tripUUID = this.tripDetails.uuid;
      this.socketService.joinTripRoom(this.tripUUID);
      this.tripDates = this.apiService.formatDateRange(this.tripDetails.tripDates)
      this.setFlags();
      this.cdr.detectChanges()
    });
  }

  private getTagsAndProductDataFromViator() {
    const location = this.apiService.extractLocationFromSingleElement(this.tripDetails.tripLocation);
    console.log(location);
    this.apiService.getViatorDestId(location).pipe(take(1)).subscribe((res: any) => {
      console.log(res);
      this.viatorDestId = res;
      this.apiService.getViatorTagsAndProductsByDestination(this.viatorDestId).pipe(take(1)).subscribe((res) => {
        console.log(res);
        this.viatorTagAndProductData = res;
        const cacheLocationData = `tripData-${this.tripDetails.tripLocation}`;

        this.storage.set(cacheLocationData, this.viatorTagAndProductData);

      });
    });
  }

  // fetchData() {
  //   // Check if data exists in cache
  //   this.storage.get('cached_data').then((cachedData) => {
  //     if (cachedData) {
  //       // Use cached data
  //       this.viatorTagAndProductData = cachedData;
  //       console.log('This is cached data')
  //     } else {
  //       // Fetch data from API
  //       console.log('This is api data')
  //     }
  //   });
  // }

  private setFlags() {
    this.userIsViewingPublicTripFromExplorerPageAndNotJoinedTrip = !this.isOwner && this.getInviteStatus(this.tripDetails.invitees) && this.tripDetails.isTripPublic;
    this.userIsViewingPublicTripFromExplorerPageAndHasJoinedTrip = (!this.isOwner || !this.getInviteStatus(this.tripDetails.invitees));

    this.userHasAcceptedInviteFromPrivateTrip = this.getInviteStatus(this.tripDetails.invitees) && this.isOwner && !this.tripDetails.isTripPublic && !this.userIsViewingPublicTripFromExplorerPageAndNotJoinedTrip;
    this.shouldSeeOverlayForPrivateTrip = !this.isOwner && !this.getInviteStatus(this.tripDetails.invitees) && !this.tripDetails.isTripPublic;
    this.hasBeenInvitedButNotAcceptedYet = this.tripDetails.invitees.some((invitee) => !invitee.isInviteAccepted && invitee.userInvitedId === this.myUserId);

    this.hasAcceptedTripInvite = this.getInviteStatus(this.tripDetails.invitees);

    this.hasBeenInvited = this.tripDetails.invitees.some((invitee) => invitee.userInvitedId === this.myUserId);
    if (this.hasAcceptedTripInvite) {
      this.hasBeenInvited = false;
      this.shouldSeeOverlayForPrivateTrip = false;
    }

    if (this.hasBeenInvitedButNotAcceptedYet) {

      this.shouldSeeOverlayForPrivateTrip = true;
      this.hasAcceptedTripInvite = false;
      this.userHasAcceptedInviteFromPrivateTrip = false;
      this.tripDetails.isTripPublic = false;
    }
  }

  async ionViewWillEnter() {

    //   this.socketService.joinNotificationZone(this.myUserId)
    //   this.apiService.getMyNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
    //     console.log(res)
    //     const trip = res.find((trip)=>this.tripUUID === trip.tripUUID)
    //     console.log(trip)
    //     if (trip) {
    //       console.log(trip.tripUUID)

    //       const notificationData = {
    //         updatedValue: trip.count,
    //         tripId: trip.tripUUID
    //       };
    //       console.log(notificationData)
    //       this.apiService.updateNotificationNumber(notificationData)
    //       this.notificationNumber = trip.count;
    //       // Update notification data or perform other actions
    //       console.log('Notification data updated:', notificationData);
    //     } else {
    //       this.notificationNumber = 0
    //     }
    //   })


    //   console.log('hi again')
    //   this.apiService.notificationNumber$.pipe(takeUntil(this.destroy$)).subscribe((notificationCount: NotificationData)=>{
    //     console.log(notificationCount)
    //     this.notificationNumber = notificationCount?.updatedValue
    //     const id = notificationCount?.tripId

    //   })
    //   this.getRealtimeInviteStatusFromSocket();

    // }
  }
  async initStorage() {
    // Initialize Ionic Storage
    await this.storage.create();
  }

  findIsInviteAccepted(invitees, myEmail) {
    const invitee = invitees.find(invitee => invitee.email === myEmail);
    return invitee ? invitee.isInviteAccepted : false;
  }

  getTripInvitees(tripUUID) {
    this.apiService.getTripInvitees(tripUUID).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      console.log(res);
      console.log(this.apiService.myTripData.invitees);

      for (const userInvitedId of res.inviteesWithUserData) {
        console.log(userInvitedId)
        const inviteeIndex = this.apiService.myTripData.invitees.findIndex((invitee) => invitee.userInvitedId === userInvitedId._id);

        if (inviteeIndex !== -1) {
          // If a matching invitee is found, update the profilePicture
          this.apiService.myTripData.invitees[inviteeIndex].profilePicture = userInvitedId.profilePicture;
          this.getUserInitialsAndColors(this.apiService.myTripData.invitees);
          console.log(this.apiService.myTripData.invitees);
        }
      }
      this.isLoaded = true;
    });
  }

  navigateToHistory() {
    const tripUuid = this.tripDetails.uuid;

    this.router.navigate(['/history', tripUuid]);

    // this.modalController.dismiss()
    //  this.rootPage = HistoryPage;

    // this.component= HistoryPage

  }

  navigateToChat() {
    console.log('click..')
    const tripUuid = this.tripDetails.uuid;

    this.router.navigate(['/chat', tripUuid]);

    // this.modalController.dismiss()
    //  this.rootPage = HistoryPage;

    // this.component= HistoryPage

  }


  private getRealtimeInviteStatusFromSocket() {
    this.tripUpdateSubscription = this.socketService
      .onTripUpdated()
      .subscribe(async (data) => {

        // await PushNotifications.requestPermissions();

        // Handle push notifications when the app is open
        // PushNotifications.addListener('pushNotificationReceived', (notification) => {
        //   console.log('Push received: ' + JSON.stringify(notification));
        //   // Handle the notification as needed
        // });

        // // Handle background push notifications
        // PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        //   console.log('Push action performed: ' + JSON.stringify(notification));
        //   // Handle the action as needed
        // });

        console.log('Received trip update:', data);
        this.tripDetails = data.updatedTrip
        console.log(this.apiService.myTripData)
        this.setFlags()
        // this.apiService.myTripData = data.updatedTrip;
        // this.apiService.my
        this.apiService.myTripData.invitees = data.updatedTrip?.invitees;
        this.inviteAcceptedCounter = this.apiService.myTripData.invitees.filter((invitee) => invitee.isInviteAccepted).length + 1
        console.log(this.inviteAcceptedCounter)
        console.log('Received trip update:', data);

        // Set the shared data in the service
        this.apiService.setSharedData(data);
        // this.notificationNumber = data.updatedTrip?.notifications.filter(notification => !notification.seenBy.includes(this.myUserId)).length;
        // this.apiService.updateNotificationNumber(this.notificationNumber)

        console.log(this.notificationNumber)
        console.log(this.apiService.myTripData.invitees)
        this.getInviteStatus(this.apiService.myTripData.invitees)
        this.getUserInitialsAndColors(this.apiService.myTripData.invitees);

        this.apiService.setTripData(data.trips)
        this.apiService.setSingleTripData(data.updatedTrip)
        console.log(data.trips)
        // this.myTripData = myUserData.trips;
        // console.log('TRIP DATA:', this.myTripData);

        // // this.notificationNumber = this.myTripData.notifications?.filter(notification => !notification.seenBy.includes(this.myUserData.email)).length;
        // console.log(this.notificationNumber)
        // this.apiService.getUsersInvitedTrips(myUserData.email, myUserData.phoneNumber).subscribe((data) => {
        //   this.myInvitedtripData = data.matchingTrips;
        //   console.log(this.myInvitedtripData)
        // })
        // });
        this.cdr.detectChanges()
      });
  }

  goBack() {
    // this.router.navigate(['tabs/tab2'])
    this.router.navigateByUrl('/tabs/tab2');
  }

  goBackToTabs1() {
    this.router.navigate(['tabs/tab1'])
  }

  private getInviteStatus(inviteesArray) {
    console.log(this.myEmail)
    console.log(this.myNumber)
    // Add trip owner

    const inviteStatus = inviteesArray?.find((invitees) => invitees.userInvitedId === this.myUserId);
    // Check if isInviteAccepted is defined and get its value
    return this.isInviteAccepted = inviteStatus?.isInviteAccepted ? true : false;
    console.log(this.isInviteAccepted)
  }



  // private getGeoLocation(location) {
  //   console.log(location);
  //   this.geocodingService.geocode(location[0]).pipe(takeUntil(this.destroy$)).subscribe((geoCodeData) => {
  //     console.log(geoCodeData);
  //     this.getNearbyRecommendations(geoCodeData[0].lat, geoCodeData[0].lng); // Pass the cache key
  //   });

  // }

  // private setSegment() {
  //   this.segmentValue = this.tripDetails.isTripPublic ? 'public' : 'private';
  //   this.tripVisibilityText = this.segmentValue === 'public' ? 'This trip is visible to the public' : 'This trip is private';
  //   this.initialIsTripPublic = this.segmentValue;

  // }

  onVisibilityIconClick(isTripPublic) {
    console.log(isTripPublic)
    this.tripDetails.isTripPublic = !this.tripDetails.isTripPublic
    if (!this.tripDetails.isTripPublic) {

      this.segmentChanged('private'); // If public, change it to private
    } else {

      this.segmentChanged('public'); // If not public, change it to public
    }
  }
  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      if (i % 2 === 0) {
        // For even positions, use letters (avoid '00' or 'FF')
        color += letters[Math.floor(Math.random() * 14) + 1]; // Random hex digit from 1 to 14
      } else {
        // For odd positions, use digits (0-9)
        color += letters[Math.floor(Math.random() * 10)];
      }
    }
    return color;
  }

  public getUserInitials(name: string): string {
    // Your logic to generate initials from the user's name here
    return name.split(" ").map((namePart) => namePart.charAt(0)).join("");
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getUserInitialsAndColors(this.tripDetails?.invitees);

    },);

  }

  onNotificationNumberUpdated(updatedValue: any) {
    console.log(updatedValue)
    this.notificationNumber = updatedValue;
  }

  private getUserInitialsAndColors(array) {
    console.log(array);
    console.log(this.tripOwner)
    // Set isInviteAccepted to true for your user
    if (this.tripOwner) {
      this.tripOwner.isInviteAccepted = true;
    }
    //  array.unshift(this.tripOwner)

    const acceptedUsers = array?.filter(user => user.isInviteAccepted);
    // Initialize the userColors array based on whether the user has a profile picture
    //acceptedUsers.unshift(this.tripOwner)
    this.userColors = acceptedUsers?.map(user => (
      user.profilePicture ? null : this.getRandomColor()
    ));
    // Map the sorted users
    this.users = acceptedUsers?.map(user => ({
      name: user.userInvitedName,
      profilePicture: user.profilePicture
    }));

    console.log(this.users);
  }







  // private getNearbyRecommendations(latitude: number, longitude: number) {
  //   const location = `${latitude},${longitude}`; // Format as "lat,lng"
  //   const radius = 5000; // Set the desired radius
  //   const includedTypes = ["restaurant","bakery","cafe","coffee_shop","italian_restaurant","steak_house","sushi_restaurant","vegan_restaurant"]; // Specify included types
  //   const excludedTypes = []; // Specify excluded types, if any
  //   const maxResultCount = 20; // Set the maximum number of results
  //   this.geocodingService.nearbySearch(location, radius, includedTypes, excludedTypes, maxResultCount).subscribe((data) => {

  //   console.log(data)
  //   this.tripRecommendations = data?.places.filter((place) => place.businessStatus === 'OPERATIONAL');
  //   console.log(this.tripRecommendations);

  //   let photoPromises = [];

  //   this.tripRecommendations.forEach((place, placeIndex) => {
  //     if (place?.photos?.length > 0) {
  //       place.photos.forEach((photo) => {
  //         const photoReference = photo.name;
  //         // Note: Now including placeIndex in the promise resolution to track which place the photo belongs to
  //         photoPromises.push(
  //           new Promise((resolve) => {
  //             this.geocodingService.getPlacePhoto(photoReference).subscribe(
  //               (imageData) => {
  //                 const imageUrl = URL.createObjectURL(imageData);
  //                 // Resolve with an object containing the imageUrl and the placeIndex
  //                 resolve({ imageUrl, placeIndex });
  //               },
  //               (error) => {
  //                 console.error('Error loading image:', error);
  //                 // Resolve with a default image and placeIndex
  //                 resolve({ imageUrl: 'https://ionicframework.com/docs/img/demos/card-media.png', placeIndex });
  //               }
  //             );
  //           })
  //         );
  //       });
  //     }
  //   });


  //   Promise.all(photoPromises)
  //   .then((results) => {
  //     // Initialize an empty array for photoUrls in each place
  //     this.tripRecommendations.forEach(place => place.photoUrls = []);

  //     // Iterate over each result and associate it with the correct place using placeIndex
  //     results.forEach(({ imageUrl, placeIndex }) => {
  //       if (imageUrl) { // Check if imageUrl is not null or undefined
  //         this.tripRecommendations[placeIndex].photoUrls.push(imageUrl);
  //       }
  //     });

  //     // Filter out places without any photoUrls
  //     this.tripRecommendations = this.tripRecommendations.filter(place => place.photoUrls.length > 0);

  //     // Optional: Update the location for each place if necessary
  //     this.tripRecommendations = this.tripRecommendations.map(place => ({
  //       ...place,
  //       // location: this.location // Assuming this.location is defined elsewhere and relevant to each place
  //     }));
  //   });



  //     console.log(this.tripRecommendations)
  //   })
  // }

  public tripType(type: string) {
    console.log(type)
    // this.getNearbyRecommendations(this.location, type)
  }
  private getLatestData() {
    this.apiService.getUserAuthenticationData().pipe(takeUntil(this.destroy$)).subscribe((user) => {
      const userData$ = this.apiService.getUserDataFromDatabase(user);
      if (!userData$) {
        return;
      }

      userData$.pipe(takeUntil(this.destroy$)).subscribe((userData) => {
        // Find the trip in the user's data
        console.log(userData)
        // const notificationNumber = userData.trips
        const foundTrip = userData.trips.find((tripData) => tripData.uuid === this.tripUUID);
        if (foundTrip) {
          this.apiService.myTripData.invitees = foundTrip.invitees;
          console.log(foundTrip)

          this.apiService.setSingleTripData(foundTrip)
          this.notificationNumber = foundTrip?.notifications?.filter(notification => !notification.seenBy.includes(this.myUserId)).length;
          console.log(this.notificationNumber)
          return;
        }
        if (this.selectedTab === 'invited-trips') {
          this.apiService.myTripData.invitees = this.tripDetails.invitees;
          // this.numberOfInviteesIncludingYou = this.apiService.myTripData.invitees
          //   .filter(invitee => invitee.inviteMethods.includes(this.myEmail)).length;
          // console.log(foundTrip)
          // this.notificationNumber = foundTrip?.notifications.filter(notification => !notification.seenBy.includes(this.myUserData.email)).length;
          // console.log(this.notificationNumber)
          // if (this.numberOfInviteesIncludingYou) {
          //   this.count = (this.apiService.myTripData.invitees.length);
          //   console.log(this.count);
          //   console.log(this.numberOfInviteesIncludingYou);
          // }

          console.log(this.apiService.myTripData.invitees[0]);
        }

      });
      if (this.selectedTab === 'my-trips') {
        this.apiService.myTripData.invitees = this.tripDetails.invitees;
        this.count = 1;
        console.log(this.apiService.myTripData.invitees);
        // this.numberOfInviteesIncludingYou = this.apiService.myTripData.invitees
        // .filter(invitee => invitee.inviteMethods.includes(this.myEmail)).length;
        // if(this.numberOfInviteesIncludingYou){
        //   this.count = (this.apiService.myTripData.invitees.length - 1);
        //   console.log(this.count)
        //   console.log(this.numberOfInviteesIncludingYou)
        // }
        console.log(this.apiService.myTripData.invitees[0]);
      }
    });
  }

  dismiss() {
    this.getLatestData()
    // this.router.navigate(['tabs/tabs2'])
    // this.modalController.dismiss();
  }
  // private highlightSelectedDates(tripDates) {
  //   console.log(tripDates)
  //   for (const date of tripDates) {
  //     this.highlightedDates.push({
  //       date: date.slice(0, 10),
  //       textColor: '#800080',
  //       backgroundColor: '#ffc0cb',
  //       highlighted: true, // Initially, all dates are highlighted
  //     });
  //   }
  // }

  toggleDateSelection(date: string) {

    // Check if the date is undefined or null before proceeding
    if (date) {
      const dateIndex = this.tripDetails.tripDates.indexOf(date);

      if (dateIndex !== -1) {
        // Date is already selected, so remove it from the array
        this.tripDetails.tripDates.splice(dateIndex, 1);
        console.log('Date removed:', date);
      } else {
        // Date is not selected, so add it to the array
        this.tripDetails.tripDates.push(date);
        console.log('Date added:', date);

      }
    }
    console.log(this.tripDetails)
  }


  // Handle date selection
  public changeDate(event) {
    const newSelectedDates = event.detail.value || []; // Ensure it's an array

    // Compare the new selection with the stored selection
    for (const newDate of newSelectedDates) {
      const isSelected = this.selectedDates.includes(newDate);

      if (isSelected) {
        // Date is already selected, so remove it
        const index = this.selectedDates.indexOf(newDate);
        if (index !== -1) {
          this.selectedDates.splice(index, 1);
        }
      } else {
        // Date is not selected, so add it
        this.selectedDates.push(newDate);
      }
    }

    console.log(this.selectedDates); // This will have the updated list of selected dates
  }

  public segmentChanged(event: string) {
    this.segmentValue = event;
    console.log(this.toggleSegmentPublic);
    console.log(this.toggleSegmentPrivate);

    if (this.segmentValue === 'private') {
      this.toggleSegmentPublic = false;
      this.toggleSegmentPrivate = true;
      this.tripDetails.isTripPublic = false;
    } else {
      this.toggleSegmentPublic = true;
      this.toggleSegmentPrivate = false;
      this.tripDetails.isTripPublic = true;
    }

    this.saveTripDetails();
  }


  private onIsTripPublicChange(newValue: string) {
    if (newValue !== this.initialIsTripPublic) {
      // If the value changed from the initial value, enable the "Save" button
      this.isSaveDisabled = false;
    } else {
      // If the value is the same as the initial value, disable the "Save" button
      this.isSaveDisabled = true;
    }
  }


  async openGoogleMaps(url: string) {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }

  public saveTripDetails() {
    console.log('clicked save')
    // this.socketService.saveTripDetails(this.tripDetails)
    // this.presentToast(response)
    // console.log(this.ecoFriendlyStatus)
    // console.log(this.tripDetails)
    // this.tripDetails.isEcoFriendly = this.ecoFriendlyStatus;
    this.apiService.saveTripDetails(this.tripDetails).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      // this.tripDetails.isTripPublic = response.trip.isTripPublic;
      console.log(response)
      this.apiService.updateDataForASingleTripObs(response.updatedTrip)
      // this.apiService.myTripData.
      this.apiService.presentToast('privacy', this.tripDetails.isTripPublic)

      this.apiService.updateArray(response.allTrips)

    })

    //   // Use map to update the trip in the res array based on UUID

    //   this.apiService.getPublicTrip$.subscribe((res)=>{
    //     console.log(res)
    // })



    //   this.apiService.getPublicTrip$.pipe(
    //     map((res) => res.map(trip => (trip.uuid === response.trip.uuid ? response.trip : trip)))
    //   ).subscribe((updatedTrips) => {
    //     console.log(updatedTrips);
    //     // Now updatedTrips contains the array with the trip replaced with updated data
    //   });

    // });

  }

  async presentToast(response) {

    const message = `Trip visibility is ${response.trip.isTripPublic ? 'public' : 'private'}`;
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'bottom',
      cssClass: 'custom-toast'
    });
    toast.present();
  }

  ngOnDestroy() {
    console.log("Destroy")
    // this.apiService.myTripData.invitees = [];
    this.destroy$.next();
    this.destroy$.complete();
    console.log('leaving room.....')
    this.socketService.leaveTripRoom(this.tripUUID);
    sessionStorage.removeItem('multiLocationArray')
    // Unsubscribe from the trip update observable
    this.tripUpdateSubscription.unsubscribe();
   // this.clearCachedData()
  }

  public goBackToPreviousPage() {
    const backAnimation: AnimationBuilder = (baseEl: HTMLElement, opts?: any): Animation => {
      const animation = this.animationCtrl.create()
        .addElement(baseEl) // Use the baseEl as the primary element
        .duration(80) // Increased duration for a smoother transition
        .easing('ease-out') // Simpler and generally smoother easing function
        .fromTo('transform', 'translateX(-100%)', 'translateX(0%)')
        .fromTo('opacity', 0.2, 1);
  
      return animation;
    };
  
    this.navCtrl.navigateBack('/tabs/tab2', {
      animation: backAnimation
    });
  }

  clearCachedData() {
    this.storage.remove(`tripData-${this.selectedLocation}`).then(() => {
      console.log('Cached data cleared successfully');
    }).catch(error => {
      console.error('Error clearing cached data:', error);
    });
    this.storage.remove(`tripData-${this.locationCoordinated}`).then(() => {
      console.log('Cached coordinates data cleared successfully');
    }).catch(error => {
      console.error('Error clearing cached data:', error);
    });
  }
  ionViewDidLeave() {
    this.tripUpdateSubscription.unsubscribe();

  }

  public goToNotificationsPage() {
    // this.router.navigate(['/history']);
  }

  async openAddUserModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5,
      componentProps: {
        tripDetails: this.tripDetails,
        isUserBeingAdded: true
      },

    });
    modal.present();
  }

  async openQuestionnaire() {
    const modal = await this.modalCtrl.create({
      component: QuestionnaireComponent,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5,
      componentProps: {
        tripDetails: this.tripDetails,
      },

    });
    modal.present();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  async uploadFile() {
    if (!this.selectedFile) {
      // No file selected
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile);

    try {
      // Use Angular HttpClient to send the file to the server
      const response = await this.apiService.postTripImage(this.tripDetails.uuid, formData).toPromise();
      console.log('File uploaded successfully:', response);

      // After successfully uploading, you can update your trip's photo URL here
      // Example: this.tripDetails.tripUrl = response.fileUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }


  async changeTripPhoto() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Allow only image files

    input.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files[0];
      if (file) {
        this.selectedFile = file;
        this.uploadFile();
      }
    });

    // Trigger the file input dialog
    input.click();
  }
  menuClick(event: Event) {
    event.stopPropagation();
  }

  public acceptInvite() {
    this.actionSheetController.create({
      header: 'Accept Invitation',
      buttons: [
        {
          text: 'Accept',
          icon: 'checkmark-circle-outline',
          cssClass: 'success-button',
          handler: () => {
            const method = {
              number: this.myNumber,
              email: this.myEmail,
            };
            console.log(method);
            this.apiService
              .acceptInvite(this.tripDetails, method)
              .pipe(takeUntil(this.destroy$))
              .subscribe((trip: any) => {
                console.log(trip);
                this.isInviteAccepted = true;
                this.hasBeenInvited = false;
                this.hasAcceptedTripInvite = true;
                this.shouldSeeOverlayForPrivateTrip = false;
                this.userHasAcceptedInviteFromPrivateTrip = true;
                this.hasBeenInvitedButNotAcceptedYet = false
                this.isOwner = false;
                this.apiService.presentToast('acceptedInvitation', this.tripDetails.tripName)
              });
          },
        },
        {
          text: 'Cancel', // Rename to "Cancel" instead of "Accept"
          role: 'cancel', // Specify role as 'cancel' for the cancel button
          icon: 'close',
          handler: () => {
            // Handle the cancel action if needed
            // This function will be called when clicking outside the action sheet
          },
        },
      ],
    }).then((actionSheet) => {
      actionSheet.present();
    });
  }


  declineInvite() {
    this.actionSheetController.create({
      header: 'Decline Invitation',
      buttons: [
        {
          text: 'Decline',
          role: 'destructive',
          icon: 'close-circle',
          handler: () => {
            console.log('Decline clicked');
            // Add your decline logic here
            this.apiService.declineInvitation(this.tripDetails).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
              console.log(res)
              this.isInviteDeclined = res.updatedTrip.invitees.some((invitee) => invitee.userInvitedId === this.myUserId)
              this.apiService.presentToast('declinedInvitation', this.tripDetails.tripName)

              setTimeout(() => {
                this.router.navigate(['tabs/tab2'])
              }, 2000);
              // this.isInviteAccepted = false;
            })
          }
        },
        {
          text: 'Cancel', // Rename to "Cancel" instead of "Accept"
          role: 'cancel', // Specify role as 'cancel' for the cancel button
          icon: 'close',
          handler: () => {
            // Handle the cancel action if needed
            // This function will be called when clicking outside the action sheet
          },
        },
      ]
    }).then(actionSheet => {
      actionSheet.present();
    });
  }



  dismissIframe() {
    this.isShowingRecommendations = false;
  }


  async openRecommendation(recommendation?) {
    this.isShowingRecommendations = true;
    this.recommendation = recommendation
    console.log(recommendation)
    this.showMap(recommendation)
    const location = this.tripDetails.tripLocation
    const modal = await this.modalCtrl?.create({
      component: RecommendationsModalComponent,
      breakpoints: [0.1, 0.3, 0.5, 0.8],
      initialBreakpoint: 0.5,
      backdropDismiss: true,
      componentProps: {
        recommendation: recommendation,
        location: location

      },

    });

    modal.present();

  }
  public showMap(recommendation) {
    // Process the name
    const nameParts = recommendation.name.split(' ');
    const processedName = nameParts.map((word, index) => (index < nameParts.length - 1 ? word + '+' : word)).join('');
    // Process the vicinity
    const vicinityParts = this.recommendation.vicinity.split(' ');
    const processedVicinity = vicinityParts.map((word, index) => (index < vicinityParts.length - 1 ? word + '+' : word)).join('');
    console.log(this.tripDetails)
    // Construct the query string for the iframe
    const queryString = `&q=${processedName},${this.tripDetails.tripLocation}`;
    console.log(queryString)
    // Use `queryString` when building your iframe URL
    this.iframeUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY${queryString}`;
    console.log(this.iframeUrl)
  }
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
