import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { ModalController } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  map: any;
  @Input() coordinates = []
  private destroy$ = new Subject<void>();

  constructor(private apiService: ApiService, private modalCtrl: ModalController) { 

  }

  ngOnInit() {

  }

  ngAfterViewInit(){
 
    this.apiService.coordinates$.pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      console.log(data)
     if(data){
      console.log('got data..loading map...')
      this.coordinates = data;
      if(this.coordinates){
        this.loadMap();

      }

     }

     console.log(this.coordinates)
    })

  }

  loadMap() {
    console.log('Loading map...')
    // Calculate the bounds of all markers
    let bounds = new google.maps.LatLngBounds();
  
    this.coordinates?.forEach(place => {
      bounds.extend(place);
    });
  
    // Create the map options with the calculated bounds
    const mapOptions = {
      zoom: 15,
      // mapTypeId: google.maps.MapTypeId.HYBRID,
      styles: [
        {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "lightness": "0"
                }
            ]
        },
        {
            "featureType": "poi.medical",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "lightness": "-5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#a7ce95"
                },
                {
                    "lightness": "45"
                }
            ]
        },
        {
            "featureType": "poi.school",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#be9b7b"
                },
                {
                    "lightness": "70"
                }
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#5d4b46"
                },
                {
                    "lightness": "60"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "saturation": "23"
                },
                {
                    "lightness": "10"
                },
                {
                    "gamma": "0.8"
                },
                {
                    "hue": "#b000ff"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#a2daf2"
                }
            ]
        }
    ]
    };
    

    
  
    // Create the map with the specified options
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  
    // Fit the map to the calculated bounds
    this.map.fitBounds(bounds);
  
    // Set a maximum zoom level to prevent zooming in too much
    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      if (this.map.getZoom() > 15) {
        this.map.setZoom(15);
      }
    });
  
    // Loop through your array of coordinates and add markers to the map
    this.coordinates.forEach(place => {
      // Create a marker for each coordinate
      const marker = new google.maps.Marker({
        position: place,
        map: this.map,
        label: {
          text: place.name,
          color: 'black',
          fontSize: '12px',
          fontWeight: 'bold',
        }
      });
    });
  }
  
  cancel(){
    console.log("closing...")
    this.modalCtrl.dismiss({})
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    console.log('destroying map')

  }
  
}

