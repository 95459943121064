import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Plugins } from '@capacitor/core'; // Import Plugins directly
// const { Keyboard } = Plugins;
import { Platform } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-reply-message-popover',
  templateUrl: './reply-message-popover.page.html',
  styleUrls: ['./reply-message-popover.page.scss'],
})
export class ReplyMessagePopoverPage implements OnInit {
  @Input() originalMessage: string;
  
  constructor(private apiService: ApiService, private popoverController: PopoverController, private platform: Platform) { }

  dismissPopover(replyData?: any) {
    // Dismiss the popover with optional reply data
    this.popoverController.dismiss(replyData);
  }


  async openKeyboard() {
    try {
      // await Keyboard.show();
       this.apiService.sendReply('replyMessage');
      // this.apiService.messageInput.setFocus()
    } catch (error) {
      console.error('Error opening keyboard:', error);
    }
  }

  
  ngOnInit() {
  }

}
