import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IonInput, IonModal, ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { Observable, Subject, catchError, debounceTime, filter, of, switchMap, takeUntil } from 'rxjs';
import { ApiService } from '../api.service';
import { count } from 'console';
import { GoogleService } from '../google.service';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.page.html',
  styleUrls: ['./search-bar.page.scss'],
  encapsulation: ViewEncapsulation.None

})
export class SearchBarPage implements OnInit {
  @ViewChild('input', {static: false}) input!: IonInput;

  @Input()isTripPlannerPage? : boolean;
 @Input() countryLists: any;
 @Input() isTripBuilder? : boolean;
 @Input() tripId : string;
 @Output() locationRemoved = new EventEmitter<number>();

 searchControl: FormControl;
 public searchTerm: string = '';


 isCleared: boolean = false;
 public searchResults: any[] = [];
 public predictions = [];
 public locations: any[] = [];
 public location: string;
 public country: any;
 public isMultiLocation = false;
 public multiLocationArray = [];
 private destroy$ = new Subject<void>();

 public locationsToVoteOn = []
 locationInput ='';
 public searchForm: FormGroup; 
 constructor(private modalCtrl: ModalController, 
  private apiService: ApiService, private googlePlacesService: GoogleService, private formBuilder: FormBuilder) {
    
    this.searchForm = this.formBuilder.group({
      searchControl: [''] // Initialize with an empty value
    });
   }

  ngOnInit() {

    console.log(this.isTripPlannerPage)
    // To retrieve data from LocalStorage
const storedLocations = JSON.parse(localStorage.getItem('multiLocationArray'));
  if (storedLocations) {
    this.multiLocationArray = storedLocations;
    console.log(this.multiLocationArray)
  }

  
   
  }

  ngOnChanges(changes: SimpleChanges) {
    // When itineraryDates input changes, reinitialize Swiper
    console.log(changes)
    }
  
  ionViewDidEnter() {
    this.input.setFocus()
    if (!this.isTripBuilder) {
      console.log('searching countries.txt');
      // Subscribe to changes in the search term
      this.searchResults = []; // Clear existing search results
    }
  }
  


  selectPrediction(prediction: string) {
    // Handle the selected prediction, e.g., set it to a variable or perform an action
    console.log(prediction)
    this.location = prediction
    if(this.location.length > 0){
      this.country = this.location
      this.multiLocationArray.push(this.location)
      this.apiService.myTripData.tripLocation = this.multiLocationArray
      

      localStorage.setItem('multiLocationArray', JSON.stringify(this.multiLocationArray));
      this.locationsToVoteOn.push(this.location)
      console.log(this.locationsToVoteOn)
      console.log(this.apiService.myTripData.tripLocation)
      this.getGeoCodesFromGoogle(this.multiLocationArray);
      
      
    } else {
      this.country = prediction;
      this.locationInput = prediction;
       console.log(this.country)
       this.apiService.myTripData.tripLocation = this.country;
        console.log("another location added...")
        this.apiService.setNewModalBreakpoint();
    }

    this.predictions = []; // Clear the predictions list
    this.locationInput = ''
    // this.apiService.openSearchBar(0.2, true)
  }



  private getGeoCodesFromGoogle(locationArr) {
    console.log(locationArr);
    const uniqueLocations = new Set(); // Using a Set to ensure uniqueness
  
    this.googlePlacesService.geocode(locationArr).pipe((takeUntil(this.destroy$)))
    .subscribe((resArray) => {
      console.log(resArray);
  
      // Assuming resArray is an array of responses, each containing latitude and longitude

          // Use the updated method to append the new location
          this.apiService.updateLocations(resArray);
          sessionStorage.setItem('multiLocationArray', JSON.stringify(resArray));

  
      // Other code to execute after processing all responses
      // this.apiService.openSearchBar(0.4, true);
      // this.modal.setCurrentBreakpoint(0.2);
      this.apiService.setModalBreakpoint();
    });
  }
  
  

  saveTripPlanningLocationOptions(){
    console.log(this.locationsToVoteOn)
    this.apiService.createLocationOption(this.locationsToVoteOn, this.tripId)
    .pipe((takeUntil(this.destroy$)))
    .subscribe((res)=>{
      console.log(res)
       this.apiService.setLocationsToVoteOn(res)
      
      this.cancel();
      this.apiService.setStepperState(true)
      this.locationsToVoteOn = []
      this.multiLocationArray = [];
      localStorage.removeItem('multiLocationArray');

      this.searchResults = [];
      
    })
  }


  onClearSearch() {
    // Explicitly reset the value
    this.searchTerm = '';
    this.searchResults = []; // Clear the search results
    this.isCleared = true; // Set the flag
    console.log('Search input was cleared!');
  }
  searchCountries(query: string): Observable<any[]> {
    // Don't subscribe here; just return the Observable from the service
    return this.apiService.searchCountries(query);
  }

  

  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  public openDetails(country){
    this.cancel()
    this.apiService.openCountryDetails(country)
  }

  focusInput(){
    console.log("click")
    this.apiService.setNewModalBreakpoint()
  }

  onSearchTermChange() {
    // Implement your search logic here
    console.log('Search term changed:', this.searchTerm);
    // Call your search function with the updated search term
    if (!this.isTripBuilder) {
      this.searchCountries(this.searchTerm).pipe((takeUntil(this.destroy$)))
      .subscribe(
        results => {
          this.searchResults = results;
          console.log(this.searchResults);
        },
        error => {
          console.error(error);
          this.searchResults = [];
        }
      );
    }
  }

  

  onInputChange(event: any) {
    const inputValue = event.target.value;
    console.log(this.multiLocationArray)
    console.log(inputValue)
    // if(inputValue && this.multiLocationArray.length > 1){
    //   this.apiService.setNewModalBreakpoint()

    // }
    // Perform any actions with the input value
    console.log('Input Value:', inputValue);
    this.googlePlacesService.autocomplete(inputValue).pipe((takeUntil(this.destroy$)))
    .subscribe((data) => {
      // Handle the autocomplete response here
      console.log(data); // You can update your UI with the autocomplete suggestions
      if (data.predictions) {
        this.predictions = data.predictions.map((prediction: any) => prediction.description);
      } else {
        this.predictions = [];
      }
    });
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const itemMove = this.multiLocationArray.splice(ev.detail.from, 1)[0];
    this.multiLocationArray.splice(ev.detail.to, 0, itemMove);
    this.apiService.myTripData.tripLocation =  this.multiLocationArray;
    localStorage.setItem('multiLocationArray', JSON.stringify(this.apiService.myTripData.tripLocation));

    ev.detail.complete();
  }

  removeLocation(i: number, event: MouseEvent) {
    event.stopPropagation(); // Prevent interfering with reorder
    
    // Remove the location from the array
    this.multiLocationArray = this.multiLocationArray.filter((_, index) => index !== i);
    console.log(this.multiLocationArray);
    this.apiService.myTripData.tripLocation =  this.multiLocationArray;
    localStorage.setItem('multiLocationArray', JSON.stringify(this.apiService.myTripData.tripLocation));
    // Update sessionStorage after removal
    let storedArray = JSON.parse(localStorage.getItem('multiLocationArray'));
// If the storedArray is null or undefined, set it to an empty array
if (!storedArray) {
  storedArray = [];
}
storedArray = storedArray.filter((_, index) => index !== i);
console.log(storedArray);

// Update localStorage after removal
localStorage.setItem('multiLocationArray', JSON.stringify(storedArray));
    // Pass the remaining locations to update the map
    this.updateMapWithRemainingLocations();
      
    // Emit the event indicating that a location is removed
    this.locationRemoved.emit(i);
}

  
  private updateMapWithRemainingLocations() {
    // Filter out locations with undefined lngLat
    // const remainingLocations = this.multiLocationArray.filter(location => location.lngLat);
    
    // // Extract the coordinates of the remaining locations
    // const remainingCoordinates = remainingLocations.map(location => location.lngLat);
    // console.log(remainingCoordinates)
    // Pass the remaining coordinates to getGeoCodesFromGoogle or any other method to update the map
    console.log(this.multiLocationArray)
    this.getGeoCodesFromGoogle(this.multiLocationArray);
  }
  
  

  ngOnDestroy() {
    console.log('Destoryingggg')
    this.destroy$.next();
    this.destroy$.complete();
  }
}
