// socket.service.ts

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: any;

  constructor(private apiService: ApiService) {
    this.socket = io(this.apiService.backendUrl); // Replace with your server URL
  }



  joinTripRoom(tripUUID: string) {
    console.log(tripUUID)
    this.socket.emit('joinTripRoom', tripUUID);
  }

  joinTripMyIdRoom(tripUUID: string) {
    this.socket.emit('joinTripMyIdRoom', tripUUID);
  }

  joinTripAllMyRooms(tripUUIDs: string) {
    this.socket.emit('joinTabs2Trips', tripUUIDs);
  }


  leaveTripRoom(tripUUID: string) {
    this.socket.emit('leaveTripRoom', tripUUID);
  }

  leaveTabs2(rooms:  string[]){
    console.log(rooms)
    this.socket.emit('leaveTabs2', rooms);
  }

  onTripUpdated(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('tripUpdated', (data: any) => {
        observer.next(data);
      });
    });
  }
  onUserAddedToTrip(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('addedToTrip', (data: any) => {
        observer.next(data);
      });
    });
  }

  onUserEnteredTripList(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('notification', (data: any) => {
        observer.next(data);
      });
    });
  }
  saveTripDetails(trip: any) {
    this.socket.emit('saveTripDetails', {trip });
  }

  receiveTripSavedDetails(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('saveTripDetails', (data) => {
        observer.next(data);
      });
    });
  }

  sendMessage(tripId: string, myUserData: string, message: string, replyMessageObject?: any) {
    this.socket.emit('chatMessage', { tripId, user: myUserData, message, replyMessageObject });
  }

  receiveMessages(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('chatMessage', (data) => {
        observer.next(data);
      });
    });
  }
  leaveNotificationZone(myUserId){
    
    this.socket.emit('leaveNotificationZone', { myUserId });
  }

  joinNotificationZone(myUserId){
    this.socket.emit('joinNotificationZone', { myUserId });
  }

  joinTripDataUpdatedForPublicView(){
    this.socket.emit('joinTripDataUpdatedForPublicView', 'public-room');
  }

  notifyjoinTripDataUpdatedForPublicView() {
    return new Observable((observer) => {
      this.socket.on('joinTripDataUpdatedForPublicView', (data) => {
        observer.next(data);
      });
    });
  }

  notifyTaggedUser(taggedUserIds, senderUserId, tripId) {
    console.log(senderUserId, 'notifying..', taggedUserIds)
    this.socket.emit('notifyTaggedUsers', { taggedUserIds, senderUserId, tripId });
  }

  receiveTaggedNotifications(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('notifyTaggedUsers', (data) => {
        observer.next(data);
      });
    });
  }

  receiveTaggedUserNotification(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('notifyTaggedUsers', (data) => {
        observer.next(data);
      });
    });
  }

  public onItineraryUpdate(callback: (data: any) => void) {
    this.socket.on('itineraryUpdated', callback);
  }

}
