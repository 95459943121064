import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-itinerary-types',
  templateUrl: './itinerary-types.page.html',
  styleUrls: ['./itinerary-types.page.scss'],
})
export class ItineraryTypesPage implements OnInit {
  selectedItineraryType: string;
  @Input() item: any;
  @Input() tripId: string;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    console.log(this.item)
    console.log(this.tripId)
  }

  selectItineraryType(event: any) {
    this.selectedItineraryType = event.detail.value;
    console.log(this.selectedItineraryType);
    this.item.selectedItineraryType = this.selectedItineraryType;
    console.log(this.item)
  }

  linkItinerary() {
    // Perform action to link itinerary
    console.log(`Linking itinerary for type: ${this.selectedItineraryType}`);
    this.apiService.addItineraryToTrip(this.tripId, this.item).subscribe((res)=>{
      console.log(res)
      this.apiService.presentToast('itineraryLink', res.trip.tripName)
    })

  }

}
