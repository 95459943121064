import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Pagination from 'swiper';
import PaginNavigationation from 'swiper';
import Swiper from 'swiper';

import { IonicSlides } from '@ionic/angular';
import { animate, style, transition, trigger } from '@angular/animations';

// swiperModules = [IonicSlides];


// Swiper.use([PaginNavigationation, Pagination]);

@Component({
  selector: 'app-image-fullscreen-modal',
  templateUrl: './image-fullscreen-modal.component.html',
  styleUrls: ['./image-fullscreen-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // Define the "void" => "visible" transition
      transition(':enter', [ // :enter is alias for void => *
        style({ opacity: 0 }), // Start with an opacity of 0
        animate('0.5s ease-in', style({ opacity: 1 })) // Animate to opacity 1
      ]),
      // Define the "visible" => "void" transition
      transition(':leave', [ // :leave is alias for * => void
        animate('0.5s ease-out', style({ opacity: 0 })) // Animate to opacity 0
      ])
    ])
  ]
})
export class ImageFullscreenModalComponent implements OnInit {
  @ViewChild('swiperContainer', { static: true }) swiperContainer!: ElementRef<HTMLDivElement>;

  @Input() images: any[] = [];
  @Input() initialSlide: number = 0;
  @Input() currentSlideIndex: number = 0;
  swiper: Swiper | undefined;
  @Output() slideChanged = new EventEmitter<number>();

  // @ViewChild(IonSlides) slides: IonicSlides;

  constructor(private modalController: ModalController, private renderer: Renderer2) { }

  ngOnInit() {
    console.log(this.images)
    console.log(this.currentSlideIndex)

  }

  ngAfterViewInit() {
    if (this.swiperContainer) {
      this.swiper = new Swiper(this.swiperContainer.nativeElement, {
        // Your Swiper configuration options
        // For example:
        // loop: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // }
        initialSlide: this.currentSlideIndex ,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
      });

      // Listen to slide change event
      this.swiper.on('slideChange', () => {
        console.log('Slide changed');
        console.log('Active index:', this.swiper?.activeIndex);
        this.slideChanged.emit( this.swiper?.activeIndex);

      });
    }
  }

  onSlideChange(event) {
    console.log('Slide changed');
    // if (this.swiperComponent?.swiperRef) {
    //   console.log('Current index:', this.swiperComponent.swiperRef.activeIndex);
    // } else {
    //   console.log('SwiperComponent reference is undefined');
    // }
  }

  closeModal() {
    console.log(this.images)
    // Assuming swiperInstance is the variable holding the reference to your Swiper instance
    // This line gets the current active index before closing the modal
    // this.currentSlideIndex = this.swiperInstance.activeIndex;
    console.log(this.currentSlideIndex)
    this.modalController.dismiss({
      'dismissed': true,
      'currentSlideIndex': this.swiper?.activeIndex
    });
  }
}
