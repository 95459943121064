import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contacts } from '@capacitor-community/contacts';
import { ActionSheetController, IonInput, IonicSlides, ModalController, ToastController } from '@ionic/angular';
import { Subject, take, takeUntil } from 'rxjs';
import { ApiService } from '../api.service';
import { contacts } from '../contacts';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { TabsServiceService } from '../tabs-service.service';
import { DatePipe } from '@angular/common';
import { GoogleService } from '../google.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MapComponent } from 'ngx-mapbox-gl';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { DateRange, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Animation, AnimationController, AnimationBuilder, NavController } from '@ionic/angular';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.page.html',
  styleUrls: ['./contacts.page.scss'],
  animations: [
    trigger('fadeInOut', [
      // Define the "void" => "visible" transition
      transition(':enter', [ // :enter is alias for void => *
        style({ opacity: 0 }), // Start with an opacity of 0
        animate('0.5s ease-in', style({ opacity: 1 })) // Animate to opacity 1
      ]),
      // Define the "visible" => "void" transition
      transition(':leave', [ // :leave is alias for * => void
        animate('0.5s ease-out', style({ opacity: 0 })) // Animate to opacity 0
      ])
    ])
  ]
})
export class ContactsPage implements OnInit, OnDestroy {

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;
  @HostBinding('style.backgroundColor') backgroundColor: string;
  @ViewChild('input') input!: IonInput;

  @ViewChild(MapComponent) mapComponent!: MapComponent
  mapTopStyle = '26%'; // Default values
  // mapHeightStyle = '74%';
  public locations: any[] = [];
  public isMultiLocation = false;
  public multiLocationArray = [];
  public highlightedDates = [];
  public permission: any;
  public contacts: any[] = [];
  public searchQuery: string = '';
  public disableCreateTripButton = false;
  // public filteredContacts: any[] = [];
  public myTrips: any[] = [];
  public isTripPublic: boolean = false; // Initialize with a default value
  public country: any;
  public selectedDates: Date[] = [];
  public tripLimitNumber: string = '';
  public isEcoFriendly: false;
  private destroy$: Subject<void> = new Subject<void>();
  public regions = ['Americas', 'Europe', 'Asia', 'Africa']
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
    spaceBetween: 0 // Adjust this value as needed
  };
  // dateRange: { from: string; to: string } = {
  //   from: '2023-09-01',
  //   to: '2023-09-10',
  // };
  public mockCountryData = [
    {
      countryName: "Jamaica",
      cityName: "Kingston"
    },
    {
      countryName: "USA",
      cityName: "Atlanta"
    },
    {
      countryName: "France",
      cityName: "Paris"
    },
    {
      countryName: "Spain",
      cityName: "Madrid"
    },
    {
      countryName: "Germany",
      cityName: "Cologne"
    }
  ];
  public hideSearchBar = false;

  public searchActive: boolean = false;
  public userDeclinedContactsAccess = false;
  public showEditOption = false;
  public stepOne = false;
  public stepTwo = false;
  public stepThree = false;
  public stepFour = false;
  public stepFive = false;
  public stepDate = false;
  public tripForm: FormGroup;
  public minDate: string; // 
  public optionsArray = [
    {
      title: 'Create New',
      img: 'assets/cover-splash-screen.png'
    },
    {
      title: 'Edit Existing',
      img: 'assets/editTrip.jpeg'
    },

    { title: 'Something else', img: 'assets/cover-splash-screen.png' }
  ];
  swiperModules = [IonicSlides];

  private myUserId: string;
  private myTripData = {
    tripName: '',
    isTripPublic: false,
    isGoingWithFriendsFamily: false,
    tripLocation: '',
    tripDates: [],
    invitees: []
  }

  locationInput = '';
  placeDetails: any;
  nearbyPlaces: any[];
  predictions: string[] = [];
  public location: string;

  parsedDateRanges: { start: Date, end: Date }[] = [];
  selectedDateRange: DateRange<Date>;


  constructor(private router: Router, private tabsService: TabsServiceService, private _formBuilder: FormBuilder, private renderer: Renderer2,
    private datePipe: DatePipe,
    private actionSheetController: ActionSheetController,
    private navCtrl: NavController, private animationCtrl: AnimationController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private apiService: ApiService,
    private googlePlacesService: GoogleService,
    public cdr: ChangeDetectorRef,
    
    private route: ActivatedRoute) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to midnight
    this.minDate = today.toISOString(); // Convert to ISO string format
  }

  ngOnInit() {

    this.apiService.moveToNextStepInStepperEvent.subscribe((res)=>{
      console.log(res);
      if(res === true){
        this.goToStepDate()
      }
    })

    // this.apiService.searchPlaces('cancun').subscribe((data)=>{
    //   console.log(data)
    // })
    this.route.paramMap.subscribe(params => {
      console.log(params)
      this.myUserId = params.get('data');
      this.location = params.get('location')
      this.locationInput = this.location?.length > 0 ? this.location : ''
      console.log(this.location)
      console.log(this.myUserId)
    });
    this.resetStepper();
    this.tripForm = new FormGroup({
      tripName: new FormControl('')
    });
    
    this.getLatestDataFromDB();
    // this.loadContacts()
  }

  ionViewDidEnter() {
    this.input.setFocus();
  }
  
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.parsedDateRanges.some(range => {
        return date >= range.start && date <= range.end;
      });
      return highlightDate ? 'special-date' : '';
    };
  }
  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      // Update the selectedDateRange with the end date
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    } else {
      // Create a new DateRange with the selected date as the start date
      this.selectedDateRange = new DateRange(date, null);
    }

    // Update the tripDates array with the start and end dates
    const { start, end } = this.selectedDateRange;
    if (start && end) {
      this.apiService.myTripData.tripDates = [
        start.toISOString().substring(0, 10),
        end.toISOString().substring(0, 10)
      ];
    } else if (start) {
      this.apiService.myTripData.tripDates = [start];
    } else {
      this.apiService.myTripData.tripDates = [];
    }

    console.log(this.selectedDateRange);
    console.log(this.apiService.myTripData.tripDates)
}


  private getLatestDataFromDB() {
    this.apiService.getUserAuthenticationData().subscribe((user) => {
      this.apiService.getUserDataFromDatabase(user).subscribe((data) => {
        console.log(data);
        this.myTrips = data.trips;

      });
    });
  }

  // Perform a place autocomplete search
  // onInputChange(event: any) {
  //   const inputValue = event.target.value;
  //   console.log(inputValue)

  //   // Perform any actions with the input value
  //   console.log('Input Value:', inputValue);
  //   this.googlePlacesService.autocomplete(inputValue).subscribe((data) => {
  //     // Handle the autocomplete response here
  //     console.log(data); // You can update your UI with the autocomplete suggestions
  //     if (data.predictions) {
  //       this.predictions = data.predictions.map((prediction: any) => prediction.description);
  //     } else {
  //       this.predictions = [];
  //     }
  //   });
  // }

  onSelectPlace(placeId: string) {
    console.log(placeId)
    this.googlePlacesService.getPlaceDetails('ChIJSyrkEAPUT48Rt5r_k9vA7Q4').subscribe(data => {
      console.log('Place details:', data);
      // Here you can process the place details
      // For example, extracting the latitude and longitude
      const location = data.result.geometry.location;
      console.log('Location:', location);
      // this.apiService.updateLocations({ lngLat: [lng, lat] });
    });
  }

  // selectPrediction(prediction: string) {
  //   // Handle the selected prediction, e.g., set it to a variable or perform an action
  //   console.log(prediction)
  //   this.location = prediction
  //   if(this.location.length > 0){
  //     this.country = this.location
  //     this.multiLocationArray.push(this.location)
  //     this.apiService.myTripData.tripLocation = this.multiLocationArray

  //     console.log(this.multiLocationArray)
  //     console.log(this.apiService.myTripData.tripLocation)
  //     this.googlePlacesService.geocode(this.location).subscribe((res) => {
  //       console.log(res);
  //       const results = res.results[0].geometry;
  //       const lng = results.location.lng;
  //       const lat = results.location.lat;

  //       // Use the updated method to append the new location
  //       this.apiService.updateLocations({ lngLat: [lng, lat] });
  //     });


  //   } else {
  //     this.country = prediction;
  //     this.locationInput = prediction;
  //      console.log(this.country)
  //      this.apiService.myTripData.tripLocation = this.country;
  //   }

  //   this.predictions = []; // Clear the predictions list
  // }

  removeLocation(index: number): void {
    this.multiLocationArray.splice(index, 1);
    
  }

  // Get place details using place_id
  getPlaceDetails(placeId: string): void {
    this.googlePlacesService.getPlaceDetails(placeId).subscribe((data: any) => {
      this.placeDetails = data.result;

      this.searchNearbyPlaces();
    });
  }

  // Search nearby places based on location and keyword
  searchNearbyPlaces(): void {
    if (this.placeDetails) {
      const location = this.placeDetails.geometry.location.lat + ',' + this.placeDetails.geometry.location.lng;
      const radius = 1500; // Set your desired radius
      const keyword = 'restaurant'; // Set your desired keyword
      this.googlePlacesService.searchNearbyPlaces(location, radius, keyword).subscribe((data: any) => {
        if (data.results) {
          this.nearbyPlaces = data.results;
        }
      });
    }
  }
  public formatDate(date: Date): string {
    return this.datePipe.transform(date, 'EEEE, MMMM d yyyy');
  }
  public dateChanged(event: any) {
    this.highlightedDates = [];
    this.selectedDates = [];
    this.selectedDates = event.detail.value?.map((dateString: string) => dateString);
    this.apiService.myTripData.tripDates = this.selectedDates
  }
  public toggleChanged(event: any, toggleName: string) {
    const newValue = event.detail.checked;
    console.log(toggleName)
    // You can now use the toggleName to identify which toggle was changed
    if (toggleName === 'isTripPublic') {
      console.log('isTripPublic toggle changed:', newValue);
      this.apiService.myTripData.isTripPublic = newValue

    } else if (toggleName === 'isGoingWithFriendsFamily') {
      this.apiService.myTripData.isGoingWithFriendsFamily = newValue;
      if (!this.apiService.myTripData.isGoingWithFriendsFamily) {
        this.apiService.myTripData.invitees = []
      }
    }
    if (toggleName === 'isEcoFriendly') {
      this.apiService.myTripData.isEcoFriendly = newValue;
    }
    console.log(this.apiService.myTripData)
    // You can also use newValue in your logic as needed
  }
public goBack() {
  const backAnimation: AnimationBuilder = (baseEl: HTMLElement, opts?: any): Animation => {
    const animation = this.animationCtrl.create()
      .addElement(baseEl) // Use the baseEl as the primary element
      .duration(80) // Increased duration for a smoother transition
      .easing('ease-out') // Simpler and generally smoother easing function
      .fromTo('transform', 'translateX(-100%)', 'translateX(0%)')
      .fromTo('opacity', 0.2, 1);

    return animation;
  };

  this.navCtrl.navigateBack('/tabs/tab1', {
    animation: backAnimation
  });
  this.tabsService.updateTabsVisibility(true);

}

  
  public resetStepper() {
    this.stepOne = true;
    this.stepTwo = false
    this.stepThree = false;
    this.stepFour = false;
    this.stepDate = false
    this.stepFive = false;
  }

  public goToStepTwo() {
    this.stepOne = false;
    this.stepTwo = true
    this.showEditOption = true;
    const tripName = this.tripForm.get('tripName').value
    this.apiService.myTripData.tripName = tripName;
    this.stepDate = false;
    console.log('goToStepTwo')
    // this.apiService.createTrip(tripName, this.myUserId).pipe(take(1)).subscribe((data)=>{
    //   console.log(data)
    // })
  }


  public goToStepThree() {
    console.log("clicked step 3...")
    this.stepOne = false;
    this.stepTwo = false
    if (this.apiService.myTripData.isGoingWithFriendsFamily) {
      this.stepThree = true
      console.log(this.stepThree)

    } else {
      this.stepFour = true;
    }
    this.stepDate = false;
    this.showEditOption = true;
    this.apiService.myTripData.tripLimitNumber = this.tripLimitNumber;
    this.apiService.myTripData.isEcoFriendly = this.isEcoFriendly
    this.apiService.myTripData.isTripPublic = this.isTripPublic;
    this.apiService.myTripData.isMultiLocation = this.isMultiLocation
    console.log(this.apiService.myTripData)
    // this.myTripData.tripName = 
    // this.apiService.createTrip(tripName, this.myUserId).pipe(take(1)).subscribe((data)=>{
    //   console.log(data)
    // })
  }
  public goToStepFour() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepThree = false
    this.stepFour = true;
    this.showEditOption = true;
    this.stepDate = false;
    this.apiService.myTripData.tripLocation = this.country;
    console.log(this.country)
    // const tripName = this.tripForm.get('tripName').value
    // this.myTripData.tripName = tripName;
    // this.apiService.createTrip(tripName, this.myUserId).pipe(take(1)).subscribe((data)=>{
    //   console.log(data)
    // })
  }

  public goToStepDate() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepThree = false
    this.stepFour = false;
    this.stepFive = false;
    this.stepDate = true;
    this.showEditOption = true;

  }
  public goToStepFive() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepThree = false
    this.stepFour = false;
    this.stepDate = false;
    this.stepFive = true;
    this.showEditOption = true;
    console.log("lkdsg")
    console.log(this.apiService.myTripData)

  }
  public goBackToStepOne() {
    this.stepOne = true;
    this.stepTwo = false
    this.stepThree = false;
    this.stepFour = false;
    this.stepDate = false

    //this.showEditOption = true;
  }
  public goBackToStepTwo() {
    this.stepOne = false;
    this.stepTwo = true
    this.stepThree = false;
    this.stepFour = false;
    this.stepDate = false

    //this.showEditOption = true;
  }
  public goBackToStepThree() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepFour = false;
    if (!this.apiService.myTripData.isGoingWithFriendsFamily) {
      this.stepTwo = true;
    } else {
      this.stepThree = true

    }
    this.stepDate = false

    // this.apiService.openSearchBar(true)
    console.log('yasso')
    //this.showEditOption = true;
    this.cdr.detectChanges()
  }
  public goBackToStepDate() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepThree = false;
    this.stepFour = false;
    this.stepDate = true
    this.stepFive = false;
    console.log('yasso3')
    this.highlightSelectedDates();
    //this.showEditOption = true;
    
  }

  private highlightSelectedDates() {
    for (const date of this.myTripData.tripDates) {
      this.highlightedDates.push({
        date: date.slice(0, 10),
        textColor: '#800080',
        backgroundColor: '#ffc0cb',
      });
    }
  }

  public goBackToStepFour() {
    this.stepOne = false;
    this.stepTwo = false
    this.stepThree = false;
    // if(!this.myTripData.isGoingWithFriendsFamily){
    //   this.stepTwo = true;
    //   console.log("in here")
    // } else {
    //   this.stepFour = true;
    // }
    this.stepFour = true;
    this.stepFive = false;
    this.stepDate = false
    console.log('yasso3')
    //this.showEditOption = true;
  }

  public selectCountry(event) {
    this.apiService.myTripData.tripLocation = event.detail.value
    console.log(this.apiService.myTripData)
  }
  // async loadContacts(){
  //   console.log('loading contacts...')
  //   //db lookup to see if matching numbers
  //  // this.userDeclinedContactsAccess = false;
  //   try {
  //     const permission = await Contacts.requestPermissions();
  //     this.permission = permission
  //     if(!permission?.contacts) return;
  //     else if(permission?.contacts === 'granted'){
  //       Contacts.getContacts({
  //         projection: {
  //           name: true,
  //           phones: true,
  //           image: true
  //         }
  //       }).then((result)=>{
  //         console.log('result',result)
  //         this.contacts = result.contacts
  //       })
  //     } else {
  //       this.userDeclinedContactsAccess = true;

  //       //need a solution for when user declines.
  //     }

  //   } catch(e){
  //     console.log(e)
  //     // need a solution for when user is on web
  //     if(e){ 
  //       const user = 
  //       {
  //         "contactId": "177C371E-701D-42F8-A03B-C61CA31627F7",
  //         "name": {

  //             "display": "Phillip DaCosta",
  //         },
  //         "phones": [
  //             {
  //                 "number": "(404) 751-7786",
  //                 "type": "mobile"
  //             }

  //         ]
  //     }

  //       this.contacts = contacts
  //       this.contacts.push(user)
  //       console.log( this.contacts)
  //     }
  //   }

  // }
  // async openActionSheet(contact: any) {
  //   console.log(contact);
  //   const buttons = contact.phones.map((phone: any) => ({
  //     text: phone.number,
  //     handler: () => {
  //       // Handle the selected phone number
  //       console.log('Selected phone number:', phone.number);
  //       this.openModal(contact,false, phone.number, false)
  //     }
  //   }));

  //   // Add an input option for email address
  //   buttons.push({
  //     text: 'Invite by Email Address',
  //     handler: () => {
  //       // Handle the email address input
  //       console.log('Email address entered');
  //       this.openModal(contact, true, '', false)
  //     }
  //   });

  //   const actionSheet = await this.actionSheetController.create({
  //     header: 'Where should we send the invite',
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel'
  //       },
  //       ...buttons
  //     ]
  //   });

  //   await actionSheet.present();
  // }

  // async openModal(contact?: any, isEmailOptionSelected?: boolean, number?: string, showAddContactAlternative?: boolean) {
  //   console.log('modal', number)
  //   const modal = await this.modalCtrl.create({
  //     component: EmailModalComponent,
  //     breakpoints: [0, 0.3, 0.5, 0.8],
  //     initialBreakpoint: 0.5,
  //     componentProps: {
  //       contact: contact, // Pass the contact data as a component property
  //       isEmailOptionSelected: isEmailOptionSelected,
  //       number: number,
  //       showAddContactAlternative: showAddContactAlternative
  //     },

  //   });
  //   modal.present();

  //   const { data, role } = await modal.onWillDismiss();

  //   if (role === 'confirm'  && data) {
  //     console.log(data);
  //     this.myTripData.invitees.push(data.response);

  //     this.presentToast(data)
  //   }
  // }

  async presentToast(data: any) {
    const { name, email, number } = data.response;
    const message = `Invite sent to ${name} at ${number || email}`;
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      cssClass: 'custom-toast'
    });
    toast.present();
  }

  get isTripName(): boolean {
    const trip = this.tripForm.get('tripName').value;

    return trip.trim() !== ''
  }

  // searchContacts(query: string) {
  //   // Convert the query to lowercase for case-insensitive search
  //   const lowercaseQuery = query.toLowerCase();

  //   // Check if the search query is empty
  //   this.searchActive = query.trim().length > 0;

  //   // Filter the contacts based on the search query
  //   this.filteredContacts = this.contacts.filter((contact) => {
  //     // Check if the contact's name or any phone number matches the search query
  //     return (
  //       contact.name.display.toLowerCase().includes(lowercaseQuery) ||
  //       contact.phones.some((phone) => phone.number.toLowerCase().includes(lowercaseQuery))
  //     );
  //   });
  // }

  public editTripName(trip) {
    this.tripForm.get('tripName').setValue(trip.tripName);
  }

  public submitTrip() {
    this.disableCreateTripButton = true;
    localStorage.removeItem('multiLocationArray');
    sessionStorage.removeItem('searchBar');

    const inviteesMap = new Map<string, any>(); // Map to track invitees by userPhoneId

    // Iterate through the invitees array and merge duplicate invitees
    console.log(this.apiService?.myTripData?.invitees)
    for (const invitee of this.apiService?.myTripData?.invitees) {
      if (inviteesMap?.has(invitee?.userPhoneId)) {
        // Merge inviteMethods if userPhoneId is already in the map
        const existingInvitee = inviteesMap?.get(invitee?.userPhoneId);
        existingInvitee.inviteMethods = Array.from(
          new Set(existingInvitee?.inviteMethods?.concat(invitee?.inviteMethods))
        );
      } else {
        // Add invitee to the map if not found
        inviteesMap?.set(invitee.userPhoneId, invitee);
      }
    }

    // Convert the map values back to an array of invitees
    const deduplicatedInvitees = Array.from(inviteesMap?.values());

    // Now, deduplicatedInvitees contains unique invitees with merged inviteMethods
    console.log(deduplicatedInvitees);

    // Create the payload with deduplicated invitees
    const myUpdatedTripData = {
      ...this.apiService?.myTripData,
      invitees: deduplicatedInvitees,
    };

    console.log(myUpdatedTripData)
    console.log(this.myUserId)
    this.apiService.createTrip(myUpdatedTripData).pipe(takeUntil(this.destroy$)).subscribe(async (data) => {
      console.log(data);
      if (data) {
        console.log("navigating")
        this.apiService.presentToast('tripCreated', data.trip.tripName)
        await Haptics.impact({ style: ImpactStyle.Heavy });
        // if(data.trip.isTripPublic){
        //   console.log("tabs1")
        //   this.router.navigate(['/tabs/tab1']);
        

          this.apiService.openTripDetails(data.trip)
       // }
        //  else {
         
        //   this.router.navigate(['/tabs/tab2']);
        // }


        this.apiService.getAllPublicTrips().pipe((takeUntil(this.destroy$))).subscribe((res) => {
          console.log(res)
          this.apiService.updateArray(res.trips);  
          this.tabsService.updateTabsVisibility(true);
        });
      }

    });
  }
  // public getAllMyTrips(){
  //   this.apiService.getAllMyTrips().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
  //     console.log(res)
  //     this.apiService.setTripData(res.trips)
  //     this.router.navigate(['/tabs/tab2']);
  //     this.tabsService.updateTabsVisibility(true);
  //   })
  // }

  openSearchBarAndHide() {
    sessionStorage.setItem('searchBar', 'true');
    this.hideSearchBar = true
    console.log('gaddd')
    this.backgroundColor = '#2e4c6b';
    console.log('rrrrrrr')
    this.apiService.openSearchBar(true)
    console.log('kkkkkkk')
    // Use Renderer2 to update the styles
    console.log('boom')
    this.mapTopStyle = '1%'; // New values
    // this.mapHeightStyle = '99%';
    console.log('bam')
     this.apiService.showFabIcon = false;

  }

  showSearchBar() {
    // this.hideSearchBar = false
    this.apiService.showFabIcon = false;
    console.log('clicking....')
    // this.backgroundColor = '#355474';
    this.apiService.openSearchBar(true)
  }

  onLocationRemoved(index: number) {
    console.log('removing index', index)
    this.locations.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

}
