import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import { Subject, takeUntil } from 'rxjs';
import { $ } from 'protractor';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.page.html',
  styleUrls: ['./category-modal.page.scss'],
})
export class CategoryModalPage implements OnInit {
  @Input() tripId: string;
  @Input() categories: any
  public selectedCategories = [];
  public categoryId: any
  public isCategoryDeleted = false
  private destroy$: Subject<void> = new Subject<void>();
  public index: any
  allCategories = [
    { name: 'When', icon: 'calendar',_id: '657daacfcd3d51e1e44e4c42' },
    { name: 'Budget', icon: 'wallet', _id: '657a4077844e65dc114e43e4' },
    { name: 'Where', icon: 'map', _id: '658a4077844e65cc914d43d6' },
    { name: 'Housing', icon: 'home', _id: '657a4077844e65cc914d43d3' },
    // Add more categories as needed
  ];

  constructor(private modalCtrl: ModalController, private apiService: ApiService) {}

  ngOnInit() {
    console.log(this.tripId);
    console.log(this.categories);
  
    // Pre-select categories whose 'categoryId' matches the '_id' of a category in 'allCategories'
    // and the 'tripId' is included in their 'trips' array.
    this.selectedCategories = this.allCategories
      .filter(allCat => this.categories.some(cat => cat.categoryId === allCat._id && cat.trips.includes(this.tripId)))
      .map(cat => cat._id);
  
    console.log(this.selectedCategories);
  }
  
  
  
  selectCategory(category) {
    const categoryId = category._id; // Use the _id property
    this.isCategoryDeleted = false;
    let index = this.selectedCategories.indexOf(categoryId);
    console.log(index)
    if (index > -1) {
        // If the category is already selected, remove it (deselect)
        const removedIndex = index;
        this.selectedCategories.splice(index, 1);
        this.isCategoryDeleted = true;
        console.log('index being removed ', removedIndex);
        // Pass the removedIndex to saveSelections
        this.saveSelections(removedIndex);
    } else {
        // If the category is not selected, add it (select)
        this.selectedCategories.push(categoryId);
        this.isCategoryDeleted = false;
        index = this.selectedCategories.indexOf(categoryId);
        console.log('index being added ', index);
        // Pass the index to saveSelections
        this.saveSelections(index);
    }
    index = 0;
    console.log(this.selectedCategories);
}

saveSelections(index) {
    console.log('Selected Categories:', this.selectedCategories);
    // Implement the logic to handle the selected categories
    // This might involve passing the data back to the parent component or making an API call
    this.apiService.saveCategories(this.tripId, this.selectedCategories).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        console.log('isCategoryDeleted ', this.isCategoryDeleted)
        // Pass the isCategoryDeleted and index to dismissModal
        this.dismissModal(res, this.isCategoryDeleted, index); // Optionally dismiss the modal after saving
    });
}

  
  dismissModal(res, isCategoryDeleted, updatedIndex) {
    this.modalCtrl.dismiss({
      categories: res.categories,
      isCategoryDeleted: isCategoryDeleted,
      updatedIndex: updatedIndex
    });  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
