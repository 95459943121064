import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../api.service';
import { IonNav, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-create-topic',
  templateUrl: './create-topic.page.html',
  styleUrls: ['./create-topic.page.scss'],
})
export class CreateTopicPage implements OnInit {
  tripLocation: any;
  title = '';
  description = '';
  tags = '';
  @Output() dataEmitter = new EventEmitter<string>();

  constructor(private apiService: ApiService, private navParams: NavParams, private ionNav: IonNav) { }

  ngOnInit() {
    this.tripLocation = this.navParams.get('tripLocation');
    console.log(this.tripLocation)
  }


  postQuestion() {
    const postData = {
      title: this.title,
      description: this.description,
      tags: this.tags.split(',').map(tag => tag.trim()), // Split tags by comma and trim whitespace
    };
    this.apiService.postCountryQuestion(this.tripLocation,postData).subscribe((res: any)=>{
      console.log(res)
      this.apiService.updateForumList(res)
      this.apiService.presentToast('Your question has been posted')
      this.ionNav.pop()
    })

  }

}
