import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import { Subject, takeUntil } from 'rxjs';
import { ImageFullscreenModalComponent } from '../image-fullscreen-modal/image-fullscreen-modal.component';
import Swiper from 'swiper';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.page.html',
  styleUrls: ['./preview-image.page.scss'],
})
export class PreviewImagePage implements OnInit {
  @Input() images: any[]; // Define the type based on what you're passing to it
  @Input() location: any;
  @Input() cityImages: any[]
  private destroy$: Subject<void> = new Subject<void>();
  swiper: Swiper | undefined;
  @ViewChild('swiperContainer', { static: true }) swiperContainer!: ElementRef<HTMLDivElement>;

  imageLoaded: boolean = false; 
  isLoaded = false;// You might want this to be an array if you're tracking load per image
  swiperConfig = {
    zoom: true,
    pagination: { clickable: true },
    navigation: true,
  };

  constructor(
    private modalController: ModalController, private apiService: ApiService,  private animationCtrl: AnimationController,
  ) { }

  ngOnInit() {
    console.log(this.cityImages)
    console.log(this.location)
    this.apiService.getCountryCityImages(this.location).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
      this.images = res
      this.isLoaded = true;
      // this.cdr.detectChanges()
    })
    // console.log(this.images)
    // this.images = this.navParams.get('images');
    // this.isLoaded = true;
    // // If you need to start at a specific slide
    // const initialSlide = this.navParams.get('initialSlide');
    // if (initialSlide !== undefined) {
    //   this.swiperConfig['initialSlide'] = initialSlide;
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images && changes.images.currentValue) {
      console.log('OnChanges:', this.images);
    }
  }

  ngAfterViewInit() {
    if (this.swiperContainer) {
      this.swiper = new Swiper(this.swiperContainer.nativeElement, {
        speed: 100,
        observeParents: true,
        observer: true,
        updateOnWindowResize: true,
        watchSlidesProgress: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
        // Disable/Enable features based on need
        zoom: false,
        virtual: false,
        // lazy: true // Only if you have lots of images
      });
      
      this.swiper.on('slideChange', () => {
        console.log('Slide changed');
        console.log('Active index:', this.swiper?.activeIndex);
        // this.slideChanged.emit( this.swiper?.activeIndex);

      });
    }
  }

  handleSlideChange(newIndex: number) {
    // Update the parent Swiper to the new index in real-time
    this.updateParentSwiper(newIndex);
  }
  updateParentSwiper(newIndex: number) {
    console.log('updating to index', newIndex)
    if (this.swiper) {
      this.swiper.slideTo(newIndex, 0, false); // No animation
    }
  }
    

  async openFullscreenModal(currentSlideIndex: number) {

    console.log("OPENING")
    const enterAnimation = (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;
    
      const backdropAnimation = this.animationCtrl.create()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    
      const wrapperAnimation = this.animationCtrl.create()
        .addElement(root.querySelector('.modal-wrapper')!)
        .fromTo('transform', 'translateY(-100%)', 'translateY(0)');
    
      return this.animationCtrl.create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(10)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    }
    
    const leaveAnimation = (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;
    
      const backdropAnimation = this.animationCtrl.create()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', 'var(--backdrop-opacity)', '0');
    
      const wrapperAnimation = this.animationCtrl.create()
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
          { offset: 0, transform: 'translateY(0) scale(1)' },
          { offset: 0.5, transform: 'translateY(-50%) scale(0.5)' },
          { offset: 1, transform: 'translateY(-100%) scale(0.1)' }
        ]);
    
      return this.animationCtrl.create()
        .addElement(baseEl)
        .easing('ease-in')
        .duration(500) // Increased duration for smoother transition
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };
    

    const modal = await this.modalController.create({
      enterAnimation,
      leaveAnimation,
      component: ImageFullscreenModalComponent,
      componentProps: {
        images: this.images,
        currentSlideIndex: currentSlideIndex,
      },  
      });
      modal.onWillDismiss().then((result) => {
        console.log(result)
        if (result && result.data && result.data.currentSlideIndex ) {
          // Handle the activeIndex received from the child component
          const activeIndex = result.data.currentSlideIndex
          console.log(activeIndex)
          this.updateParentSwiper(activeIndex)
          // Do whatever you need with the activeIndex from the child
        }
      });
  
      return await modal.present();
  }

  closeModal() {
    this.modalController.dismiss({
      'dismissed': true,
      // Pass back any necessary data
    });
  }
}
