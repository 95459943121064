import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { Tab2Page } from '../tab2/tab2.page';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from '../app.component';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.page.html',
  styleUrls: ['./history.page.scss'],
})
export class HistoryPage implements OnInit, OnDestroy {
  public  tripNotifications: any ;
  public  tripNotificationUsers: any ;
  public myUser: string
  public myEmail = this.apiService.getMyUserEmail()
  public myUserId = this.apiService.getMyUserId()
  public isLoaded = false;
  @ViewChild(Tab2Page) parentComponent: Tab2Page;
  private destroy$ = new Subject<void>();
  public showMoreThanOneApproval = false
  public multipleUserApprovalMessage: string;
  formattedNotifications: any[] = []; // To store formatted notifications
  // formattedNotifications: any[] = this.formatNotificationDates(this.tripData.notifications);

  constructor(private socketService: SocketService, private apiService: ApiService, private modalController: ModalController, private router: Router, private route: ActivatedRoute, private appComponent: AppComponent) { 

  }
  ngOnInit() {

    this.socketService.onTripUpdated().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      // Handle the updated trip data, e.g., update your UI
      console.log('Received updated trip:', res);
      this.callUserHasSeenNotification(res.updatedTrip.uuid)

      // const notificationData = {
      //   updatedValue: res.count,
      //   tripId: res.updatedTrip.uuid

      // }
      // this.apiService.updateNotificationNumber(notificationData );
      // this.apiService.setSingleTripData(updatedTrip)
      // Update your component's data with the received data
      // this.tripDetails = updatedTrip;

    });

   // this.appComponent.dataLoaded.subscribe((data) => {
     // console.log(data)
    //  if(data){
        console.log(this.myUserId)
    
        this.route.params.subscribe(params => {
          console.log(params.tripUuid)
          const tripUuid = params.tripUuid; // Access the appended value
          // Use tripUuid as needed in your 'history' component
          this.callUserHasSeenNotification(tripUuid)
          let hasNotSeenNotification: any;
          console.log(hasNotSeenNotification); // This might be undefined here, as it's asynchronous
        });
    
    //  }




  
    // this.apiService.singleTrip.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
    //   if (data) {
    //     this.tripData = data;
    //     console.log(data);
    //     this.tripData.notifications = this.formatNotificationDates(this.tripData.notifications);
  
    //     // Filter notifications where your user email is not in the seenBy array
    //     hasNotSeenNotification = this.tripData?.notifications?.filter(notification => {
    //       return !notification.seenBy.includes(this.myEmail);
    //     });
  
    //     if (hasNotSeenNotification) {
    //       // Make the API call here, only if hasNotSeenNotification is available
    //       this.callUserHasSeenNotification(hasNotSeenNotification);
    //     }
    //   }
    // });
  
   
  //});

  }
  
      // You can define a separate function to make the API call
      callUserHasSeenNotification(tripUUID) {
        this.isLoaded = false;
        this.apiService.userHasSeenNotification(tripUUID).subscribe((data: any) => {
          console.log(data);
          this.tripNotifications = data.tripNotifications;
          this.myUser = data.myUser
          const notificationNumber = data.count;
          this.tripNotifications = this.formatNotificationDates(data.tripNotifications);
          // Iterate through tripNotifications to add user data to the userInvited property
          this.tripNotifications.forEach((notification) => {
            console.log(notification)
            const userInvitedId = notification.userInvited.userInvitedId;
            const matchingUser = data.userInvited.find((user) => user._id === userInvitedId);
            if (matchingUser) {
              notification.userInvited = matchingUser;
            }
          });
          console.log(this.tripNotifications)
          // let previousUser = null;
          // let acceptedUsers = [];
          
          // for (const notification of this.tripNotifications) {
          //   if (notification.notificationType === 'accepted') {
          //     if (notification.userInvited._id !== previousUser) {
          //       acceptedUsers.push(notification.userInvited.name);
          //     }
          //     previousUser = notification.userInvited._id;
          //   }
          // }
          //   console.log(acceptedUsers)
          // if (acceptedUsers.length > 1) {
          //    this.showMoreThanOneApproval = true;
          //   this.multipleUserApprovalMessage = `${acceptedUsers.join(' and ')} have accepted the invitation.`;
          //   console.log(this.multipleUserApprovalMessage);
          // }
          this.tripNotifications = this.tripNotifications.reverse();
          this.isLoaded = true;
          console.log(notificationNumber);
          const notificationData = {
            updatedValue: notificationNumber,
            tripId: data.tripId
          }
          this.apiService.updateNotificationNumber(notificationData );
          // this.apiService.setSingleTripData(data.trip);
        });
      }
      
  
  
  formatNotificationDates(notifications: any[]) {
    return notifications?.map((notification) => {
      console.log(notification)
      return {
        ...notification,
        formattedDate: moment(notification.timestamp).fromNow()
      };
    });
  }

  

  ngOnDestroy(): void {
    console.log('leaving history...')
    this.destroy$.next();
    this.destroy$.complete();
  }

 
}
