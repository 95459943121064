import { Component, Input, OnInit } from '@angular/core';
import { HistoryPage } from '../history/history.page';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() notificationNumber: any
  @Input() isTabs: boolean;
  
  rootPage: any
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    console.log(this.notificationNumber)
    // this.apiService.notificationNumber$.subscribe((value) => {
    //   console.log(value)
    //   this.notificationNumber = value;
    // });

    //  this.apiService.notificationNumber$.subscribe((value) => {
    //   console.log(value)
    //   this.notificationNumber = value;
    // });
  }

  updateNotificationNumber() {
   
    // this.router.navigate(['/history']); // 
     // this.modalController.dismiss()
    //  this.notificationNumber = HistoryPage;
 
   }

}
