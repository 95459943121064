import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MaskitoOptions, MaskitoElementPredicateAsync } from '@maskito/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable, Subject, take, takeUntil } from 'rxjs';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EmailModalComponent implements OnInit {
  public name: string;
  public emailForm: FormGroup;
  public sentInvites: any = [];
  public myUserId = this.apiService.getMyUserId()
  private destroy$ = new Subject<void>();
  @Output() dataEmitter: EventEmitter<any> = new EventEmitter<any>();

  readonly phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly cardMask: MaskitoOptions = {
    mask: [
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
    ],
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

  @Input() contact: any; //
  @Input() isEmailOptionSelected: boolean; //
  @Input() number: string; //
  @Input() showAddContactAlternative: boolean;
  @Input() isUserBeingRemoved: boolean;
  @Input() isUserBeingAdded: boolean;
  @Input() component: string
  @Input() tripData: any;


  @Input() tripId: string; //
  constructor(private modalCtrl: ModalController, private apiService: ApiService, private socketService: SocketService) { }

  ngOnInit() {
    console.log(this.number)
    console.log(this.contact)
    console.log(this.component)
    console.log(this.tripData)
    console.log(this.isEmailOptionSelected)
    this.emailForm = new FormGroup({
      email: new FormControl(''),
      number: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\+1 \(\d{3}\) \d{3}-\d{4}$/)
      ]),
      name: new FormControl('')
    });
    this.socketService.onTripUpdated().pipe(takeUntil(this.destroy$)).subscribe((updatedTrip) => {
      // Handle the updated trip data, e.g., update your UI
      console.log('Received updated trip:', updatedTrip);
      this.apiService.setSingleTripData(updatedTrip)
      // Update your component's data with the received data
      // this.tripDetails = updatedTrip;
    });
  }


  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  public confirm(isUserBeingRemoved: boolean) {
    // Create an empty response object
    let response: any = {};
    const inviteMethods = []

    // Check if it's not a user removal operation and the component is 'contactsPage'
    if (!isUserBeingRemoved && this.component === 'contactsPage') {
      if (this.showAddContactAlternative) {
        response = {
          name: this.contact.name.display,
          userPhoneId: this.contact.contactId,
          isInviteAccepted: false,

        };

        if (this.isEmailOptionSelected) {
          response['email'] = this.emailForm.get('email').value.toLowerCase();
          inviteMethods.push(this.emailForm.get('email').value.toLowerCase())
        } else {
          response['number'] = this.emailForm.get('number').value.toLowerCase();
          inviteMethods.push(response['number'] = this.number.toLowerCase())
        }

        response['inviteMethods'] = inviteMethods;
        console.log(response)
      } else {
        // const inviteMethods = []
        response = {
          name: this.contact.name.display.toLowerCase(),
          userPhoneId: this.contact.contactId,
          isInviteAccepted: false,

          // number: response['number'] = this.emailForm.get('number').value.toLowerCase()
        };
        console.log("YO DAWG 1")
        console.log(this.isEmailOptionSelected)
        if (this.isEmailOptionSelected) {
          console.log("YO DAWG 2")
          response['email'] = this.emailForm.get('email').value.toLowerCase();
          inviteMethods.push(this.emailForm.get('email').value.toLowerCase())
        } else {
          response['number'] = this.number.toLowerCase();
          inviteMethods.push(response['number'] = this.number.toLowerCase())
        }
        response['inviteMethods'] = inviteMethods;
        console.log(response)
        // this.apiService?.myTripData?.invitees.push(response)
      }
      console.log(this.apiService.myTripData)
      // Push the response to the sentInvites array
     
      // console.log(this.apiService.myTripData)
      // Dismiss the modal and pass the response
      this.modalCtrl.dismiss(
        {
          response,
          sentInvites: this.apiService.myTripData.invitees,
        },
        'confirm'
      );

      return this.sentInvites;
    } else if (this.component === 'tripDetailsPage') {
      const email = response['email'] = this.emailForm.get('email').value.toLowerCase();
      // Create contact data
      let contactData = {}
      if (!email) {
        contactData = {
          name: this.contact.name.display,
          number: this.number !== undefined ? this.number : response['number'] = this.emailForm.get('number').value.toLowerCase(),
          userPhoneId: this.contact.contactId,
          isInviteAccepted: false
        };
      } else {
        contactData = {
          name: this.contact.name.display,
          email: email,
          userPhoneId: this.contact.contactId,
          isInviteAccepted: false
        };
      }

      // Add the user to the trip
      this.addUserToTrip(contactData);
    } else {
      // Remove the user from the trip
      this.removeUserFromTrip();
    }
  }

  private removeUserFromTrip() {
    this.apiService.removeUserFromTrip(this.tripId, this.contact).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      // Emit the response using dataEmitter
      console.log(response)
      this.apiService.myTripData.invitees = response.invitees;
      this.apiService.setSingleTripData(response)
      this.apiService.getMyNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        console.log(res)

        const notificationData = {
          updatedValue: res[0].count,
          tripId: res[0].tripUUID
        }
        this.apiService.updateNotificationNumber(notificationData );
      })
      this.modalCtrl.dismiss(
        {
          response,
          isRemoved: this.sentInvites,
        },
        'confirm'
      );
    });
  }

  private addUserToTrip(contactData: {}) {
    this.apiService.addUserToTrip(this.tripId, contactData).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.apiService.myTripData.invitees = response.invitees;
      this.apiService.setSingleTripData(response);
      this.apiService.getMyNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        console.log(res)

        const notificationData = {
          updatedValue: res[0].count,
          tripId: res[0].tripUUID
        }
        this.apiService.updateNotificationNumber(notificationData );
      })
      this.modalCtrl.dismiss(
        {
          response,
          isRemoved: this.sentInvites,
        },
        'confirm'
      );
    });
  }

  get isEmailValid(): boolean {
    if (this.showAddContactAlternative) {
      const email = this.emailForm.get('email').value;
      // const name = this.emailForm.get('name').value;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      const phoneNumber = this.emailForm.get('number').value;
      const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      return (
        (email.trim() !== '' && emailRegex.test(email)) ||
        (phoneNumber.trim() !== '' && phoneNumberRegex.test(phoneNumber))
      );
    }

    const phoneNumber = this.emailForm.get('number').value;
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const email = this.emailForm.get('email').value;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return (
      (email.trim() !== '' && emailRegex.test(email)) ||
      (phoneNumber.trim() !== '' && phoneNumberRegex.test(phoneNumber)) ||
      !this.isEmailOptionSelected
    );
  }


}
