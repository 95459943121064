import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../theme.service';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Subject, take, takeUntil } from 'rxjs';
import { ActionSheetController, IonDatetime } from '@ionic/angular';
import { TabsServiceService } from '../tabs-service.service';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedInterest: string = ''
  public toggleState =false
  public isDark = false
  public imgUrl: string;
  public myData: any;
  public imageLoading = false;
  private destroy$: Subject<void> = new Subject<void>();
  timestamp: number;
  public userInterests = []
  public availableInterests: string[] = ['Beach', 'Hiking', 'Cultural Sites', 'Sight Seeing','Water Sports', 'Tropical'];

  constructor(private actionSheetController: ActionSheetController,private themeService: ThemeService, private tabsService: TabsServiceService,private router: Router,private cd: ChangeDetectorRef, private apiService: ApiService) {
    this.timestamp = Date.now();
   }

  ngOnInit() {
    this.loadData();

    // this.apiService.getUserAuthenticationData().pipe(takeUntil(this.destroy$)).subscribe((user) => {
    //   this.apiService.getUserDataFromDatabase(user).subscribe((myUserData) => {
    //     console.log(myUserData)
    //     // this.isUserActive = myUserData.isActive;
    //     // this.apiService.setIsUserActiveStatus(this.isUserActive)
    //     // this.apiService.setTripData(myUserData.trips, () => {
    //     //   // Callback function
    //     //   if (!this.isUserActive) {
    //     //     this.tabsService.updateTabsVisibility(false);
    //     //     this.getCountryList()
    //     //   }
    //     //   this.isLoaded = true
    //     //   this.myData = myUserData;
    //     //   this.myUserId = myUserData.id;
    //     //   this.apiService.setMyUserId(myUserData.id)
    //     //   this.apiService.getProfilePic(myUserData.id).subscribe((res)=>{
    //     //     console.log(res)
    //     //     this.myData.myProfilePicture = res[0];
    //     //     this.apiService.setMyUserData(this.myData)
    //       })
          // this.router.navigate(['/tabs/tab1']); // Navigate only after data is set
          //Pull user invited trips from database.          
       // });
    //  });
    // });







    // this.apiService.myUserData.subscribe((data )=>{
    //   console.log(data)
    //   this.myData = data;
    //   console.log(this.myData)
    //   this.cd.detectChanges(); // Manually trigger change detection
    // })
    
    setTimeout(() => {
      this.themeService.darkMode.pipe(takeUntil(this.destroy$)).subscribe((status: boolean) => {
        console.log(status)
       this.toggleState = status
      });

   });
  }

  removeInterest(interest: string) {
    this.userInterests = this.userInterests.filter(i => i !== interest);
    // Update the database with the new list of interests
    this.updateUserInterests();
  }
  
  addInterest(interest: string) {
    if (!this.userInterests.includes(interest)) {
      this.userInterests.push(interest);
    }
    // Reset the selected interest
    this.selectedInterest = '';
    // Update the database with the new list of interests
    this.updateUserInterests();
  }
  
  updateUserInterests() {
    this.apiService.saveUserInterests(this.userInterests).subscribe((res)=>{
      console.log(res)
      this.apiService.getAllPublicTrips().subscribe((res)=>{
        console.log(res)
        this.apiService.updateArray(res.trips)
      })
    })
  }
  






  public toggleDarkMode(){
    this.isDark = !this.isDark;
    this.themeService.changeDarkmode(this.isDark, this.apiService.getMyUserId()).pipe(take(1)).subscribe((res: any)=>{
     this.themeService.setDarkModeStatus(res.isDarkMode)
      this.toggleState = res.isDarkMode
      document.body.classList.toggle('dark', res.isDarkMode);
           
    })
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose an option',
      buttons: [
        {
          text: 'Take Picture',
          icon: 'camera',
          handler: () => {
            this.takePicture();
          }
        },
        {
          text: 'Choose a Picture',
          icon: 'image',
          handler: () => {
           this.fileInput.nativeElement.click();
          }
        },
        {
          text: 'Remove profile picture',
          icon: 'trash',
          handler: () => {
           this.deletePicture()
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
  
    await actionSheet.present();
  }
  
  // addPhotoToGallery() {
  //   this.addNewToGallery();
  // }
  // public async addNewToGallery() {
  //   // Take a photo
  //   const capturedPhoto = await Camera.getPhoto({
  //     resultType: CameraResultType.Uri,
  //     source: CameraSource.Camera,
  //     quality: 100
  //   });
  // }

  public async takePicture() {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 100,
    });
    console.log(capturedPhoto);
  
    if (capturedPhoto.dataUrl) {
      // Use the captured photo's dataUrl to display the image or upload it
      this.uploadProfilePicture(capturedPhoto.dataUrl);
    }
  }
  

  async readFileAndUpload(dataUrl: string) {
    try {
      // Generate a unique file name using a timestamp
      const fileName = `photo_${Date.now()}.jpg`;
  
      // Save the Data URL as a file
      const savedFile = await Filesystem.writeFile({
        path: fileName,
        data: dataUrl,
        directory: Directory.Data,
      });
  
      // Read the saved file data
      const fileData = await Filesystem.readFile({
        path: savedFile.uri,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
  
      // Now you can use `fileData` to display the image or upload it
      console.log(fileData.data)
      this.uploadProfilePicture(fileData.data);
    } catch (error) {
      console.error('Error reading file:', error);
    }
  }
  
  
  deletePicture(){
    this.apiService.deleteProfilePic().subscribe((res: any) => {
      console.log(res);
      if(res){
        this.apiService.presentToast('deletePicture')
      }
      this.myData.myProfilePicture = res.imageUrl;
      this.apiService.updateUserData(this.myData);
      this.imageLoading = false;
      this.cd.detectChanges(); // Manually trigger change detection
      this.loadData();
    });
  }

  

  uploadProfilePicture(event: any) {
    if (typeof event === 'string') {
      // Case for a picture taken with the camera
      const dataUrl = event;
      const dataBlob = this.dataURLToBlob(dataUrl);
  
      const formData = new FormData();
      formData.append('profilePicture', dataBlob, 'profile_picture.jpg');
      // Send the image to the server using HttpClient
      this.imageLoading = true;
      this.apiService.uploadProfilePic(formData).subscribe((res: any) => {
        console.log(res);
        if(res){
          this.apiService.presentToast('uploadPicture')
        }
        this.myData.myProfilePicture = res.imageUrl;
        this.apiService.updateUserData(this.myData);
        this.imageLoading = false;
        this.cd.detectChanges(); // Manually trigger change detection
        this.loadData();
      });
    } else if (event && event.target.files && event.target.files[0]) {
      // Case for uploading a file
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('profilePicture', file);
      
      // Send the image to the server using HttpClient
      this.imageLoading = true;
      this.apiService.uploadProfilePic(formData).subscribe((res: any) => {
        console.log(res);
        if(res){
          this.apiService.presentToast('picture')
        }
        this.myData.myProfilePicture = res.imageUrl;
        this.apiService.updateUserData(this.myData);
        this.imageLoading = false;
        this.cd.detectChanges(); // Manually trigger change detection
        this.loadData();
      });
    }
  }
  
  dataURLToBlob(dataURL: string) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  

  loadData() {
    this.apiService.getUserAuthenticationData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        console.log(user)
        this.apiService.getUserDataFromDatabase(user)
          .subscribe(myUserData => {
            this.myData = myUserData;
            // this.myData.myProfilePicture = myUserData.myProfilePicture
            // Additional logic as needed
            console.log(this.myData)
            this.userInterests = this.myData.userInterests
            // Ensure myData is loaded before fetching profile picture
            this.apiService.getProfilePic(myUserData.id).subscribe((data)=>{
              console.log(data)
              this.myData.myProfilePicture = data[0]
              this.apiService.updateUserData(this.myData);
              // this.imageLoading = true
            })
          
          });
      });
  }

  goBack() {
    // this.router.navigate(['/tabs/tab1']);
    this.tabsService.updateTabsVisibility(true)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

 