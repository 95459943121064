import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GoogleService } from '../google.service';
import { ApiService } from '../api.service';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Storage } from '@ionic/storage-angular'
import { GoogleMapContentComponent } from '../google-map-content/google-map-content.component';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-itinerary-activities',
  templateUrl: './itinerary-activities.page.html',
  styleUrls: ['./itinerary-activities.page.scss'],
})
export class ItineraryActivitiesPage implements OnInit {
  @ViewChild(GoogleMapComponent) googleMapComp: GoogleMapComponent;

  @Input() isTours: boolean;
  @Input() selectedDate: string;
  @Input() dayId: number;
  @Input() tripLocation: string;
  @Input() tripId: number;
  @Input() viatorDestinationProducts: any; // Replace with proper type
  @Input() tripRecommendations: any; // Replace with proper type
  @Input()selectedDateActivities: any;
  private destroy$ = new Subject<void>();
  public coordinates = []
  location = 'Madrid, Spain'
  selectedIndex: number = -1;
  // public tripRecommendations = [];
  attractions: any[] = [/*... your array of objects ...*/];
  selectedAttractions: any[] = []; // Define an array to store selected attractions
  // data :[]
  restaurants = [
    { name: 'Restaurant 1' },
    { name: 'Restaurant 2' },
    // Add more restaurants here
  ];
  subscription: Subscription;
  isVisible = false;
  private modal: HTMLIonModalElement;

  constructor(private modalCtrl: ModalController, private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private geocodingService: GoogleService, private apiService: ApiService, private storage: Storage) {
      this.initStorage();
      this.subscription = this.apiService.getModalState().subscribe(state => {
        console.log(state)
        this.isVisible = state;
         if(this.isVisible === false){
          this.closeModal()
         } else {
          this.openModal()
         }
      });
     }

  ngOnInit() {
    console.log(this.isTours)
    if(!this.isTours){
      this.apiService.currentGoogleData.pipe((take(1))).subscribe(data => {
        console.log(data)
        // Now you have access to all the passed data
        this.isTours = data.isTours;
        this.selectedDate = data.selectedDate;
        this.dayId = data.dayId;
        this.tripLocation = data.tripLocation;
        // this.viatorDestinationProducts = data.viatorDestinationProducts;
        this.tripRecommendations = data.tripRecommendations;
      });
    }

    console.log(this.viatorDestinationProducts)
    console.log(this.tripRecommendations)
    if(!this.isTours){

      this.openGoogleMapsContent(1)

  } else {
    this.attractions = this.viatorDestinationProducts
    console.log(this.attractions)
    console.log(this.selectedDateActivities)
    console.log(this.dayId)
    // Create a Set of productCodes from selectedDateActivities
    const selectedProductCodes = new Set(this.selectedDateActivities.map(activity => activity.productCode));

    // Iterate over attractions and set the flag if the productCode is in the selectedProductCodes Set
    this.attractions.forEach((attraction) => {
      if (selectedProductCodes.has(attraction.productCode)) {
        console.log(attraction);
        attraction.isAlreadySelectedForDayId = true;
      }
    });

    this.cdr.detectChanges()

  }
  }

  // private getNearbyRecommendations(latitude: number, longitude: number) {
  //   const location = `${latitude},${longitude}`; // Format as "lat,lng"
  //   const radius = 50000; // Set the desired radius
  //   const includedTypes = ["restaurant","bakery","cafe","coffee_shop","italian_restaurant","steak_house","sushi_restaurant","vegan_restaurant"]; // Specify included types
  //   const excludedTypes = []; // Specify excluded types, if any
  //   const maxResultCount = 20; // Set the maximum number of results
  //   this.geocodingService.nearbySearch(location, radius, includedTypes, excludedTypes, maxResultCount).subscribe((data) => {
 
  //   console.log(data)
  //   this.tripRecommendations = data?.places.filter((place) => place.businessStatus === 'OPERATIONAL');
  //   console.log(this.tripRecommendations);
    
  //   let photoPromises = [];

  //   this.tripRecommendations.forEach((place, placeIndex) => {
  //     if (place?.photos?.length > 0) {
  //       place.photos.forEach((photo) => {
  //         const photoReference = photo?.name;
  //         // Note: Now including placeIndex in the promise resolution to track which place the photo belongs to
  //         photoPromises.push(
  //           new Promise((resolve) => {
  //             this.geocodingService?.getPlacePhoto(photoReference).subscribe(
  //               (imageData) => {
  //                 const imageUrl = URL.createObjectURL(imageData);
  //                 // Resolve with an object containing the imageUrl and the placeIndex
  //                 resolve({ imageUrl, placeIndex });
  //               },
  //               (error) => {
  //                 console.error('Error loading image:', error);
  //                 // Resolve with a default image and placeIndex
  //                 resolve({ imageUrl: 'https://ionicframework.com/docs/img/demos/card-media.png', placeIndex });
  //               }
  //             );
  //           })
  //         );
  //       });
  //     }
  //   });
    

  //   Promise.all(photoPromises)
  //   .then((results) => {
  //     // Initialize an empty array for photoUrls in each place
  //     this.tripRecommendations.forEach(place => place.photoUrls = []);
    
  //     // Iterate over each result and associate it with the correct place using placeIndex
  //     results.forEach(({ imageUrl, placeIndex }) => {
  //       if (imageUrl) { // Check if imageUrl is not null or undefined
  //         this.tripRecommendations[placeIndex].photoUrls.push(imageUrl);
  //       }
  //     });
    
  //     // Filter out places without any photoUrls
  //     this.tripRecommendations = this.tripRecommendations.filter(place => place.photoUrls.length > 0);
    
  //     // Optional: Update the location for each place if necessary
  //     this.tripRecommendations = this.tripRecommendations.map(place => ({
  //       ...place,
  //       // location: this.location // Assuming this.location is defined elsewhere and relevant to each place
  //     }));
  //   });
    
  //     console.log(this.tripRecommendations)
  
  //     this.coordinates = this.tripRecommendations.map(recommendation => ({
  //       lat: recommendation.location.latitude,
  //       lng: recommendation.location.longitude,
  //       name: recommendation.displayName.text
  //     }));

  //     this.apiService.setCoordinates(this.coordinates)
  //     console.log(this.coordinates);
  //    // this.openGoogleMapsContent()

      
  //     console.log(this.tripRecommendations)
  //   })
  // }

  ngAfterViewInit(){
    console.log(this.isTours)
    console.log(this.tripRecommendations)
    // if(!this.isTours){

    //     this.openGoogleMapsContent(1)

    // } else {
    //   this.attractions = this.viatorDestinationProducts
    //   console.log(this.attractions)

    // }
    // this.fetchData();
    // this.searchPlacesFourSQ(this.tripLocation)
  }



  async openGoogleMapsContent(breakpoint) {
    const modal = await this.modalCtrl.create({
      component: GoogleMapContentComponent,
      breakpoints: [0.1,  1], // Removed 0, making 0.3 the minimum breakpoint
      initialBreakpoint: breakpoint,
      // cssClass: 'custom-modal-open',
      backdropBreakpoint: breakpoint,
       backdropDismiss: false, // Prevent modal from being dismissed by clicking
      componentProps: {
        data: this.tripRecommendations, // Pass the data as a component property
      },
    });
    await modal.present();
  

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm'  && data) {
      console.log(data);
      // this.presentToast(data)
      console.log("closing google map contents comp")
    }
  }
  

  findBestQualityImage(images: any[]): string | null {
    let bestQualityImageUrl: string | null = null;
    let bestQuality = 0;
  
    // Iterate through each image in the images array
    images.forEach((image) => {
      // Iterate through each variant of the current image
      image.variants.forEach((variant) => {
        // Calculate the quality of the current variant
        const quality = variant.height * variant.width;
  
        // Update bestQualityImageUrl if the quality of the current variant is better
        if (quality > bestQuality) {
          bestQuality = quality;
          bestQualityImageUrl = variant.url;
        }
      });
    });
  
    return bestQualityImageUrl;
  }



  async initStorage() {
    // Initialize Ionic Storage
    await this.storage.create();
  }

  // fetchData() {
  //   // Check if data exists in cache
  //   this.storage.get('cached_data').then((cachedData) => {
  //     if (cachedData) {
  //       // Use cached data
  //       this.attractions = cachedData;
  //       console.log('This is cached data')
  //     } else {
  //       // Fetch data from API
  //       // this.fetchDataFromApi();
  //       console.log('This is api data')
  //     }
  //   });
  // }


  

  // private fetchDataFromApi() {
  //   const city = this.apiService.extractLocationFromSingleElement(this.tripLocation);
  //   console.log(city);
  //   this.apiService.getViatorThingsToDo(city).pipe((takeUntil(this.destroy$))).subscribe((res) => {
  //     console.log(res);
  //     // const destinationId = res.destinationId;
  //     // console.log(destinationId)
  //     //  this.apiService.getViator(destinationId).pipe((takeUntil(this.destroy$))).subscribe((res)=>{
  //     //     console.log(res)
  //     this.attractions = res.data;
  //     this.storage.set('cached_data', this.attractions);
  //   });
  // }

  calculateStars(rating: number) {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return {
      fullStars: Array(fullStars).fill(0), // Creates an array with `fullStars` elements.
      halfStar: Array(halfStar).fill(0), // Creates an array with `halfStar` element, either 0 or 1.
      emptyStars: Array(emptyStars).fill(0), // Creates an array with `emptyStars` elements.
    };
  }


  isAttractionSelected(attraction: any): boolean {
    return this.selectedAttractions.some(selected => selected.productCode === attraction.productCode);
  }
  

  toggleSelection(attraction: any) {
    console.log(attraction)
    if (this.isAttractionSelected(attraction)) {
      // If attraction already selected, remove it from the array
      this.selectedAttractions = this.selectedAttractions.filter(selected => selected.productCode !== attraction.productCode);
    } else {
      // If attraction not selected, add it to the array with only the needed properties
   
      const { attractionCity, attractionStreetAddress, destinationId, rating, productCode, thumbnailUrl, title, productCount } = attraction;
      const productPricing = attraction.pricing.summary.fromPrice
      const productImage = this.apiService.findBestQualityImage(attraction.images)
      const productRating =  attraction.reviews.combinedAverageRating;
      const productReview =  attraction.reviews.totalReviews;
      const productFlags = attraction.flags
      const productURL =  attraction.productUrl;
      const productDuration = attraction?.duration?.variableDurationFromMinutes ? attraction?.duration?.variableDurationFromMinutes : attraction?.duration?.fixedDurationInMinutes
      
      this.selectedAttractions.push({ productFlags, productDuration, productRating, productPricing, productImage, productReview, productURL, attractionCity, attractionStreetAddress, destinationId, rating, productCode, thumbnailUrl, title, productCount });
    }
  
    console.log(this.selectedAttractions);
  }
  
  

  

  saveSelections() {
    // Implement save logic here, such as sending the data to a backend or storing locally.
    console.log(Array.from(this.selectedAttractions));


    this.apiService.saveSelectedAttractions(this.tripId, this.selectedAttractions,this.dayId).pipe((takeUntil(this.destroy$))).subscribe((res)=>{
      console.log(res)
      this.apiService.presentToast('selectionSaved')
      this.modalCtrl.dismiss({res})
    })
  }

  closeModal(){
    console.log("closing modal...")
    this.modalCtrl.dismiss()
  }

  openModal(){
    console.log("opening modal...")
    this.openGoogleMapsContent(0.1)
  }
  async openWebpage(url: string) {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }

  // extractLocationFromSingleElement(destinationArray) {
  //   if (destinationArray.length === 0) {
  //     return 'Array is empty';
  //   }

  //   // Access the first (and only) element in the array
  //   const fullLocation = destinationArray[0];

  //   // Split the string at the comma
  //   const parts = fullLocation.split(',');

  //   // Return the first part, trimmed of whitespace
  //   return parts[0].trim();
  // }

  selectLocation(location: any, index: number) {
    this.selectedIndex = index;
    console.log('Location selected:', location);
    this.getGeoLocation(location)
    // Implement further actions as necessary
  }

  searchActivities(event: any) {
    const query = event.target.value.toLowerCase();

    // Implement search logic here
    // For example, filter the activities array based on the query
  }

  openActivity(activity: any) {
    // Implement logic to open and view activity details
    console.log('Opening activity:', activity);
  }


  cancel() {
    console.log("closing modal")
    this.modalCtrl.dismiss()
    this.googleMapComp.cancel()
  }


  private getGeoLocation(location) {
    this.geocodingService.geocode(location).pipe((takeUntil(this.destroy$))).subscribe((geoCodeData) => {
      // Handle the response from the Geocoding API here
      console.log(geoCodeData);
      this.location = geoCodeData.results[0].geometry.location.lat + ',' + geoCodeData.results[0].geometry.location.lng;
      console.log(this.location);
    });
  }

  // private getNearbyRecommendations(location: string, type: any) {
  //   this.geocodingService.nearbySearch(location, 100, type, [], 20).subscribe((data) => {
  //     console.log(data)
  // Handle the response from the Places API here
  //Mocking nearbyssearch results

  // const resultTemp = [

  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4169473,
  //         "lng": -3.7035285
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.41830278029149,
  //           "lng": -3.70139075
  //         },
  //         "southwest": {
  //           "lat": 40.4156048197085,
  //           "lng": -3.70569575
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#7B9EB0",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Puerta del Sol",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 3024,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/116111766015791226277\">Andrei Constantin</a>"
  //         ],
  //         "photo_reference": "ATJ83zhgrtcoLwqnudaXCbC9j3A6KtbBfVSn3GulLLcfYTRgo15XfcXLIYGG9Z71_9dy95MmYIIkVkYq3mSizJaoy48H6GswJYxKspjP-WzHNllLrckuoIdnS7zD5HwY_bHop_yCa3NrJmsHsfTRAnX_1JOpmY0VaqlT1bt0YX1TWZAzfLlV",
  //         "width": 4032
  //       }
  //     ],
  //     "place_id": "ChIJXz_iGX4oQg0R-9a-2eSgws4",
  //     "plus_code": {
  //       "compound_code": "C78W+QH Madrid, Spain",
  //       "global_code": "8CGRC78W+QH"
  //     },
  //     "rating": 4.6,
  //     "reference": "ChIJXz_iGX4oQg0R-9a-2eSgws4",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 161038,
  //     "vicinity": "Puerta del Sol, s/n, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.41721579999999,
  //         "lng": -3.7037518
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4186244802915,
  //           "lng": -3.703056600000001
  //         },
  //         "southwest": {
  //           "lat": 40.4159265197085,
  //           "lng": -3.7057846
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Tío Pepe",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 2252,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/115267089889382864238\">Sergio Cristian Marquez de la Barrera</a>"
  //         ],
  //         "photo_reference": "ATJ83ziAak57Sd6C9SJKVGJbQN09rBTuwyc2n8WZ6aX-5Kzd0jYVjKpoRJXy_Vl3mhIOcr8yoM1duxpFB0kSpk42fY9Mzy_OXlSrdEgt1YlA16Nc9F1qszwtvz4B0iKGRc6P2yDU-7tNr9bce056mCKRbuZK4rixhwkw3xEqoO7QVI7mWgaP",
  //         "width": 4000
  //       }
  //     ],
  //     "place_id": "ChIJYVK5EX4oQg0RPdEI2G9kyC4",
  //     "plus_code": {
  //       "compound_code": "C78W+VF Madrid, Spain",
  //       "global_code": "8CGRC78W+VF"
  //     },
  //     "rating": 4.6,
  //     "reference": "ChIJYVK5EX4oQg0RPdEI2G9kyC4",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 233,
  //     "vicinity": "Puerta del Sol, 11, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4169769,
  //         "lng": -3.7027379
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4183639802915,
  //           "lng": -3.701359019708498
  //         },
  //         "southwest": {
  //           "lat": 40.4156660197085,
  //           "lng": -3.704056980291503
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#7B9EB0",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "El Oso y el Madroño",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 4032,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/102277544398092860080\">Sol Torres</a>"
  //         ],
  //         "photo_reference": "ATJ83zjAZeKeIrEqktbjYX2nPYPQC9THouelg_lWliaestCevyovTRIBNBWUXFGtxliz-YWFbZCEFSR_2z8lLsc51GYkwtN9j_mMj5SEZ_-QCTbJzco8SXxGK4s7w3pFwBliKEcafs6NCEpE_xsYo3VLMPLLsXXhk5ARwWiiDm7K-g1KzhJY",
  //         "width": 2268
  //       }
  //     ],
  //     "place_id": "ChIJN-KUGX4oQg0R0eKI7ogGgq8",
  //     "plus_code": {
  //       "compound_code": "C78W+QW Madrid, Spain",
  //       "global_code": "8CGRC78W+QW"
  //     },
  //     "rating": 4.5,
  //     "reference": "ChIJN-KUGX4oQg0R0eKI7ogGgq8",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 4941,
  //     "vicinity": "Puerta del Sol, 1, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4167754,
  //         "lng": -3.7037902
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.41799658029149,
  //           "lng": -3.703084069708498
  //         },
  //         "southwest": {
  //           "lat": 40.4152986197085,
  //           "lng": -3.705782030291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Callao y edificio Carrión",
  //     "photos": [
  //       {
  //         "height": 4032,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/118344537169776432769\">Mayron Vergel</a>"
  //         ],
  //         "photo_reference": "ATJ83zgjVqOlrRCvHzBI3XK4IImQgP9my-G8pxvyImsR_zyLzM9KSxBxOMSh_DMf7a2c6ZIQqR1we5Tjxoo-28RD5m3oA8ABDjMxVds-MwJ90MZkbFDOKAJX14FPSBZuNRmmM_pLqnjvyUUuwc1RQYpzpWER4LaomfoM83WFcslXBOnjUhqg",
  //         "width": 2268
  //       }
  //     ],
  //     "place_id": "ChIJ57ADmA8pQg0RAjchuR22URs",
  //     "rating": 5,
  //     "reference": "ChIJ57ADmA8pQg0RAjchuR22URs",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 1,
  //     "vicinity": "C78W+PF, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4166359,
  //         "lng": -3.7038101
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4179201302915,
  //           "lng": -3.703093469708497
  //         },
  //         "southwest": {
  //           "lat": 40.4152221697085,
  //           "lng": -3.705791430291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#7B9EB0",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Kilometre Zero",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 4032,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/106373468553483384407\">Romi Gutharz</a>"
  //         ],
  //         "photo_reference": "ATJ83zjdRc8BEkqKtVb82mnnvu3I3MV-KqBmGx3kaG_ovhh7y4t8bRuQJ30g-C5ijrn_k6Fm5q-7unPlosRwv4jesmL9LGkaKv7wcI-xxDzG9Xv5EizwC6Xh673H1pWaUaFOLnzdkwOf3SWFo-3A35bHn1gMah3rzmkjmJ-aHhE3ASy2EXAX",
  //         "width": 3024
  //       }
  //     ],
  //     "place_id": "ChIJSWOgLw4pQg0RC0t6k5xBdhY",
  //     "plus_code": {
  //       "compound_code": "C78W+MF Madrid, Spain",
  //       "global_code": "8CGRC78W+MF"
  //     },
  //     "rating": 4.5,
  //     "reference": "ChIJSWOgLw4pQg0RC0t6k5xBdhY",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "landmark",
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 1700,
  //     "vicinity": "Puerta del Sol, 7, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4165583,
  //         "lng": -3.7037846
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4179206802915,
  //           "lng": -3.703081919708498
  //         },
  //         "southwest": {
  //           "lat": 40.4152227197085,
  //           "lng": -3.705779880291503
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Clock Tower of Sun Gate",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 3024,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/102950781169487870580\">Szymon Syta</a>"
  //         ],
  //         "photo_reference": "ATJ83zgyPhPTx-teNlTJ8qVQa2O_o2_dYevOa50xDewu7Now7x1S2MYnHXtIO_l0TT1KiiE55N55b2AVtViwCkBOTL8y6IhirAUt00tHsI3HQ1h8Y5fjpxMBjHC8X8DQxCBdaFnTGdL0Eq4ffjYkyws3P8spxwopcF0ps9wDjd_r7LTQQGmD",
  //         "width": 4032
  //       }
  //     ],
  //     "place_id": "ChIJm1TJPX4oQg0R5uFSWl5x2zU",
  //     "plus_code": {
  //       "compound_code": "C78W+JF Madrid, Spain",
  //       "global_code": "8CGRC78W+JF"
  //     },
  //     "rating": 4.7,
  //     "reference": "ChIJm1TJPX4oQg0R5uFSWl5x2zU",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 133,
  //     "vicinity": "Puerta del Sol, 7, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4166635,
  //         "lng": -3.7041687
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.41801248029149,
  //           "lng": -3.702819719708498
  //         },
  //         "southwest": {
  //           "lat": 40.4153145197085,
  //           "lng": -3.705517680291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "마드리드 여행 코스",
  //     "photos": [
  //       {
  //         "height": 4032,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/103797414407651783393\">Bicha</a>"
  //         ],
  //         "photo_reference": "ATJ83zgs0D0WWbru3eBZaCUA4lbQjbH87162Oyj8p18zUbr8Q_ZAjmQgZE23pBN-9GluCx9EycTNT_SlNXg43iTK6Zzf-AaV6OY51zZNmGrqOGGURSqsT2OjqLjytMMoIgiD55khIph8sAYNxRHGN7Z1S4oVBT2EfkgOcNto6ZNPnNvfJKC3",
  //         "width": 3024
  //       }
  //     ],
  //     "place_id": "ChIJD3v9z40pQg0R3Jo6AHTUhdw",
  //     "plus_code": {
  //       "compound_code": "C78W+M8 Madrid, Spain",
  //       "global_code": "8CGRC78W+M8"
  //     },
  //     "reference": "ChIJD3v9z40pQg0R3Jo6AHTUhdw",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "vicinity": "Sol, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4164102,
  //         "lng": -3.7037974
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4177187302915,
  //           "lng": -3.703088319708497
  //         },
  //         "southwest": {
  //           "lat": 40.41502076970851,
  //           "lng": -3.705786280291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Real Casa de Correos",
  //     "opening_hours": {
  //       "open_now": false
  //     },
  //     "photos": [
  //       {
  //         "height": 3024,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/116111766015791226277\">Andrei Constantin</a>"
  //         ],
  //         "photo_reference": "ATJ83ziNLkS1Bg7EmTRlwVg7FVeCVhCLjFBvUmiWrxJmN7pcLL5LWIeycL3UQ39tceWw_aQlBcZlcGICdP5LsV_5pyrk070tsI0Fdn3B-lljMohaKwPLpAQPUHcQuGcwodYGdxsmivYJTY_f-kkwQenZXWAay0-TZoPKKvT8wJhhQoawqSlG",
  //         "width": 4032
  //       }
  //     ],
  //     "place_id": "ChIJw6gRPH4oQg0RABHe9Rw1PkI",
  //     "plus_code": {
  //       "compound_code": "C78W+HF Madrid, Spain",
  //       "global_code": "8CGRC78W+HF"
  //     },
  //     "rating": 4.5,
  //     "reference": "ChIJw6gRPH4oQg0RABHe9Rw1PkI",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 737,
  //     "vicinity": "Puerta del Sol, 7, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4168618,
  //         "lng": -3.7045595
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.4180577802915,
  //           "lng": -3.703468419708498
  //         },
  //         "southwest": {
  //           "lat": 40.4153598197085,
  //           "lng": -3.706166380291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#7B9EB0",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "La Mariblanca",
  //     "opening_hours": {
  //       "open_now": true
  //     },
  //     "photos": [
  //       {
  //         "height": 4160,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/103920148903994911142\">ANTONIO JESÚS MARTIN</a>"
  //         ],
  //         "photo_reference": "ATJ83zg72TVac_kSOiy_UUGJ3lCTYNhR1XcArPJXkKfikhm6IBl6g7Hq4mZIn0K32ZKhP9ir8X2wPE911xE58-5Z1VGHUaCTauVIxROZ_ixY2eJ5WzFQQh0wZoPEy3QyI8GHwNqPO_RGGr9wx--yyVBMOXhsNHjcHegIAqrTjSTgG1bxK_cj",
  //         "width": 3120
  //       }
  //     ],
  //     "place_id": "ChIJP5YWaX4oQg0RWZTLRYppQEI",
  //     "plus_code": {
  //       "compound_code": "C78W+P5 Madrid, Spain",
  //       "global_code": "8CGRC78W+P5"
  //     },
  //     "rating": 4.4,
  //     "reference": "ChIJP5YWaX4oQg0RWZTLRYppQEI",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 246,
  //     "vicinity": "Calle del Arenal, 2, Madrid"
  //   },
  //   {
  //     "business_status": "OPERATIONAL",
  //     "geometry": {
  //       "location": {
  //         "lat": 40.4161312,
  //         "lng": -3.704146699999999
  //       },
  //       "viewport": {
  //         "northeast": {
  //           "lat": 40.41748018029149,
  //           "lng": -3.702797719708498
  //         },
  //         "southwest": {
  //           "lat": 40.41478221970849,
  //           "lng": -3.705495680291502
  //         }
  //       }
  //     },
  //     "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
  //     "icon_background_color": "#13B5C7",
  //     "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  //     "name": "Belén de la Comunidad de Madrid",
  //     "opening_hours": {
  //       "open_now": false
  //     },
  //     "photos": [
  //       {
  //         "height": 3456,
  //         "html_attributions": [
  //           "<a href=\"https://maps.google.com/maps/contrib/110783732525236649578\">Juan Gutiérrez</a>"
  //         ],
  //         "photo_reference": "ATJ83zhCBBSK0hstlNcULzfeHHY8gXyOKZCH4bZOZONn5Krb8Ss3dLDYax6MxdvMiDuY1rH9i4yTEEmHp31qGJIDkvpxmI92DypOkmI8r5iJTabpItvibZq74kKuhSSGCqwBFsBH4jaRMBycKOWChZgdDlLIZ0xSyUk7FWhw68aT_8PjSr35",
  //         "width": 4608
  //       }
  //     ],
  //     "place_id": "ChIJS07rGEcpQg0R5tHbfoa1f2Y",
  //     "plus_code": {
  //       "compound_code": "C78W+F8 Madrid, Spain",
  //       "global_code": "8CGRC78W+F8"
  //     },
  //     "rating": 4.5,
  //     "reference": "ChIJS07rGEcpQg0R5tHbfoa1f2Y",
  //     "scope": "GOOGLE",
  //     "types": [
  //       "tourist_attraction",
  //       "point_of_interest",
  //       "establishment"
  //     ],
  //     "user_ratings_total": 57,
  //     "vicinity": "Real Casa de Correos, Madrid"
  //   }

  // ]
  //   this.tripRecommendations = data.places.filter((place) => place.businessStatus === 'OPERATIONAL');
  //   const photoPromises = this.tripRecommendations.map((place) => {
  //     if (place?.photos && place.photos.length > 0) {
  //       const photoName = place.photos[0].name;
  //       return new Promise<string>((resolve) => {
  //         this.geocodingService.getPlacePhoto(photoName).subscribe(
  //           (imageData) => {
  //             const reader = new FileReader();
  //             reader.onload = () => {
  //               const imageUrl = reader.result as string;
  //               resolve(imageUrl);
  //             };
  //             reader.readAsDataURL(imageData);
  //           },
  //           (error) => {
  //             console.log('Error loading image:', error);
  //             resolve('https://ionicframework.com/docs/img/demos/card-media.png'); // Default image URL
  //           }
  //         );
  //       });
  //     }
  //     return Promise.resolve(null);
  //   });

  //   Promise.all(photoPromises)
  //     .then((imageUrls) => {
  //       this.tripRecommendations = this.tripRecommendations.map((place, index) => ({
  //         ...place,
  //         photoUrl: imageUrls[index], // Assign the imageUrl
  //         location: this.location
  //       }));
  //       console.log(this.tripRecommendations);
  //     });


  // })
  // Filter operational places

  // this.tripRecommendations = resultTemp.filter((place) => place.business_status === 'OPERATIONAL');

  // console.log(this.tripRecommendations);
  // Create an array to store photo loading promises
  // Loop through the placesData.results array
  // Create an array to store photo promises
  // const photoPromises: Promise<string>[] = [];

  // Assuming this.tripRecommendations is an array of places
  // Assuming `getPlacePhoto` is updated to accept the new photo name reference

  // console.log(this.tripRecommendations)

  // }

  searchPlacesFourSQ(location: string) {
    this.apiService.searchPlacesFourSquare(location).subscribe(data => {
      console.log(data);

      // Extract fsqId values into an array
      const fsqIds = data.results.map(result => result.fsq_id);
      console.log(fsqIds);

      // Call getPlaceDetailsFourSQ with the array of fsqIds
      this.getPlaceDetailsFourSQ(fsqIds);
    });
  }

  getPlaceDetailsFourSQ(fsqIds: string[]) {
    // Iterate through each fsqId in the array
    fsqIds.forEach(fsqId => {
      this.apiService.getPlaceDetails(fsqId).subscribe(data => {
        console.log('Place Details:', data);
        // Display place details as needed
      });
    });
  }
}  
