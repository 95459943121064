import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { MaskitoModule } from '@maskito/angular';

import {IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthConfig, AuthModule } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri } from './auth.config';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/compat/storage';
import { CommonModule, DatePipe } from '@angular/common';
// import { TripdetailsComponent } from './tripdetails/tripdetails.component';
import { InviteFriendsComponent } from './invite-friends/invite-friends.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RecommendationsModalComponent } from './recommendations-modal/recommendations-modal.component';
import { SettingsPage } from './settings/settings.page';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CalendarModule } from "ion2-calendar";
import { NotificationsComponent } from './notifications/notifications.component';
import { SharedModule } from './shared.module';
import { HistoryPage } from './history/history.page';
import { TripPagePage } from './trip-page/trip-page.page';
import { ChatPage } from './chat/chat.page';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import {MatButtonModule} from '@angular/material/button';
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import { VoteResultsComponent } from './vote-results/vote-results.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import { CountryDetailsPage } from './country-details/country-details.page';
import { CityConversationPage } from './city-conversation/city-conversation.page';
import { CreateTopicPage } from './create-topic/create-topic.page';
import { ModalwrapperComponent } from './modalwrapper/modalwrapper.component';
import { SearchBarPage } from './search-bar/search-bar.page';
import { CityDetailsPage } from './city-details/city-details.page';
import { MapComponent } from './map/map.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { LinkedTripsPage } from './linked-trips/linked-trips.page';
import { ItineraryTypesPage } from './itinerary-types/itinerary-types.page';
import { Haptics } from '@capacitor/haptics';
import { ContactsPage } from './contacts/contacts.page';
import { ItineraryDetailsPage } from './itinerary-details/itinerary-details.page';
import { ItineraryActivitiesPage } from './itinerary-activities/itinerary-activities.page';
import { IonicStorageModule } from '@ionic/storage-angular';
import { PreviewImagePage } from './preview-image/preview-image.page';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { RouterModule } from '@angular/router';
// import { routes } from './app-routing.module'; // Make sure this import points to your actual routes


export function playerFactory(): any {  
  return import('lottie-web');
}

const config: AuthConfig = {
  domain,
  clientId,
  authorizationParams: {
    redirect_uri: callbackUri,
  },
  // For using Auth0-Angular with Ionic on Android and iOS,
  // it's important to use refresh tokens without the falback
  useRefreshTokens: true,
  useRefreshTokensFallback: false,
  cacheLocation:"localstorage"

};

@NgModule({
    declarations: [TripPagePage,ChatPage, ItineraryDetailsPage, ItineraryActivitiesPage, AppComponent, QuestionnaireComponent, SearchBarPage, ContactsPage, CityDetailsPage, MapComponent,LinkedTripsPage, ItineraryTypesPage,
      EmailModalComponent, SettingsPage, RecommendationsModalComponent, CityConversationPage, ModalwrapperComponent,
      HistoryPage, GenericModalComponent, InviteFriendsComponent, VoteResultsComponent, CountryDetailsPage, CreateTopicPage,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      ,
        BrowserModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AuthModule.forRoot(config),
        FormsModule,
        MaskitoModule,
        HttpClientModule,
        AngularFireStorageModule,
        NgxFileDropModule,
        NgxSkeletonLoaderModule,
        SharedModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        BrowserModule,
        CommonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,  
        FormsModule,
        // RouterModule.forRoot(routes), // Assuming you have routing setup, include your routes here

        IonicStorageModule.forRoot(),



        NgxMapboxGLModule.withConfig({
          accessToken: 'pk.eyJ1IjoicGhpbGxpcGRhY29zdGEiLCJhIjoiY2xzeG0ydWc0MDQwcDJrbzM1MnFxZDJubyJ9.N3Wyrw6gMLWr5sFi-fcZ6w', // Optional, can also be set per map (accessToken input of mgl-map)
        })
        
        
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy } ,DatePipe,  provideLottieOptions({ player: () => player  }),
      provideCacheableAnimationLoader(), {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    }],
    bootstrap: [AppComponent]
})
export class AppModule {}
