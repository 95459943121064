import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  isDark = false;
  toggleState = false;
  public darkMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private http: HttpClient){}

  toggleDarkMode() {
    // console.log(this.isDark)
    // this.isDark = !this.isDark;
    // document.body.classList.toggle('dark', this.isDark);
   
  }
    // Define a method to set new trip data and notify observers
    public getDarkModeStatus(): boolean {
      return this.darkMode.getValue();
    }
  
    // Setter for the string
    public setDarkModeStatus(value: boolean) {
      console.log(value)
      this.darkMode.next(value);
    }

    public changeDarkmode(isDark, userId) {
      console.log(isDark, userId)
      return this.http.post(this.apiService.backendUrl + '/changeDarkmode', { isDark: isDark, userId: userId })
  
    }
  
}
