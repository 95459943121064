import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateRange, MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { RecommendationsService } from '../recommendations.service';
import { VoteResultsComponent } from '../vote-results/vote-results.component';
import { SocketService } from '../socket.service';
import { CategoryModalPage } from '../category-modal/category-modal.page';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear()
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit {
  @ViewChild('calendar') calendar: MatCalendar<Date>;
  public isCalendar = false;
  public isOwner = false;
  dateRanges = [
    // ... your array of objects ...
  //   {
  //     "userId": "654193018de8cb44c6e68298",
  //     "tripId": "43b96605-882b-4489-aa36-95d74dd30767",
  //     "profilePicture": "https://storage.googleapis.com/travel_buggery/profile-pictures/654193018de8cb44c6e68298/myimage/1702673182232",
  //     "startDate": "2023-12-04T05:00:00.000Z",
  //     "endDate": "2023-12-09T05:00:00.000Z",
  //     "_id": "657def83fc2f74c886b95edf"
  // }
  ];

  parsedDateRanges: { start: Date, end: Date }[] = [];
  // @ViewChild(IonSlides) slides: IonSlides;

  private destroy$ = new Subject<void>();
   @Input() tripDetails: any
  public myUserId;
  public categories: any []
  public activitiesList : any []
  public showEmptyCategoryText = '';
  public selectedCategory: any = null;
  public selectedCategoryId: number;
  public selectedActivity: any = '';
  public selectedActivities: any = {};
  public isVoted = false;
  public shouldShowActivityForThisTrip = false;
  private profilePicture: string;
  public recommendedAccommodation: any
  public recommendations: any;
  public timeIsNull = false;
  public trip: any
  public selected: Date | null;
  public isDataLoaded = false;
  public tripDatesForFormatting = []
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 0 // Adjust this value as needed
  };

  tripDataForAI = {
    tripLocation: '',

  }
  campaignOne: FormGroup;
  selectedDateRange: DateRange<Date>;
  countdownEnd: Date;
  remainingTime: string;
  private intervalId: any;
  public activities = []
  
  private tripUpdateSubscription: Subscription;
  //   { id: '1', name: 'Hiking', selected: false, usersVotedYes: []  },
  //   { id: '2',name: 'Beach Day', selected: false,usersVotedYes: [] },
  //   {id: '3', name: 'Museum Visit', selected: false,usersVotedYes: [] },
  //   // Add more da as needed
  // ];
  countdown: string;
  constructor(public modalController: ModalController, private socketService: SocketService, private modalCtrl: ModalController, private formBuilder: FormBuilder,private modalCtr: ModalController, 
    private apiService: ApiService, private recommendationsService: RecommendationsService) { }

  ngOnInit() {
    this.isOwner = this.tripDetails.tripOwner.ownerId === this.apiService.getMyUserId()

    this.apiService.getDataForActivityList$.subscribe((res)=>{
      console.log(res)
      this.activitiesList = res;

    })

    this.tripUpdateSubscription = this.socketService
      .onTripUpdated()
      .subscribe(async (data) => {
        console.log(data)
        this.initializeCountdown(data.updatedTrip.countdownEnd)
      });

    console.log(this.parsedDateRanges);
    this.apiService.myUserData.pipe(takeUntil(this.destroy$)).subscribe((res: any)=>{
      console.log(res)
      this.profilePicture = res.profilePicture

    })
    this.campaignOne = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required]
    });
    
    console.log(this.tripDetails)
    this.apiService.myUserId.pipe(takeUntil(this.destroy$)).subscribe((userId) => {
     console.log('myUserId', userId)
     this.myUserId = userId;
    this.getAllCategoriesToDisplay()
    this.fetchCountdownData();
    })
  }

  fetchCountdownData() {
    if (this.tripDetails && this.tripDetails.uuid) {
      this.apiService.getTripCountdown(this.tripDetails.uuid).pipe(takeUntil(this.destroy$)).subscribe((trip: any) => {
        this.trip = trip;
        console.log(trip)
        if (trip.countdownEnd) {
          this.initializeCountdown(trip.countdownEnd);
        } else {
          console.log('Countdown data not available');
          this.timeIsNull = true;
        }
      }, error => {
        console.error('Error fetching countdown data:', error);
      });
    }
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: CategoryModalPage,
      breakpoints: [0, 0.3, 0.5, 0.8],
      initialBreakpoint: 0.5,
      componentProps: {
        tripId: this.tripDetails.uuid,
        categories: this.categories
      },
    });
  
    await modal.present();
  
// In the caller function
const { data } = await modal.onDidDismiss();
console.log('Modal dismissed with data:', data);
this.categories = data.categories;

let slideIndex = 0;
if (data.isCategoryDeleted) {
    // If a category was deleted, slide to the index before the updatedIndex
    slideIndex = Math.max(data.updatedIndex - 1, 0);
} else {
    // If a category was added, slide to the updatedIndex
    slideIndex = data.updatedIndex;
}
  console.log("slideIndex is: ", slideIndex)
// Ensure that slides are updated before sliding
// await this.slides.update();

// // Slide to the calculated slideIndex
// await this.slides.slideTo(slideIndex, 500); // Adjust slide duration as needed
  
    // this.getCategoryActivities(data , false)
    // // this.isCalendar = this.categories.some((cat) =>cat.category === 'When')
    // if(data.length > 0){
      
    // } else {
    //   console.log("Sup")
    //   this.showEmptyCategoryText = "Add a category to get started!"
    // }
   
    // You can now use this data as needed, for example, to update the UI or process the selected categories
  }
  


  startCountdown() {
    const { uuid } = this.tripDetails; 
    this.apiService.initializeCounter(uuid).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      console.log(res);
      this.apiService.updateDataForASingleTripObs(res.updatedTrip)
      // Assuming the backend response contains the 'countdownEnd' field
      if (res.updatedTrip.countdownEnd) {
        this.initializeCountdown(res.updatedTrip.countdownEnd);
        this.timeIsNull = false;
      } else {
        console.error('Countdown end time not provided in response');
      }
    }, error => {
      console.error('Error initializing countdown:', error);
    });
  }

  cancelCountDown(){
    console.log('cancelling countdown...')
    const { uuid } = this.tripDetails; 
    this.apiService.cancelCounter(uuid).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      console.log(res);
      this.countdown = res.updatedTrip.countdownEnd
      clearInterval(this.intervalId);
      // Assuming the backend response contains the 'countdownEnd' field
      if (res.updatedTrip.countdownEnd === null) {
        this.initializeCountdown(res.updatedTrip.countdownEnd);
      } else {
        console.error('Countdown end time not provided in response');
      }
    }, error => {
      console.error('Error initializing countdown:', error);
    });
  }
  getApiResponse(){
    this.apiService.getAIResponse(this.tripDetails.uuid).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
    })
  }

  initializeCountdown(countdownEnd: string) {
    console.log(countdownEnd)
    if(countdownEnd === null){
      this.timeIsNull = false;
      console.log(this.timeIsNull)
      clearInterval(this.intervalId);
    }
    const end = new Date(countdownEnd);

    this.intervalId = setInterval(() => {
      const now = new Date();
      const distance = end.getTime() - now.getTime();
      
      if (distance < 0) {
        console.log('clearing clock...')
        clearInterval(this.intervalId);
        this.countdown = '00:00:00';
        if(this.countdown === '00:00:00'){
          // this.getApiResponse()
        }
        this.timeIsNull = true;
        return;
      }

      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((distance / (1000 * 60)) % 60);
      const seconds = Math.floor((distance / 1000) % 60);
      this.countdown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  private refreshCalendar() {
    if (this.calendar) {
      // Refresh the calendar to apply changes
      this.calendar.updateTodaysDate();
    }
  }
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.parsedDateRanges.some(range => {
        return date >= range.start && date <= range.end;
      });
      return highlightDate ? 'special-date' : '';
    };
  }
  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
    console.log(this.selectedDateRange)
  }

//   submitForm() {
//     let startDate;
//     let endDate;
//    // if (this.campaignOne.valid) {
//       if(this.selectedDateRange.start){
//         startDate = this.selectedDateRange.start
//       }
//       if(this.selectedDateRange.end){
//         endDate =  this.selectedDateRange.end
//       }
//       // const startDate = this.campaignOne.get('start').value;
//       // const endDate = this.campaignOne.get('end').value;

//       // Prepare the object to send to the database
//       const dateRange = {
//         startDate: startDate, // or format it as required
//         endDate: endDate,     // or format it as required
//         userId: this.myUserId,
//         tripId: this.tripDetails.uuid,
//         selectedCategoryId: this.selectedCategoryId
//       };

//       console.log(dateRange);
//       this.apiService.saveUsersPreferredTripDates(dateRange.selectedCategoryId, this.tripDetails.uuid, dateRange)
//       .pipe(takeUntil(this.destroy$))
//       .subscribe((res: any) => {
//         // Handle response
//         console.log(res)
//         this.activitiesList.forEach((activity: any) => {
//           console.log('activity ', activity);
      
//           // Find a matching result based on the _id
//           // const matchingResult = res.findActivityAndUpdate.find((result: any) => result._id === activity._id);
          
//           if(res.findActivityAndUpdate._id === activity._id){
//             console.log('in here...')
//             const matchingResult = res.findActivityAndUpdate
//             activity.usersVotedYes = matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid);
//             this.isVoted = activity.usersVotedYes.some((user)=> user.userId === this.myUserId)
//             console.log('have i voted? ', this.isVoted)
//             this.addHighlightedDates(activity);
//           }
//            else {
//             // If no matching result is found, ensure usersVotedYes is empty or untouched
//             // depending on your requirement
//             //  activity.usersVotedYes = []; // Uncomment this line if you want to clear usersVotedYes when no matching result is found
//           }
//         });
        
//         this.isDataLoaded = true;
//       });
//  // }
//       // Here, send 'dateRange' to your backend or database
//    // }
//   }

  // clearDates(event){
  // //  const isChecked = event.detail.checked
  //   // this.toggleActivity(activity, true)
  //   console.log(this.selectedActivity)
  //   const userIndex = this.selectedActivity.usersVotedYes.findIndex(user => user.userId === this.myUserId);
    

  //   if ( userIndex > -1) {
  //     this.selectedActivity.usersVotedYes.splice(userIndex, 1);
  //     // Call API to remove vote
  //     this.apiService.removeUserFromTripActivity(this.selectedActivity._id, this.tripDetails.uuid)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((res: any) => {
  //         // Handle response
  //         console.log(res)
  //         this.activitiesList.forEach((activity: any) => {
  //           console.log('activity ', activity);
        
  //           if(res.updatedActivity._id === activity._id){
  //             const matchingResult = res.updatedActivity;
  //             activity.usersVotedYes = matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid);
  //             this.isVoted = activity.usersVotedYes.some((user)=> user.userId === this.myUserId)
  //             console.log('have i voted? ', this.isVoted)
  //             this.addHighlightedDates(activity);
  //             this.selectedDateRange = null;
  //             this.refreshCalendar()
  //           }
  //            else {
  //             // If no matching result is found, ensure usersVotedYes is empty or untouched
  //             // depending on your requirement
  //              activity.usersVotedYes = []; // Uncomment this line if you want to clear usersVotedYes when no matching result is found
  //           }
  //         });
  //         this.isDataLoaded = true;
  //       });
  //   }

  // }

  getAllCategoriesToDisplay(){
    this.apiService.getAllCategoriesToDisplay(this.tripDetails.uuid).pipe(takeUntil(this.destroy$)).subscribe(async (res)=>{
     
      this.categories = res.categories;
      console.log(this.categories)
      this.isCalendar = this.categories.some((cat) =>cat.category === 'When')
      const index = 0
      this.selectedCategory = this.categories[index];
 
      this.getCategoryActivities( this.selectedCategory, false)

      // if(this.categories.length > 0){
      //   // 
      // } else {
      //   console.log("Sup")
      //   this.showEmptyCategoryText = "Add a category to get started!"
      // }
    //   if (this.categories && this.categories.length > 0) {
    //     this.selectedCategory = this.categories[0];
    //     console.log(this.selectedCategory)
    //   } 
    //   const test =
    //   {
    //     "_id": {
    //         "$oid": "657cc8c4e4ae2a88a640719a"
    //     },
    //     "category": "When",
    //     "activities": [
    //         {
    //             "activityId": "6",
    //             "activityName": "WhenToGo",
    //             "activityBelongsToCategoryId": "657daacfcd3d51e1e44e4c42"
    //         }
    //     ],
    //     "categoryId": {
    //         "$oid": "657daacfcd3d51e1e44e4c42"
    //     }
    // }
    //   this.selectedCategory = test;
    //   this.getCategoryActivities(test, true)
    })
  }

  getCategoryActivities(category, isFromDefault?){
    this.isDataLoaded = false;
    console.log(category)
    // this.selectedCategory = category

   
    console.log(category, isFromDefault)
    if(category){
      this.selectedCategory = category.category; 
      this.selectedCategoryId = category.categoryId.$oid || category.categoryId

      console.log(this.selectedCategoryId)
      console.log(this.selectedCategory)
    }
    if(isFromDefault){
      this.selectedCategory = category.category; 
      category.categoryId= category.categoryId.$oid
      this.selectedCategoryId = category.categoryId.$oid || category.categoryId

    }

   this.apiService.getCategoryActivities(this.tripDetails.uuid, category.categoryId)
  .pipe(takeUntil(this.destroy$))
  .subscribe((res: any) => {
    this.activitiesList = res.matchingActivities;
    console.log(this.activitiesList);

    this.apiService.updateDataForActivityList(res.matchingActivities);
    this.apiService.getActivitiesResults(this.tripDetails.uuid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (resultsRes: any) => {
        console.log('resultsRes ', resultsRes);

        this.activitiesList.forEach((activity: any) => {
          console.log('activity ', activity);

          // Corrected equality check
          // if (activity.activityBelongsToCategoryId === "657daacfcd3d51e1e44e4c42" && activity.tripId === this.tripDetails.uuid) {
          //   console.log(activity);
          //   // Perform necessary updates or operations here
          // }

          const matchingResult = resultsRes.matchingActivitieResults.find((result: any) => result._id === activity._id);
      
          if (matchingResult) {
            console.log('found a matching result: ', matchingResult);
            activity.usersVotedYes = matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid);
            console.log(activity.usersVotedYes);
            // this.selectedActivity = activity;
            this.isVoted = activity.usersVotedYes.some((user) => user.userId === this.myUserId);
            console.log('have i voted? ', this.isVoted);
            // this.shouldShowActivityForThisTrip = true;
          } else {
            activity.usersVotedYes = [];  // Clear usersVotedYes if no matching result
          }
        });
        console.log('Updated activities list:', this.activitiesList);
        this.categories = this.categories.map((category) => {
          // Filter activitiesList to find activities that belong to the current category
          const matchedActivities = this.activitiesList.filter((activity) => {
           
            return activity.activityBelongsToCategoryId === category.categoryId;
          });
        
          // Return a new object that spreads the current category properties and adds the matchedActivities
          return { ...category, updatedActivities: matchedActivities };
        });
        console.log(this.categories)
        this.isDataLoaded = true;

        
      });
  });

  }

  // private addHighlightedDates(activity: any) {
  //   this.parsedDateRanges = activity.usersVotedYes.map(range => ({
  //     start: new Date(range.startDate),
  //     end: new Date(range.endDate)
  //   }));
  // }

  // async presentAlertPrompt() {
  //   const alert = await this.alertController.create({
  //     header: 'Add new activity',
  //     inputs: [
  //       {
  //         name: 'activityName',
  //         type: 'text',
  //         placeholder: 'Enter the activity name'
  //       }
  //     ],
  //     buttons: [
  //       {
  //         text: 'Save',
  //         handler: (data) => {
  //           console.log('Save clicked with activity name:', data.activityName);
  //           // Here you can call a method to handle the saving of the activity
  //         }
  //       },
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Cancel clicked');
  //         }
  //       }
  //     ]
  //   });
  
  //   await alert.present();
  // }
  

  // public openAddNewActivity(){
  //   this.selectedCategory;
  //   console.log(this.selectedCategory)
  // }

    // This method is triggered every time the slide changes
    async slideChanged(category) {
      console.log("Changing...")
      this.isDataLoaded = true;
      // const index = await this.slides.getActiveIndex();
      // this.selectedCategory = this.categories[index];
 
      this.getCategoryActivities( this.selectedCategory, false)
      console.log(this.selectedCategory)
     
      // this.categories.forEach((cat)=>{
      //   console.log(cat)
      //   if(cat.activityBelongsToCategoryId === this.selectedCategory.categoryId){
      //     console.log(cat)
      //   }
          
 
      // })
      // Do something with the selectedCategory
    }

  public async openActivity(){

    const modal = await this.modalCtrl.create({
      component: VoteResultsComponent,
      breakpoints: [0, 0.3, 0.5, 0.8],
      initialBreakpoint: 0.8,
      componentProps: {
        activitiesList: this.activitiesList,
        selectedCategory: this.selectedCategory,
        myUserId: this.myUserId,
        tripDetails: this.tripDetails,
        selectedCategoryId: this.selectedCategoryId,
        isVoted: this.isVoted
      },

    });
    modal.present();
  //   this.recommendedAccommodation = this.recommendationsService.getRecommendedAccommodation(this.activitiesList)
  //     // Handle the situation where there are multiple recommendations due to a tie
  // if (this.recommendedAccommodation.includes(',')) {
  //   // Split the recommendations into an array if there's more than one
  //   this.recommendations = this.recommendedAccommodation.split(', ');
  //   console.log(this.recommendations)
  //   // Additional logic to handle multiple recommendations
  // }
  }

  public toggleActivity(activity, event) {
    console.log(activity, event)
    const isChecked = event.detail.checked;
    console.log(isChecked)
    console.log(this.selectedCategory)
    console.log(this.selectedActivity)
    const userIndex = activity.usersVotedYes.findIndex(user => user.userId === this.myUserId);
    this.isVoted = true;
    this.isDataLoaded = true;
console.log('yallo')    // If the checkbox is checked and the user hasn't voted, add their vote
    if (isChecked && userIndex === -1) {
      activity.usersVotedYes.push({
        profilePicture: this.profilePicture,
        userId: this.myUserId
      });
      // Call API to add vote
    //  if(activity.activityBelongsToCategoryId !== '657daacfcd3d51e1e44e4c42' || !isChecked){
        this.apiService.updateActivityWithUserSelection(activity._id, this.tripDetails.uuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          // Handle response
          console.log(res)
          this.isDataLoaded = true;
        });
   // }
      }

    
    // If the checkbox is unchecked and the user has voted, remove their vote
    if (!isChecked && userIndex > -1) {
      activity.usersVotedYes.splice(userIndex, 1);
      // Call API to remove vote
      this.apiService.removeUserFromTripActivity(activity._id, this.tripDetails.uuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          // Handle response
          console.log(res)
          this.activitiesList.forEach((activity: any) => {
            console.log('activity ', activity);
        
            // Find a matching result based on the _id
            // const matchingResult = res.findActivityAndUpdate.find((result: any) => result._id === activity._id);
            
            if(res.updatedActivity._id === activity._id){
              const matchingResult = res.updatedActivity
              activity.usersVotedYes = matchingResult.usersVotedYes.filter((user: any) => user.tripId === this.tripDetails.uuid);
              this.isVoted = activity.usersVotedYes.some((user)=> user.userId === this.myUserId)
              console.log('have i voted? ', this.isVoted)
              // this.addHighlightedDates(activity);
            }
             else {
              // If no matching result is found, ensure usersVotedYes is empty or untouched
              // depending on your requirement
               //activity.usersVotedYes = []; // Uncomment this line if you want to clear usersVotedYes when no matching result is found
            }
          });
          this.isDataLoaded = true;
        });
    }
  }
  
  // public isUserVoted(activity: any): boolean {
  //   return activity.usersVotedYes.some(user => user.userId === this.myUserId);
  // }
  
  selectActivity(event) {
    this.selectedActivity = event.detail.value;
    console.log(this.selectedActivity)
    this.activities.push(
      {
        activityId: this.selectedActivity,

      }
    )
    // Additional logic when an activity is selected
  }

  submitVotes() {
    // Filter activities that have been selected
    const selectedActivities = this.activities.filter(activity => activity.selected);
  
    // Create a payload containing only necessary data for the backend
    const votePayload = selectedActivities.map(({ id, usersVotedYes }) => ({
      activityId: id,
      // selected: usersVotedYes.includes(this.myUserId), 
      usersVotedYes: [...usersVotedYes, this.myUserId], // Include the current user's vote
    }));
  
    console.log('Vote Payload:', votePayload);
  
    // Send the vote payload to your backend for processing
    // You can use a service to send HTTP requests to your server
  
    // For example, assuming you have a voting service:
    // this.votingService.submitVotes(votePayload).subscribe(response => {
    //   console.log('Votes submitted successfully:', response);
    // });
  
    // Optionally, reset the selected state of activities after submitting
    this.activities.forEach(activity => (activity.selected = false));
  }
  
  dismissModal() {
    this.modalCtr.dismiss(); // Dismiss the modal
  }

  ngOnDestroy() {
    console.log('Destoryingggg')
    this.destroy$.next();
    this.destroy$.complete();
  }
}
