import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CityConversationPage } from '../city-conversation/city-conversation.page';
import { IonNav } from '@ionic/angular';
import { LinkedTripsPage } from '../linked-trips/linked-trips.page';

@Component({
  selector: 'app-modalwrapper',
  templateUrl: './modalwrapper.component.html',
  styleUrls: ['./modalwrapper.component.scss'],
})
export class ModalwrapperComponent implements OnInit {
  @ViewChild(IonNav) nav: IonNav;
  @Input() tripLocation: any;
  @Input() isLinkedPage: any;
  @Input() item: any;
  constructor() { }

  ngOnInit() {
    console.log(this.item)
  }

  ngAfterViewInit() {
    // Wait for the view to initialize, then push the page with params
    if(this.isLinkedPage){
      this.nav.push(LinkedTripsPage, { item: this.item });

    } else {
      this.nav.push(CityConversationPage, { tripLocation: this.tripLocation });

    }
  }

}
