import { ChangeDetectorRef, Component, Injectable, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GoogleService } from '../google.service';
import { ApiService } from '../api.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-google-map-content',
  templateUrl: './google-map-content.component.html',
  styleUrls: ['./google-map-content.component.scss'],
})
export class GoogleMapContentComponent implements OnInit, OnChanges {

  // @Input() data: any
  googleContent: any
  private modal: HTMLIonModalElement;
  public imageLoaded = false;
  coordinates: any
  private destroy$ = new Subject<void>();
  isVisible = false;
  subscription: Subscription;
  constructor(private cdr: ChangeDetectorRef, private geocodingService: GoogleService, private apiService: ApiService) { 

  }

  ngOnInit() {

    this.apiService.tripRestaurants.pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
      this.googleContent = res
    }) 
  }

//   private getNearbyRecommendations(latitude: number, longitude: number) {
//     const location = `${latitude},${longitude}`; // Format as "lat,lng"
//     const radius = 50000; // Set the desired radius
//     const includedTypes = ["restaurant"]; // Specify included types
//     const excludedTypes = ["supermarket", "department_store", "shopping_mall", "gas_station"]; // Add more types as needed
//     const maxResultCount = 20; // Set the maximum number of results

//     this.geocodingService.nearbySearch(location, radius, includedTypes, excludedTypes, maxResultCount).pipe(takeUntil(this.destroy$)).subscribe((data) => {
//         console.log(data);
//         // Assume `data.places` already includes the `photoUrls` for each place
//         this.tripRecommendations = data.filter((place) => place.businessStatus === 'OPERATIONAL');

//         console.log(this.tripRecommendations);

//         // Set the coordinates based on the updated recommendations
//         this.coordinates = this.tripRecommendations.map(recommendation => ({
//             lat: recommendation.location.latitude,
//             lng: recommendation.location.longitude,
//             name: recommendation.displayName.text
//         }));

//         this.apiService.setCoordinates(this.coordinates);
//         console.log(this.coordinates);

//         // Log the final list of recommendations
//         console.log(this.tripRecommendations);
//     });
// }

ngOnChanges(changes: SimpleChanges){
  console.log(changes)
}
  
  closeModal(){
    this.modal.setCurrentBreakpoint(0.1)
  }


  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
      this.apiService.setTripRestaurantData([])
    //  this.subscription.unsubscribe();

  }

}
