import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AnimationController, ModalController, NavController } from '@ionic/angular';
import { CityConversationPage } from '../city-conversation/city-conversation.page';
import { IonicSlides } from '@ionic/angular';
import { Subject, take, takeUntil } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Storage } from '@ionic/storage-angular'
import { shuffle } from 'lodash'; // Import the shuffle function
import { PreviewImagePage } from '../preview-image/preview-image.page';
import Swiper from 'swiper';


declare global {
  interface Window {
    Swiper: typeof Swiper;
  }
}

@Component({
  selector: 'app-city-details',
  templateUrl: './city-details.page.html',
  styleUrls: ['./city-details.page.scss'],
  
})


export class CityDetailsPage implements OnInit {
  swiperModules = [IonicSlides];

  
  @Input() location: any;
  public cityDetails: any;
  public matchingTrips = [];
  public isLoaded = false;
  public cityImages = []
  swiper: Swiper | undefined;
  @ViewChild('swiperContainer') swiperContainer!: ElementRef;

  public myUserId = this.apiService.getMyUserId();
  public viatorThingsToDo = []
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
    private router: Router, private cdr: ChangeDetectorRef,
    private navCtrl: NavController, private storage: Storage, private modalController: ModalController) {
    this.initStorage();

   }

  ngOnInit() {
    console.log('myUserId', this.myUserId)

    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras.state) {
      this.location = currentNavigation.extras.state;
      const locationForViator = this.location.split(', ')[0]
      console.log(locationForViator)
      // this.apiService.getViatorDestId(locationForViator).pipe(take(1)).subscribe((res: any) => {
      //   console.log(res);
      //   // this.viatorDestId = res;
      //   this.apiService.getViatorTagsAndProductsByDestination(res).pipe(take(1)).subscribe((res) => {
      //     console.log(res);


   this.apiService.getViatorThingsToDo(locationForViator).subscribe((res)=>{
          console.log(res)
          this.viatorThingsToDo = res.data
          const getSome = this.viatorThingsToDo.slice(0, 10)
          console.log(getSome);
          this.apiService.saveExperiencies(getSome)
   })
        //   this.storage.get('experiences').then((cachedExperiences: any[]) => {
        //     if (cachedExperiences && cachedExperiences.length > 0) {
        //         // Remove old items from cached experiences if needed
        //         const MAX_CACHED_ITEMS = 30;
        //         if (cachedExperiences.length >= MAX_CACHED_ITEMS) {
        //             cachedExperiences.splice(0, cachedExperiences.length - MAX_CACHED_ITEMS);
        //         }
    
        //         // Add a maximum of 20 new items from the new data to the cached experiences
        //         const newItemsToAdd = res.data.slice(0, 10);
        //         const updatedExperiences = [...cachedExperiences, ...newItemsToAdd];
                
        //         // Shuffle the updated experiences array
        //         const shuffledExperiences = shuffle(updatedExperiences);
                
        //         // Update the cache with the shuffled combined data
        //         this.storage.set('experiences', shuffledExperiences).then(() => {
        //             console.log('Cache updated with new data');
                    
        //             // Update the BehaviorSubject value
        //             this.apiService.experiencesSubject.next(shuffledExperiences);
        //         }).catch(error => {
        //             console.error('Error updating cache:', error);
        //         });
        //     } else {
        //         // If no cached experiences exist, just set the new data in the cache
        //         this.storage.set('experiences', res.data).then(() => {
        //             console.log('New data cached');
                    
        //             // Update the BehaviorSubject value
        //             this.apiService.experiencesSubject.next(res.data);
        //         }).catch(error => {
        //             console.error('Error caching data:', error);
        //         });
        //     }
        // }).catch(error => {
        //     console.error('Error retrieving cached experiences:', error);
        // });
      //   });
      // });


    //   this.apiService.getViatorThingsToDo(locationForViator).pipe(takeUntil(this.destroy$)).subscribe((res) => {
    //     console.log(res);
    //     // this.viatorThingsToDo = res.data;
    //     // Retrieve existing cached experiences
    
    // });
    
      
      
      console.log(this.location)
      this.apiService.getCountryCityImages(this.location).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        console.log(res)
        this.cityImages = res
        this.cdr.detectChanges()
      })
    }
    this.apiService.getCityDetails(this.location).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
      this.cityDetails = res.cityDetails
      if(res.matchingTrips){
        this.matchingTrips = [...this.matchingTrips, ...res.matchingTrips]

      }
      this.isLoaded = true
      console.log(this.matchingTrips)
    })
  //   this.isLoaded = true;
  //   this.cityDetails = {
  //     "description": "Moscow, the capital of Russia, is known for its historic architecture, vibrant culture, and rich history. From iconic landmarks like the Red Square and Kremlin to world-class museums like the Pushkin State Museum of Fine Arts, there is no shortage of things to do in Moscow.",
  //     "things_to_do": [
  //         "Visit the Red Square and see St. Basil's Cathedral",
  //         "Explore the Kremlin and its museums",
  //         "Walk along the Arbat Street for shopping and dining",
  //         "Take a boat cruise along the Moskva River",
  //         "Visit the State Tretyakov Gallery for Russian art",
  //         "Watch a ballet performance at the Bolshoi Theatre",
  //         "Experience the vibrant nightlife at clubs and bars",
  //         "Visit the Moscow Metro for its stunning architecture",
  //         "Take a day trip to Sergiev Posad to see the Trinity Lavra of St. Sergius"
  //     ]
  // }
  }

  async previewImages(clickedImage: any) {
    const initialSlide = this.cityImages.findIndex(image => image === clickedImage);
    const modal = await this.modalController.create({
      component: PreviewImagePage,

      componentProps: {
        images: this.cityImages,
        initialSlide: initialSlide
      }
    });
  
    modal.onDidDismiss()
      .then((data) => {
        console.log(data)
        if (data.data?.initialSlide !== undefined) {
          // Update the swiper to the new initialSlide
          this.slideToNewIndex(data.data.initialSlide);
        }
      });
  
    return await modal.present();
  }
  
  slideToNewIndex(newIndex: number) {
    if (this.swiper) {
      this.swiper.slideTo(newIndex)
    }
  }
    


  async openWebpage(url: string, event: Event) {
    event.stopPropagation();
    console.log(url)
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }

  createATrip(){
   
    this.router.navigate(['contacts', this.myUserId, this.location]);
  }

  goBack() {
    this.navCtrl.back();
  }

  async initStorage() {
    // Initialize Ionic Storage
    await this.storage.create();
  }

  // async openConversation(){
  //   const modal = await this.modalController.create({
  //     component: CityConversationPage,
  //     breakpoints: [0, 0.3, 0.5, 1],
  //     initialBreakpoint: 0.5,
  //     componentProps: {

  //     },
  //   });
  //   modal.present();
  // }
  
}
