import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ModalController } from '@ionic/angular';
// import { TripdetailsComponent } from '../tripdetails/tripdetails.component';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { SocketService } from '../socket.service';
import { HistoryPage } from '../history/history.page';
import { Router } from '@angular/router';
import { TripData } from 'interfaces/trip-interface';
import { TabsServiceService } from '../tabs-service.service';

@Component({
  selector: 'app-tab2',
  templateUrl: './tab2.page.html',
  styleUrls: ['./tab2.page.scss'],
   //changeDetection: ChangeDetectionStrategy.OnPush,

})
export class Tab2Page implements OnInit, OnDestroy {
  public myTripData: any = []
  public isLoaded = false
  public myInvitedtripData = [];
  public highlightedDates = [];
  private allTripsOwnedByMe: any;
  private destroy$: Subject<void> = new Subject<void>();
  public selectedTab: string = 'my-trips'; // Initialize with the default tab
  public notificationNumber: any
  public updatedSocketTripData: any;
  public tripUUIDS: any;
  public tripsIAmInvitedToNotification = false;
  public myTripsNotification = false;
  public allTrips = []
  public myUserId = this.apiService.getMyUserId();
  public updatedTripDataAfterSavingDetails: any;
  memoizedNotifications: { [tripId: string]: number } = {};
  notificationNumbers: number[] = [];
  public tripStartAndEnd = ""

  constructor(private router: Router, private cdr:ChangeDetectorRef ,private apiService: ApiService, private socketService: SocketService ,private modalController: ModalController) { }

  ngOnInit() {
    console.log("hello...")
    console.log('hi again')

    this.socketService.joinNotificationZone(this.myUserId)
    this.socketService.receiveTaggedNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res)
      console.log(this.allTripsOwnedByMe)
      this.getMyNotifications(this.allTripsOwnedByMe )
        // const notificationData = {
        //   updatedValue: res.count,
        //   tripId: res.tripId
        // };
        // this.apiService.updateNotificationNumber(notificationData)

        // Update notification data or perform other actions
        // console.log('Notification data updated:', notificationData);
  
      this.apiService.presentToast('taggedUser', res.tripName)
    })
    // const isAuth = this.apiService.authAndRoute()
    // console.log(isAuth)
    // this.apiService.notificationNumber$.subscribe((notificationCount)=>{
    //   const notificationData = {
    //     updatedValue: notificationCount.updatedValue,
    //     tripId: notificationCount.tripId
    //   }

    //   console.log(notificationData)
    //   this.notificationNumber = notificationCount
    // })
    //  this.getLatestData()
    // this.socketService.onUserEnteredTripList().subscribe((data) => {
    //   console.log(data);
    //   this.updatedSocketTripData = data?.updatedTrip;
     
    //   // Find and update the corresponding trip within myTripsData
    //   console.log(this.myTripData)
    //   console.log(this.updatedSocketTripData)
    //   this.myTripData = this.myTripData.map((trip) => {

    //     if (trip?.uuid === this.updatedSocketTripData.uuid) {
    //       // Update the trip with new data
    //       console.log('made it here...')
    //       this.apiService.setSingleTripData(this.updatedSocketTripData)
    //       return this.updatedSocketTripData;
    //     }
    //     return trip;
    //   });
    //   this.cdr.detectChanges();
    //   console.log(this.myTripData)
    //   this.apiService.setTripData(this.myTripData)
    //   // Now myTripsData should be updated, and you can use it in your component.
    // });

    // this.apiService.tripData.subscribe((data)=>{
    //   console.log(data)
    //   this.myTripData = data
    // })
    // this.apiService.notificationNumber$.subscribe((value) => {
    //   console.log(value)
    //   this.notificationNumber = value;
    // });

    this.socketService.onTripUpdated().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      // Handle the updated trip data, e.g., update your UI
      console.log('Received updated trip:', res);
      const notificationData = {
        updatedValue: res.count,
        tripId: res.updatedTrip.uuid

      }
      this.apiService.updateNotificationNumber(notificationData );
      // this.apiService.setSingleTripData(updatedTrip)
      // Update your component's data with the received data
      // this.tripDetails = updatedTrip;
      this.getLatestDataForMyTrips()
      this.getLatestDataForTripsIAmInvitedTo()
      this.cdr.detectChanges()
    });
    this.socketService.onUserAddedToTrip().subscribe((res)=>{
      console.log(res)
      this.getLatestDataForMyTrips()
      this.getLatestDataForTripsIAmInvitedTo()
      this.cdr.detectChanges()
    this.apiService.presentToast('notifyUserBeenAddedToTrip', res.updatedTrip.tripName)
    })
   
  }
  

  ionViewWillEnter() {
    this.cdr.detectChanges()
    console.log('hi again')
    this.apiService.notificationNumber$.subscribe((notificationCount)=>{
      console.log(notificationCount)
      this.notificationNumber = notificationCount
    })
    this.apiService.getDataForASingleTrip$.subscribe((data)=>{
      console.log(data)
      // this.getLatestDataForMyTrips()
      this.getLatestDataForTripsIAmInvitedTo()
    })
     this.getLatestDataForMyTrips()
    this.getLatestDataForTripsIAmInvitedTo()
    this.socketService.onUserEnteredTripList().subscribe((data) => {
      console.log(data);
      this.updatedSocketTripData = data?.updatedTrip;
     
      // Find and update the corresponding trip within myTripsData
      console.log(this.myTripData)
      console.log(this.updatedSocketTripData)
      this.myTripData = this.myTripData.map((trip) => {

        if (trip?.uuid === this.updatedSocketTripData.uuid) {
          // Update the trip with new data
          console.log('made it here...')
          this.apiService.setSingleTripData(this.updatedSocketTripData)
          // this.getNotificationNumber(this.updatedSocketTripData)
          return this.updatedSocketTripData;
        }
        return trip;
      });

      this.cdr.detectChanges();
      console.log(this.myTripData)

      // this.apiService.updateNotificationNumber(this.notificationNumber)

    });

    this.apiService.allPublicTripsArray.subscribe((res)=>{
      console.log(res)
      //  this.publicTrips = res
      //  this.hasTripsLoaded = true;
      //  console.log('hasLoaded', this.hasTripsLoaded )
    })

  }

  
  public segmentChanged() {
    // You can access the selectedTab value here every time the segment changes
    console.log('Selected Tab:', this.selectedTab);
    this.isLoaded = false;
    // Perform actions based on the selected tab value
    if (this.selectedTab === 'my-trips') {
      // Do something for "My Trips" tab
      this.getLatestDataForMyTrips()
    } else if (this.selectedTab === 'invited-trips') {
      // Do something for "Trips I'm Invited To" tab
      this.getLatestDataForTripsIAmInvitedTo()
    }
    this.cdr.detectChanges()
  }

  formatTripDates(tripdates) {
    if (tripdates && tripdates.length > 0) {
      const tripStart = (tripdates[0].date);
      const tripEnd = (tripdates[tripdates.length - 1].date);
  
      if (tripdates.length > 1) {
        return tripStart + ' - ' + tripEnd;
      } else {
        return tripStart;
      }
    } else {
      return '';
    }
  }
  

  public getLatestDataForMyTrips() {

    this.apiService.notificationNumber$.subscribe((notificationCount) => {
      const notificationData = {
        updatedValue: notificationCount?.updatedValue,
        tripId: notificationCount?.tripId,
      };
    
    this.apiService.getUserAuthenticationData().pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.apiService.getAllMyTrips().pipe(takeUntil(this.destroy$)).subscribe((response)=>{
        console.log(response)
        this.allTripsOwnedByMe = response.trips;
        // this.formatTripDates(this.allTripsOwnedByMe.tripDates)
        this.getMyNotifications(this.allTripsOwnedByMe )
      
        // this.displayNotificationForTripTab(this.allTripsOwnedByMe)
        console.log(this.allTripsOwnedByMe)
        // this.displayNotificationForTripTab(this.allTripsOwnedByMe)
          // Find the index of the trip with a matching tripId
          const tripIndex = this.allTripsOwnedByMe.findIndex((trip) => trip.uuid === notificationData.tripId);
        
          if (tripIndex !== -1) {
            // If a matching trip is found, update the count
            this.allTripsOwnedByMe[tripIndex].count = notificationData.updatedValue;
            console.log(this.allTripsOwnedByMe)
            this.isLoaded = true;
          }
       
        });
        
         this.tripUUIDS = this.myTripData.map((trip)=> trip.uuid);
          this.socketService.joinTripAllMyRooms(this.tripUUIDS)

      })
        this.cdr.detectChanges()

      });

  }

  public getLatestDataForTripsIAmInvitedTo(){
    this.apiService.notificationNumber$.subscribe((notificationCount) => {
      const notificationData = {
        updatedValue: notificationCount?.updatedValue,
        tripId: notificationCount?.tripId,
      };
    this.apiService.getUserAuthenticationData().pipe(takeUntil(this.destroy$)).subscribe((user) => {

      this.apiService.getLatestDataForTripsIAmInvitedTo().subscribe((data) => {
        console.log(data)
        this.myInvitedtripData = data.matchingTrips;
        // this.getMyNotifications(this.myInvitedtripData )
        // this.displayNotificationForTripTab(this.myInvitedtripData)
        console.log(this.myInvitedtripData)
        this.apiService.setTripData(this.myInvitedtripData)
        this.tripUUIDS = this.myInvitedtripData.map((trip)=> trip.uuid);
        this.socketService.joinTripAllMyRooms(this.tripUUIDS)

        const tripIndex = this.myInvitedtripData.findIndex((trip) => trip.uuid === notificationData.tripId);
        
        if (tripIndex !== -1) {
          // If a matching trip is found, update the count
          this.myInvitedtripData[tripIndex].count = notificationData.updatedValue;
          console.log(this.myInvitedtripData)
          // this.isLoaded = true;
        
        }
        this.cdr.detectChanges()
      })

      })
    });
  }




  
  public getMyNotifications(array){
    this.apiService.getMyNotifications().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      console.log(res);
      res.forEach(resItem => {
        // Find the corresponding trip in 'allTripsOwnerByMe' with matching 'uuid'
        const matchingTrip = array.find(trip => trip.uuid === resItem.tripUUID);
      
        // Update the count property in the matching trip
        if (matchingTrip) {
          matchingTrip.count = resItem.count;
          console.log(matchingTrip);
          this.displayNotificationForTripTab(array);
        } 
      });
  
      console.log(array);
      this.isLoaded = true; // Set isLoaded to true after processing all items
      console.log('isLoaded', this.isLoaded)
    });
  }
  
  
  
  public displayNotificationForTripTab(array){


   const tripsInvitedTo = array.filter((trip)=> trip.tripOwner.ownerId !== this.apiService.getMyUserId())
   console.log(tripsInvitedTo)
   const myTrips = array.filter((trip)=> trip.tripOwner.ownerId === this.apiService.getMyUserId())
   this.apiService.tripsIAmInvitedToNotification = tripsInvitedTo.some((trip)=> trip.count > 0)
   this.apiService.myTripsNotification = myTrips.some((trip)=> trip.count > 0)
   console.log( this.apiService.tripsIAmInvitedToNotification)
   console.log( this.apiService.myTripsNotification)
  }
  
  
  
  public async openTripDetails(trip) {
    console.log("clicking...")
    const tripData: any = {
      tripDetails: trip,
      tripId: trip.uuid,
      // myUserId: this.allTripsOwnerByMe.id,
      selectedTab: this.selectedTab,
      // myEmail: this.allTripsOwnerByMe.email,
      // myNumber: this.allTripsOwnerByMe.phoneNumber,
      // myUserData: this.allTripsOwnerByMe,
    };
    this.router.navigate(['tabs/tab2/trip-page',trip.uuid])
    this.apiService.setTabs2TripData(tripData);
    
  }
  
  
  

  // public async openTripDetails(trip) {
  //   console.log(trip)
  //   const modal = await this.modalController.create({
  //     component: TripdetailsComponent, // Replace with your actual modal component
  //     componentProps: {
  //       // Pass any data you need to the modal
  //       tripDetails: trip,
  //       myUserId: this.myUserData.id,
  //       selectedTab: this.selectedTab,
  //       myEmail: this.myUserData.email,
  //       myNumber: this.myUserData.phoneNumber,
  //       myUserData: this.myUserData,
  //     },
  //   });

  //   modal.onDidDismiss().then((dataReturned) => {
  //     console.log(dataReturned)
  //     if (dataReturned !== null) {
  //       // Data was returned from the modal
  //       const returnedData = dataReturned.data;
  //       console.log("Data returned from modal:", returnedData);
  //       this.getLatestData()
  //       // You can handle the returned data here
  //       // For example, you can update your UI or perform other actions
  //     }
  //   });

  //   await modal.present();
  // }
  ngOnDestroy() {
  }

  ionViewDidLeave() {
    this.destroy$.next();
    this.destroy$.complete();
    this.socketService.leaveTabs2(this.tripUUIDS)
    this.isLoaded = false;
    console.log('leaving')
  }
}
