import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController, NavController } from '@ionic/angular';
import { ApiService } from '../api.service';
import { CreateTopicPage } from '../create-topic/create-topic.page';
import { ConversationDetailsPage } from '../conversation-details/conversation-details.page';

@Component({
  selector: 'app-city-conversation',
  templateUrl: './city-conversation.page.html',
  styleUrls: ['./city-conversation.page.scss'],
})
export class CityConversationPage implements OnInit,AfterViewInit {
  @Input() tripLocation: string;
  public comments = []; // This should be populated with actual comment data
  public topics = []
  yourImageUrl = 'path/to/your/profile/image'; // Replace with your profile image URL
  public commentText: string = ''; // Property to hold the input text

  constructor(private modalCtrl: ModalController, private apiService: ApiService, private nav: IonNav) { }

  ngOnInit() {
    console.log(this.tripLocation)

    this.apiService.getCountryForums(this.tripLocation).subscribe((res)=>{
      console.log(res)
      this.topics = res.forums.forumPost
    })

    this.apiService.currentForumList.subscribe((res: any)=>{
      console.log(res)
      this.topics = res?.updatedCountryPost?.forumPost
    })
    // this.apiService.getCountryComment(this.tripLocation).subscribe((res)=>{
    //   console.log(res)
    //   this.comments = res.countryComments
    // })
  }
  navigateToCreateTopic() {
    console.log("Nav to create topic")
    this.nav.push(CreateTopicPage, { tripLocation: this.tripLocation });
  }

  navigateToForumPageWithDetails(topic) {
    console.log(topic)
    const topicId = topic.id
    console.log(topicId)
    this.nav.push(ConversationDetailsPage, { tripLocation: this.tripLocation, topicId: topicId, topic });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.apiService.toggleFooterOverlay(true);
    // });
  }
  public cancel() {
    return this.modalCtrl.dismiss('cancel');
  }

  postForumTopic() {
    const commentData = { text: this.commentText };
    this.apiService.postCountryQuestion(this.tripLocation ,commentData).subscribe((res: any)=>{
      console.log(res)
      // this.apiService.updateForumList(res)
       this.comments = res.countryComment.countryComment
    })

  }

  postComment() {
    const commentData = { text: this.commentText };
    this.apiService.postCountryComment(this.tripLocation ,commentData).subscribe((res: any)=>{
      console.log(res)
       this.comments = res.countryComment.countryComment
    })

  }
}
