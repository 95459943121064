export const contacts = [
    {
      "contactId": "177C371E-701D-42F8-A03B-C61CA31627F6",
      "name": {
        "given": "Kate",
        "suffix": "",
        "middle": "",
        "prefix": "",
        "display": "Kate Bell",
        "family": "Bell"
      },
      "phones": [
        { "number": "(555) 564-8583", "type": "mobile" },
        { "number": "(415) 555-3695", "type": "main" }
      ]
    },
    {
      "phones": [
        { "number": "+14044057230", "type": "home" },
        { "number": "(408) 555-5270", "type": "mobile" },
        { "number": "(408) 555-3514", "type": "fax_home" }
      ],
      "contactId": "AB211C5F-9EC9-429F-9466-B9382FF61035",
      "name": {
        "middle": "",
        "suffix": "Jr.",
        "family": "Higgins",
        "display": "Daniel Higgins Jr.",
        "prefix": "",
        "given": "Daniel"
      }
    },
    {
      "phones": [
        { "number": "888-555-5512", "type": "mobile" },
        { "number": "888-555-1212", "type": "home" }
      ],
      "contactId": "410FE041-5C4E-48DA-B4DE-04C15EA3DBAC",
      "name": {
        "given": "John",
        "family": "Appleseed",
        "suffix": "",
        "prefix": "",
        "middle": "",
        "display": "John Appleseed"
      }
    },
    {
      "phones": [{ "type": "home", "number": "555-522-8243" }],
      "contactId": "F57C8277-585D-4327-88A6-B5689FF69DFE",
      "name": {
        "given": "Anna",
        "display": "Anna Haro",
        "middle": "",
        "family": "Haro",
        "suffix": "",
        "prefix": ""
      }
    },
    {
      "phones": [
        { "number": "(555) 766-4823", "type": "work" },
        { "number": "(707) 555-1854", "type": "other" }
      ],
      "contactId": "2E73EE73-C03F-4D5F-B1E8-44E85A70F170",
      "name": {
        "given": "Hank",
        "family": "Zakroff",
        "middle": "M.",
        "display": "Hank M. Zakroff",
        "suffix": "",
        "prefix": ""
      }
    }
]