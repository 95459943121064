import { CapacitorConfig } from '@capacitor/cli';
import { KeyboardResize, KeyboardStyle } from '@capacitor/keyboard';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';



const config: CapacitorConfig = {
  appId: 'io.ionic.travel.1',
  appName: 'TravellingBug',
  webDir: 'www',
  plugins: {
    PushNotifications: {
      presentationOptions: ["badge", "sound", "alert"],
    },
    Keyboard: {
      resize: KeyboardResize.Body,
      style: KeyboardStyle.Default,
      resizeOnFullScreen: true,
    },
    SplashScreen: {
      launchShowDuration: 0,
      launchAutoHide: true,
    },
    cordova: {
      preferences: {
        LottieFullScreen: "true",
        LottieHideAfterAnimationEnd: "true",
        LottieAnimationLocation: "public/assets/animations/splashlong.json",

      }
    }
  },
};

// // src/app/app.config.ts

// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideLottieOptions({ player: () => player }),
//     provideCacheableAnimationLoader(),
//   ]
// };

export default config;
