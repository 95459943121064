import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GoogleService } from '../google.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-recommendations-modal',
  templateUrl: './recommendations-modal.component.html',
  styleUrls: ['./recommendations-modal.component.scss'],
})
export class RecommendationsModalComponent implements OnInit {

  @Input() recommendation?: any
  @Input() location?: any
  public placeDetails: any;
  public pulledPhotos: any
  public iframeUrl: string;
  stars: number[] = [];

  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private googleApi: GoogleService) { }

  ngOnInit() {
    console.log(this.recommendation)



    // // Process the name
    // const nameParts = this.recommendation.name.split(' ');
    // const processedName = nameParts.map((word, index) => (index < nameParts.length - 1 ? word + '+' : word)).join('');
    // // Process the vicinity
    // // const vicinityParts = this.recommendation.vicinity.split(' ');
    // // const processedVicinity = vicinityParts.map((word, index) => (index < vicinityParts.length - 1 ? word + '+' : word)).join('');

    // // Construct the query string for the iframe
    // const queryString = `&q=${processedName},${this.location}`;
    // console.log(queryString)
    // // Use `queryString` when building your iframe URL
    // this.iframeUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDZH5H2abm7wHjn49IltO3Q9WZvkWEb8uY${queryString}`;
    // console.log(this.iframeUrl)
    this.googleApi.getSpecificPlaceDetails(this.recommendation?.place_id).subscribe((data) => {
      this.placeDetails = data;
      console.log(data)
      this.stars = this.placeDetails?.result?.rating >= 0 ? new Array(Math.floor(this.placeDetails.result.rating)) : [];
      console.log('stars', this.stars)
    
      if (this.placeDetails.result.photos && this.placeDetails.result.photos.length > 0) {
        const photoPromises: Observable<Blob>[] = [];
    
        this.placeDetails.result.photos.forEach((photo) => {
          const photoReference = photo.photo_reference;
          const photoObservable = this.googleApi.getPlacePhoto(photoReference);
          photoPromises.push(photoObservable);
        });
    
        // Combine all the photo observables into a single observable
        forkJoin(photoPromises).subscribe((photoBlobs: Blob[]) => {
          // Here, you have an array of photo blobs
          // You can convert each blob to a URL and use them in your Angular template
          const imageUrls = photoBlobs.map((blob) => URL.createObjectURL(blob));
          this.pulledPhotos = imageUrls
          // Now, imageUrls contains URLs for all the retrieved images
          console.log('Image URLs:', this.placeDetails);
        });
      }
    });
    
  }    
  getStarRating(rating: number): { fullStars: number; halfStar: boolean } {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    return { fullStars, halfStar: hasHalfStar };
  }
  
  
  

  // sanitizeUrl(url: string): SafeResourceUrl {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }
}
