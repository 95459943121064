import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecommendationsService {

  constructor() { }

  getRecommendedAccommodation(accommodationOptions) {
    let maxVotes = 0;
    let tiedOptions = [];

    // Iterate over each accommodation option to find the one(s) with the most votes
    accommodationOptions.forEach(option => {
      const voteCount = option.usersVotedYes.length;
      if (voteCount > maxVotes) {
        maxVotes = voteCount;
        tiedOptions = [option];
      } else if (voteCount === maxVotes) {
        tiedOptions.push(option);
      }
    });

    // Handle tie scenario
    if (tiedOptions.length > 1) {
      // Option 1: Randomly pick one
      // return tiedOptions[Math.floor(Math.random() * tiedOptions.length)].activityName;

      // Option 2: Return all tied options to let the user decide
      console.log(tiedOptions)
      return tiedOptions.map(opt => opt.activityName).join(', ');

      // Additional tie-breaking strategies can be implemented here
    }
    console.log(tiedOptions[0].activityName)
    // If we have a single recommendation, return the activityName
    return tiedOptions.length === 1 ? tiedOptions[0].activityName : 'No recommendation';
  }
}
