import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SocketService } from '../socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { ActionSheetController, IonContent, IonInput, ModalController, PopoverController } from '@ionic/angular';
import { match } from 'assert';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { ReplyMessagePopoverPage } from '../reply-message-popover/reply-message-popover.page';
import { Keyboard } from '@capacitor/keyboard';
import { DatePipe } from '@angular/common';
import {Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {

  tripId: string = ''; // Replace with the actual Trip ID
  user: string = 'John Doe'; // Replace with the actual user
  message: string = '';
  usersTaggedId: string[] = [];
  public isLoaded = false;
  public isChatLoaded = false;
  chatMessages: any[] = [];
  public myUserData: any;
  public chatMessagesForm: FormGroup
  public myUserId = this.apiService.getMyUserId();
  private destroy$ = new Subject<void>();
  public imageLoading = false;
  public tripInvitees = [];
  isReplying: boolean = false;
  isFromReply: boolean = false;
  public replyMessageObject = '';
  lastInput: string = ''
  public emptyChatMessage = '';
  todayBannerShown = false;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('messageInput', {static: false}) messageInput!: IonInput;

  constructor(private renderer: Renderer2,private datePipe: DatePipe,
    private popoverController: PopoverController,private socketService: SocketService,
    private cdr: ChangeDetectorRef,private actionSheetController: ActionSheetController, 
    private chatService: SocketService,  private route: ActivatedRoute, 
    private apiService: ApiService) {}

  
  ngOnInit() {


    if(Capacitor.isNativePlatform()){
      Keyboard.addListener('keyboardDidShow', info => {
        console.log("Yes the keyboard is open...")
        // this.isReplying = true;
        this.cdr.detectChanges()
      });
  
      Keyboard.addListener('keyboardDidHide', () => {
        console.log("Keyboard Closed");
        this.isReplying = false;
        this.cdr.detectChanges()
      });
    }


    this.apiService.reply$.subscribe((reply) => {
      this.handleReply(reply);
      // this.messageInput.setFocus();
    });
    
    
    this.apiService.myUserData.pipe(takeUntil(this.destroy$)).subscribe((data: any)=>{
      console.log(data)
      this.myUserData = data;
      this.myUserId = data.id;
  
    // this.apiService.myUserId.pipe(takeUntil(this.destroy$)).subscribe((userId) => {
    //   this.myUserId = userId;
    //   console.log(this.myUserId);
  
      this.route.params.subscribe((params) => {
        console.log(params.tripUuid);
        this.tripId = params.tripUuid;
        this.socketService.joinTripRoom(this.tripId)

        this.apiService.getTripInvitees(this.tripId).subscribe((res)=>{
          console.log(res)
          const tripOwner = {
            _id : res.owner.ownerId,
            name: res.owner.ownerName,
            profilePicture: res.owner.ownerProfilePicture,
            isInviteAccepted: true
          }
          res.inviteesWithUserData.push(tripOwner)
        
  
        this.tripInvitees = res.inviteesWithUserData.filter((user)=> (user.isInviteAccepted && user._id !== this.myUserId))
        console.log('tripInvitees: ', this.tripInvitees)

        })
      });
        this.apiService.getAllTripMessages(this.tripId).pipe(takeUntil(this.destroy$)).subscribe((data) => {
          console.log(data);
          this.chatMessages = data.tripMessages;

          console.log(this.chatMessages);
        });
    //  });
      
        })
  
      // Receive chat messages from the server
      console.log(this.imageLoading)
      
      this.chatService.receiveMessages().pipe(takeUntil(this.destroy$)).subscribe((data) => {
        console.log(data)
        if(data.notificationType === 'chatImage'){
          this.imageLoading = false
        }
        this.chatMessages.push(data);
        console.log(this.chatMessages);
        setTimeout(() => {
          this.content.scrollToBottom();
          this.isChatLoaded = true;
          console.log('chatLoaded')
          // only show/keep keyboard open for user who sent message
          if(data.userSentMessage.userSentMessageId === this.myUserId){
            this.messageInput?.setFocus()
          }
        
        }, 20); // Adjust the delay as needed
      });

      this.socketService
      .onTripUpdated().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        console.log(res)
        this.tripInvitees = res.invitees;
      })
    
  
  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log('messageInput:', this.messageInput);
      this.messageInput?.setFocus();
      
    }, 0)
  }

  cancelReply(){
    console.log('cancelling reply..')
    this.messageInput?.setFocus()
   this.isReplying = false;
   this.cdr.detectChanges()
  }

  handleReply(message: string) {
    console.log('Reply received in chatPage here:', message);
    // Add your logic to handle the reply in chatPage
    this.messageInput?.setFocus()
  }

  ionViewDidEnter(){
    setTimeout(() => {
      this.content.scrollToBottom();
     
      console.log(this.isChatLoaded)
    }, 100); // Adjust the delay as needed
      
      this.isChatLoaded = true
    
    this.socketService.leaveNotificationZone(this.apiService.getMyUserId())
  }

  handleFileInput(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      // Handle the selected files, e.g., upload or display them
      console.log(files);
    }
  }

  onInput(event: any) {
    Keyboard.addListener('keyboardDidShow', info => {
      this.content.getScrollElement().then((scrollElement) => {
        const scrollHeight = scrollElement.scrollHeight;
        this.content.scrollToPoint(0, scrollHeight, 300); // 500 milliseconds duration with 'ease-out' easing
      });
      console.log('keyboard did show with height:', info.keyboardHeight);
    });



    window.addEventListener('keyboardDidLeave', function() {
      
      this.content.scrollToPoint(0, 0, 500);
      console.log("Keyboarding things")
    });
    const inputText: string = event.target.value || '';
    const lastCharacter: string = inputText.charAt(inputText.length - 1);
  
    // Check if the "@" symbol is the last character entered
    if (lastCharacter === '@' && this.lastInput !== lastCharacter) {
      this.presentInviteesActionSheet();
    }
  
    // Update the last input
    this.lastInput = inputText;
  }
  async presentInviteesActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.tripInvitees.length === 0 ? '😩 no travel bugs to tag' : 'Tag Users',
      cssClass: 'my-custom-action-sheet',
      buttons: this.tripInvitees.map((user) => ({
        text: `${user.name}`,
        handler: () => {
          this.message += `${user.name} `;
          this.usersTaggedId.push(user._id)

        },
      })),
    });

    await actionSheet.present();
  }

  
  sendMessage() {
  console.log('tagged user ', this.usersTaggedId)    
    // Send chat message to the server
    if(this.isReplying){
        this.chatService.sendMessage(this.tripId, this.myUserData, this.message, this.replyMessageObject);
    } else {
      this.chatService.sendMessage(this.tripId, this.myUserData, this.message);
    }
   
    // this.saveNotificationToTaggedUser()
    console.log(this.usersTaggedId)
    console.log(this.myUserData.id)
    
    this.socketService.notifyTaggedUser(this.usersTaggedId, this.myUserData.id, this.tripId)
    // this.socketService.notifyTaggedUser(this.userTaggedId)
    // Notify mentioned users
    
    setTimeout(() => {
      this.content.scrollToBottom();
    }, 100); // Adjust the delay as needed
    // Clear the input field after sending the message
    this.message = '';
    this.messageInput.setFocus()
    this.isReplying = false
    this.usersTaggedId = [];
  }
  // public saveNotificationToTaggedUser(){
  //   this.apiService.notifyTaggedUser(this.userTaggedId, this.myUserData.id).subscribe((res)=>{
  //     console.log(res)
    
  //   })
  // }


  
  scrollToMessage(msgId: string): void {
    console.log(msgId);
    const element = document.getElementById(msgId);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
  
  
  getFormattedDate(date: Date): string {
    // Use DatePipe to format the date
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  async readFileAndUpload(dataUrl: string) {
    try {
      // Generate a unique file name using a timestamp
      const fileName = `photo_${Date.now()}.jpg`;
  
      // Save the Data URL as a file
      const savedFile = await Filesystem.writeFile({
        path: fileName,
        data: dataUrl,
        directory: Directory.Data,
      });
  
      // Read the saved file data
      const fileData = await Filesystem.readFile({
        path: savedFile.uri,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
  
      // Now you can use `fileData` to display the image or upload it
      console.log(fileData.data)
      this.imageLoading = true
      console.log(this.imageLoading)
      this.uploadChatImage(fileData.data);
    } catch (error) {
      console.error('Error reading file:', error);
    }
  }

  uploadChatImage(event: any) {
    if (typeof event === 'string') {
      // Case for a picture taken with the camera
      const dataUrl = event;
      const dataBlob = this.dataURLToBlob(dataUrl);
  
      const formData = new FormData();
      formData.append('profilePicture', dataBlob, 'profile_picture.jpg');
      // Send the image to the server using HttpClient
      this.imageLoading = true;
      console.log(this.imageLoading)
      this.apiService.uploadChatImage(formData, this.tripId).subscribe((res: any) => {
        console.log(res);
        this.chatMessages.push(res.newChatMessage)
        
        if(res){
          this.apiService.presentToast('uploadPicture')
        }

       
        console.log(this.imageLoading)
        this.cdr.detectChanges(); // Manually trigger change detection
      });
    } else if (event && event.target.files && event.target.files[0]) {
      // Case for uploading a file
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('profilePicture', file);
      
      // Send the image to the server using HttpClient
      this.imageLoading = true;
      console.log(this.imageLoading)
      this.apiService.uploadChatImage(formData, this.tripId).subscribe((res: any) => {
        console.log(res);
        this.chatMessages.push(res.newChatMessage)
        if(res){
          this.apiService.presentToast('picture')
        }
        this.imageLoading = false;
        this.cdr.detectChanges(); // Manually trigger change detection
      });
    }
  }

  dataURLToBlob(dataURL: string) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose an option',
      buttons: [
        {
          text: 'Take Picture',
          icon: 'camera',
          handler: () => {
            // this.takePicture();
          }
        },
        {
          text: 'Choose a Picture',
          icon: 'image',
          handler: () => {

           this.fileInput.nativeElement.click();
          }
        },
        {
          text: 'Remove profile picture',
          icon: 'trash',
          handler: () => {
          //  this.deletePicture()
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
  
    await actionSheet.present();
  }

  onMouseDown(event: MouseEvent, msg: any, isFromReply = false) {
    this.handleLongPress(event, msg, isFromReply);
  }

  onTouchStart(event: TouchEvent, msg: any, isFromReply = false) {
    this.handleLongPress(event, msg, isFromReply);
  }

  private handleLongPress(event: Event, msg: any, isFromReply = false) {
    console.log(event)
    console.log(msg)  
    console.log(isFromReply)
    if(isFromReply){
      this.isFromReply = true
      this.isReplying = true
      // this.replyMessageObject = msg.userWhoRepliedMessage
      this.message = msg.userWhoRepliedMessage.originalMessage
      console.log(this.message)
    } else {
      this.replyMessageObject = msg
      console.log(this.replyMessageObject)
    }

  
    // Detect long press and open the dialog
    const longPressDuration = 500; // Adjust as needed
    const timeout = setTimeout(async () => {
      // await this.openReplyPopover(event, msg);
      this.messageInput.setFocus()
      this.isReplying = true
    }, longPressDuration);

    // Listen for mouse up or touch end to clear the timeout
    const clearTimer = () => {
      clearTimeout(timeout);
    };

    window.addEventListener('mouseup', clearTimer);
    window.addEventListener('touchend', clearTimer);
  }

  private async openReplyPopover(event: Event, msg: any) {
    // Create and present your reply popover
    const popover = await this.popoverController.create({
      component: ReplyMessagePopoverPage,
      keyboardClose: false,
      componentProps: {
        originalMessage: msg.message,
      },
      event: event, // Pass the event for positioning
      translucent: true,
    });
    
    await popover.present();

  


    // Handle popover result if needed
    const { data } = await popover.onDidDismiss();
    console.log('Popover dismissed with result:', data);
  }
  

  ngOnDestroy(): void {
    console.log('leaving history...')
    this.socketService.leaveTripRoom(this.tripId)

    this.destroy$.next();
    this.destroy$.complete();
  }

}
