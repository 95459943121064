import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ModalController } from '@ionic/angular';
import { CityConversationPage } from '../city-conversation/city-conversation.page';
import { ModalwrapperComponent } from '../modalwrapper/modalwrapper.component';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';
import Swiper from 'swiper';

declare global {
  interface Window {
    Swiper: typeof Swiper;
  }
}

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.page.html',
  styleUrls: ['./country-details.page.scss'],
  animations: [
    trigger('placeholderAnimation', [
      state('true', style({ opacity: 1, transform: 'translateY(0%)' })),
      state('false', style({ opacity: 0, transform: 'translateY(-100%)' })),
      transition('false => true', animate('300ms ease-out')),
      transition('true => false', animate('300ms ease-in'))
    ]),
    trigger('listAnimation', [
      transition('* <=> *', [ // '* <=> *' denotes any state change
        query(':enter', [ // ':enter' is a shorthand for void => *
          style({ opacity: 0, transform: 'translateY(-15px)' }), // Initial state
          stagger('50ms', animate('350ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' }))) // Final state
        ], { optional: true }),
        query(':leave', [ // ':leave' is a shorthand for * => void
          animate('350ms ease-in', style({ opacity: 0, transform: 'translateY(15px)' }))
        ], { optional: true })
      ])
    ]),
    trigger('fadeInOut', [
      // Define the "void" => "visible" transition
      transition(':enter', [ // :enter is alias for void => *
        style({ opacity: 0 }), // Start with an opacity of 0
        animate('0.5s ease-in', style({ opacity: 1 })) // Animate to opacity 1
      ]),
      // Define the "visible" => "void" transition
      transition(':leave', [ // :leave is alias for * => void
        animate('0.5s ease-out', style({ opacity: 0 })) // Animate to opacity 0
      ])
    ]
    )
  ]
})  
export class CountryDetailsPage implements OnInit {
  public countryDetails: any;
  public countryInfo: any
  public isLoaded = false;
  private destroy$: Subject<void> = new Subject<void>();
  public cityImages = [];
  swiper: Swiper | undefined;

  constructor(private modalController: ModalController, private cdr: ChangeDetectorRef,
     private router: Router, private apiService: ApiService) { }

  ngOnInit() {
    // this.initSwiper();
    this.apiService.toggleFooterOverlay(true);
    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras.state) {
      this.countryDetails = currentNavigation.extras.state;
      console.log(this.countryDetails)
      this.apiService.getCountryDetails(this.countryDetails.tripLocation).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        console.log(res)
        this.countryInfo = res.country
        console.log(this.countryInfo)
        this.isLoaded = true;
        // this.apiService.getCountryCityImages(this.countryDetails.tripLocation).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
        //   console.log(res)
        //   this.cityImages = res;
        //   this.initSwiper();

        //   this.cdr.detectChanges()
        // })
      })  
    //   this.countryInfo = {
    //     "description": "Russia is a vast country spanning across two continents, known for its diverse landscapes and rich history. From the historic streets of Moscow to the stunning beauty of St. Petersburg, Russia offers travelers a unique and unforgettable experience.",
    //     "best_times_to_travel": [
    //         "June",
    //         "July",
    //         "August"
    //     ],
    //     "top_cities_for_tourists": [
    //         "Moscow",
    //         "St. Petersburg",
    //         "Sochi",
    //         "Kazan",
    //         "Yekaterinburg"
    //     ]
    // }
    }

  }

  // public async getCityDetails(city){
  //   const location = city + ', ' + this.countryDetails.tripLocation;
  //   const modal = await this.modalController.create({
  //     component: CityDetailsModalPage,
  //     breakpoints: [0, 0.3, 0.5, 0.8],
  //     initialBreakpoint: 0.8,
  //     componentProps: {
  //       location: location
  //     },
  //   });
  
  //   await modal.present();
  
  //   // Handle the data returned from the modal when it's dismissed
  //   const { data } = await modal.onDidDismiss();
  //   console.log('Modal dismissed with data:', data);

  // }

  public async getCityDetails(city){
    console.log(city)
    const location = city + ', ' + this.countryDetails.tripLocation;
   this.apiService.openCityDetails(location)

  }

  public initSwiper(){
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 1,
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        // clickable: true,
    },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  async openConversation(){
    console.log("Bang")
    const modal = await this.modalController.create({
      // component: CityConversationPage,
      component: ModalwrapperComponent,
      breakpoints: [0, 0.3, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        tripLocation : this.countryDetails.tripLocation
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss()
    console.log(data)
    // this.apiService.toggleFooterOverlay(false); // Hide the overlay after modal dismiss

  }

}
