import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ContactsPage } from './contacts/contacts.page';
import { HomePage } from './home/home.page';
import { ProfileComponent } from './home/profile/profile.component';
import { SettingsPage } from './settings/settings.page';
import { HistoryPage } from './history/history.page';
// import { TripdetailsComponent } from './tripdetails/tripdetails.component';
import { TripPagePage } from './trip-page/trip-page.page';
import { ChatPage } from './chat/chat.page';
import { CountryDetailsPage } from './country-details/country-details.page';
import { CityDetailsPage } from './city-details/city-details.page';
import { LottieComponent } from 'ngx-lottie';
import { ItineraryActivitiesPage } from './itinerary-activities/itinerary-activities.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/tab1',
    pathMatch: 'full'
  },
  
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'contacts/:data',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsPageModule)
  },
  {
    path: 'contacts/:data/:location',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsPageModule)
  },

  { path: '', component: LottieComponent },

  { 
    path: 'contacts', 
    component: ContactsPage 
  },
  { 
    path: 'settings', 
    component: SettingsPage 
  },
  { 
    path: 'history/:tripUuid', 
    component: HistoryPage 
  },
  { 
    path: 'trip-page/:tripUuid', 
    component: TripPagePage 
  },
  { 
    path: 'country-page/:uuid', 
    component: CountryDetailsPage 
  },
  { 
    path: 'city-details', 
    component: CityDetailsPage 
  },

  { 
    path: 'chat/:tripUuid', 
    component: ChatPage 
  },
  { 
    path: 'itinerary-activity/:tripUuid', 
    component: ItineraryActivitiesPage 
  },
  
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'trip-page/:tripUUid',
    loadChildren: () => import('./trip-page/trip-page.module').then( m => m.TripPagePageModule)
  },

  {
    path: 'itinerary-activity/:tripUUid',
    loadChildren: () => import('./itinerary-activities/itinerary-activities.module').then( m => m.ItineraryActivitiesPageModule)
  },
  
  

  {
    path: 'history/:tripUuid',
    loadChildren: () => import('./history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'chat/:tripUuid',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'reply-message-popover',
    loadChildren: () => import('./reply-message-popover/reply-message-popover.module').then( m => m.ReplyMessagePopoverPageModule)
  },
  {
    path: 'category-modal',
    loadChildren: () => import('./category-modal/category-modal.module').then( m => m.CategoryModalPageModule)
  },
  {
    path: 'country-details',
    loadChildren: () => import('./country-details/country-details.module').then( m => m.CountryDetailsPageModule)
  },

  {
    path: 'city-details',
    loadChildren: () => import('./city-details/city-details.module').then( m => m.CityDetailsPageModule)
  },
  {
    path: 'city-conversation',
    loadChildren: () => import('./city-conversation/city-conversation.module').then( m => m.CityConversationPageModule)
  },
  {
    path: 'create-topic',
    loadChildren: () => import('./create-topic/create-topic.module').then( m => m.CreateTopicPageModule)
  },
  {
    path: 'conversation-details',
    loadChildren: () => import('./conversation-details/conversation-details.module').then( m => m.ConversationDetailsPageModule)
  },
  {
    path: 'search-bar',
    loadChildren: () => import('./search-bar/search-bar.module').then( m => m.SearchBarPageModule)
  },
  {
    path: 'linked-trips',
    loadChildren: () => import('./linked-trips/linked-trips.module').then( m => m.LinkedTripsPageModule)
  },
  {
    path: 'itinerary-types',
    loadChildren: () => import('./itinerary-types/itinerary-types.module').then( m => m.ItineraryTypesPageModule)
  },
  {
    path: 'import',
    loadChildren: () => import('./import/import.module').then( m => m.ImportPageModule)
  },
  {
    path: 'itinerary-details',
    loadChildren: () => import('./itinerary-details/itinerary-details.module').then( m => m.ItineraryDetailsPageModule)
  },
  {
    path: 'itinerary-activities',
    loadChildren: () => import('./itinerary-activities/itinerary-activities.module').then( m => m.ItineraryActivitiesPageModule)
  },
  {
    path: 'preview-image',
    loadChildren: () => import('./preview-image/preview-image.module').then( m => m.PreviewImagePageModule)
  },
  
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
