// shared.module.ts
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications/notifications.component';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { LogoutButtonComponent } from './home/logout-button.component';
import { SearchBarPage } from './search-bar/search-bar.page';
import { PreviewImagePage } from './preview-image/preview-image.page';
import { ImageFullscreenModalComponent } from './image-fullscreen-modal/image-fullscreen-modal.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [NotificationsComponent, LogoutButtonComponent, PreviewImagePage, ImageFullscreenModalComponent],
  exports: [NotificationsComponent, LogoutButtonComponent, PreviewImagePage, ImageFullscreenModalComponent],
  imports: [CommonModule, MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule], // Include CommonModule
  
})
export class SharedModule { }
