import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit {
  @Input() tripDetails: any;
  constructor(private modalCtr: ModalController) { }

  ngOnInit() {
    console.log(this.tripDetails)
  }

  dismissModal() {
    this.modalCtr.dismiss(); // Dismiss the modal
  }
  
}
